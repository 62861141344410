import React from "react";
import abIndexToLetter from "../lib/abIndexToLetter";

import "./storiesView.css";

export const StoriesView = (props) => {
  const { stories } = props;
  return (
    <div className="stories-view">
      <small>Loaded Stories ({stories.length}): </small>
      <article>
        <br />
        <figure>
          {stories.map((story) => (
            <div
              key={story.id}
              style={{ float: "left", width: "250px", padding: "20px" }}
            >
              <strong>{story.name}</strong>
              <div>ID: {story.id}</div>
              <div>
                <small>{story.mainBranchEpisodes.length} episodes</small>
              </div>
              <div>
                <small>
                  Main branch: {abIndexToLetter(story.mainBranch.index)}
                </small>
              </div>
            </div>
          ))}
        </figure>
      </article>
    </div>
  );
};
