import React, {useState} from 'react';
import type { Settings } from '../types';
import { AVAILABLE_MODES } from '../config';

type ModeSelectorProps = {
  settings: Settings;
  onChangedSettings: Function;
};


export const ModeSelector = (props: ModeSelectorProps) => {
  const {settings, onChangedSettings} = props;

  const onChange = (mode) => {
    const newSettings = {...settings, mode: mode};
    onChangedSettings(newSettings);
  };

  return (
    <div className="grid">
      <div>
        <label htmlFor="mode">Tool Mode</label>
        <small>Determine the mode for this tool</small>
        <select
          id="mode"
          name="mode"
          required
          onChange={(e) => onChange(e.target.value)}
        >
          {AVAILABLE_MODES.map((model) => (
            <option
              value={model.key}
              key={model.key}
              selected={model.key === settings.mode}
            >
              {model.name}
            </option>
          ))}
        </select>
        <small>{AVAILABLE_MODES.find(mode => mode.key == settings.mode)?.label}</small>
        <br />
      </div>
      <div></div>
    </div>
  );
  
};