const axios = require('axios');
const tough = require('tough-cookie');

async function backendInterface({urlPrefix, email, password}) {

  const cookieJar = new tough.CookieJar();

  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: urlPrefix,
    jar: cookieJar
  });

  async function auth() {
    let res;

    try {
      res = await axiosInstance.post(`api/v1/session`, {
        email: email,
        password: password,
      }, {
        withCredentials: true
      });
    } catch(e) {
      return false;
    }

    return true;
  }

  if (!await auth()) {
    return false;
  }

  return axiosInstance;
}

module.exports = backendInterface;
