import { z } from 'zod';
import { AVAILABLE_MODELS } from '../src/config';

export type Paragraph = {
  id: number;
  text: string;
  block_id: string;

  skip?: boolean; // For chat messages
  meta?: {
    retries?: number;
  };
  tags?: string[] // For the immersive sound process
};

export type SlugOrId = string | number;

export type ChunkMeta = {
  completion_tokens?: number;
  prompt_tokens?: number;
  total_tokens?: number;
  retries?: number;
};

export type Chunk = Paragraph[];

export type RequestMeta = {
  finalStatusCode: number;
  finalStatusMessage: string;
  statusCodes: number[];
};

export type ProcessedChunk = {
  content: Paragraph[] | string;
  meta?: ChunkMeta;
  requestMeta: RequestMeta;
};

export type SampleContent = Paragraph[];

export type DifferenceByWords = {
  diff: any[];
  added: number;
  wordCount: number;
  removed: number;
  percentage: number;
};

export type ProcessWorker = {
  busy: boolean;
  worker: Worker;
  start: Function;
};

export type Credentials = {
  email: string;
  password: string;
  apiKey: string;
};

export const ModeSchema = z.enum(['deep-editing', 'immersive-sound', 'summarize', 'tag-matching']);
export type Mode = z.infer<typeof ModeSchema>;

export const GPTModelSchema = z.enum(AVAILABLE_MODELS);
export type GPTModel = z.infer<typeof GPTModelSchema>;

export const SettingsSchema = z.object({
  model: GPTModelSchema,
  inputBranch: z.union([z.string(),z.number()]),
  maxDiff: z.number(),
  minDiff: z.number(),
  attempts: z.number(),
  waitTime: z.number(),
  temperature: z.number(),
  preserveParagraphs: z.boolean(),
  maxWorkers: z.number(),
  maxTokens: z.number(),
  maxTokens2: z.number(), // second request for immersive tagging
  branchPrefix: z.string(),
  mode: ModeSchema,
});
export type Settings = z.infer<typeof SettingsSchema>;

export type Prompts = {
  [key in Mode]: string
};

export type Episode = {
  id: number;
  ab_index: number;
  title: string;

  content?: any[];
  number?: number; // UI purposes
};


export type Story = {
  id: number;
  name: string;
  episodes?: any[]; //used temporarily
  branches?: any[]; //used temporarily too

  // local stuff
  mainBranch: {
    index: number;
    name: string;
  };
  mainBranchEpisodes: Episode[];
};

export type EpisodeProcessStatus =
  | "completed"
  | "not-started"
  | "started"
  | "post-process-needed"
  | "errored";

export type MessageDataFromWorker = {
  status: EpisodeProcessStatus;
  stringContent?: string,
  episodeId?: number,
  logMessage: LogEntry;
};

export type StringMap = {[key: string]: string};

type OptionsBase = {
  episodeId: number;
  storyId: number;
  abIndex: number;
  settings: Settings;
  prompt: string;
  beforePrompt: string;
  immersiveTags?: StringMap
  tagMatchingTagMap?: TagMap
};

export type TagMatchingResultItem = {
  category: string,
  tags: string[]
};

export type TagMatchingResult = TagMatchingResultItem[];

export type ProcessWorkerOptions = OptionsBase;

export type ProcessEpisodeOptions = OptionsBase & {
  onMessage: Function;
};

export type MessageDataToWorker = {
  action: "processEpisode" | "init";
  options: ProcessWorkerOptions;
  credentials?: Credentials;
  settings?: Settings;
};

export type LogEntry = {
  message: {
    text: string;
    danger?: boolean;
    busy?: boolean;
  };
  markDoneBefore?: boolean;
};

export type EpisodeProcess = {
  episodeId: number;
  title: string;
  number?: number;
  log: LogEntry[];
  status: EpisodeProcessStatus;
};

// Represents a book being processed
export type StoryProcess = {
  id: number;
  originalNumberOfEpisodes: number;
  episodes: EpisodeProcess[];
};

export type ProcessMap = { [key: number]: StoryProcess };

export type BusyTracker = {
  stories: boolean;
  sample: boolean;
  mainProcess: boolean;
};

export type BadResponseError = Error & {
  status: number | string;
};

export type ImmersivePrompts = {
  [key in 'p1' | 'p2']: string;
};

export type Tag = {
  name: string,
  filename: string
};

export type TagMap = {[key in string]: string[]};