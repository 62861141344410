import React, { useRef, useState } from "react";
import type {Mode, Tag, TagMap} from '../types';
import classNames from "classnames";

import './tag-loader.css';

type CSVReaderProps = {
  onDataLoaded : Function;
  label: string,
  mode: Mode,
  tags?: Tag[],
  tagMap?: TagMap,
};

const valid = (objectTags) => objectTags.reduce( (validity, tag) => validity && tag.name && tag.filename, true);

export const CSVReader = (props: CSVReaderProps) => {
  const {onDataLoaded, tags, label, mode, tagMap} = props;
  const fileReader = new FileReader();
  const inputRef = useRef();
  const [error, setError] = useState('');

  const handleOnChange = (e) => {
    setError('');
    const cFile = e.target.files[0];
    if (!cFile) {
      return;
    }

    fileReader.onload = function (event) {
      const csvString = event.target.result as string;
      if(!csvString) {
        alert("Empty CSV");
        return;
      }
      const rows = csvString.split('\n')

      if(mode === 'tag-matching'){
        readTagsForTagMatching(rows);
      } else if (mode === 'immersive-sound'){
        readTagsForImmersiveTagging(rows);
      } else {
        throw new Error(`Mode ${mode} not supported in CSV Reader`);
      }
    };
    fileReader.readAsText(cFile);
  };

  const readTagsForTagMatching = (rows) => {

    // first thing is to get the categories.
    const categories = rows[0].split(',').map(token => token.trim());

    // make a map like: {'ambiance': ['tag1', 'tag2'...]}
    const tagMatchingTagMap: {[key in string]: string[]} = {};
    for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
      const cols = rows[rowIndex].split(',').map(token => token.trim());
      for (let colIndex = 0; colIndex < cols.length; colIndex++) {
        const tag = cols[colIndex];
        if(tag) {
          const category = categories[colIndex];
          tagMatchingTagMap[category] = tagMatchingTagMap[category] ? [...tagMatchingTagMap[category], tag] : [tag];
        }
      }
    }

    onDataLoaded(tagMatchingTagMap);
  }

  const readTagsForImmersiveTagging = (rows) => {
    const rawData = rows
      .filter(row => !!row)
      .map( row =>
        row.split(',')
        .map( col => col.trim() )
      );
    const titles = rawData.shift()?.reverse();
    const objectTags = rawData.map( rawRow => {
      return titles?.reduce( (obj, title) => {
        obj[title] = rawRow.pop();
        return obj;
      }, {});
    });

    if(!valid(objectTags)){
      onDataLoaded([]);
      setError('Invalid CSV, expected format: name, filename');
      return;
    }

    onDataLoaded(objectTags);
  };

  const clickOnFileInput = () => {
    inputRef.current?.click();
  };

  const description = `CSV with two columns: name, file`;

  return (
    <div className="tag-loader">
      <label htmlFor="tagsFile" data-tooltip={description}>{label}</label>
      {tags && (<div className={classNames({'tag-drawer': true, 'loaded': !!tags.length, 'error': !!error})} onClick={clickOnFileInput}>
        { !!error ?
          <small className="danger">{error}</small> :
          <>
            {!!tags.length ?
            <>
             <span aria-invalid="true">{tags.length} tags loaded</span>
              <br />
              <small className="very-small">
                {tags.slice(0, 3).map(tag => tag.name).join(', ')}...
              </small>            
            </> : <span>Click to load file</span>
            }  
          </>
        }
      </div>)}
      {tagMap && (<div className="tag-drawer" onClick={clickOnFileInput}>
        {!! error && <small className="danger">{error}</small>}
        {! Object.keys(tagMap).length && (<div> <span>Click to load file</span> </div>)}
        {!! Object.keys(tagMap).length && (<div> {Object.keys(tagMap).length} Categories loaded </div>)}
        
      </div>)}
      <input
        ref={inputRef}
        className="hidden"
        name="tagsFile"
        type={"file"}
        id={"csvFileInput"}
        accept={".csv"}
        onChange={handleOnChange}
      />
      <small className="very-small">the first row of this CSV is the category, each column in this CSV will be a new list of tags. Each column will be processed as a new request.</small>
    </div>
  );
}