import store from "store";
import backendInterface from "./lib/backendInterface";
import { URL_PREFIX } from "./config";
import type { Credentials } from "./types";

export const logIn = async (
  email: string,
  password: string,
  apiKey: string
): Promise<Credentials> => {
  window.api = await backendInterface({
    urlPrefix: URL_PREFIX,
    email,
    password,
  });

  if (!window.api) {
    throw new Error("Cannot connect with Studio server");
  }

  const credentials = { email, password, apiKey };
  store.set("credentials", credentials);

  return credentials;
};
