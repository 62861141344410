import classNames from "classnames";
import { useState } from "react";
import type { DifferenceByWords } from "../types";

import "./DiffContentView.css";

type DiffContentViewProps = {
  content: DifferenceByWords;
};

const DiffEntry = (props) => {
  const { index, entry } = props;
  const parts = entry.value.split("\n\n");

  if (parts.length > 1) {
    return (
      <>
        {parts.map((part, pindex) => (
          <>
            <span
              key={index + "-" + pindex}
              className={classNames({
                added: entry.added,
                removed: entry.removed,
              })}
            >
              {part}
            </span>
            {pindex < parts.length - 1 && (
              <>
                <br key={pindex + 'b1'} />
                <br key={pindex + 'b2'} />
              </>
            )}
          </>
        ))}
      </>
    );
  }

  return (
    <span
      key={index}
      className={classNames({
        added: entry.added,
        removed: entry.removed,
      })}
    >
      {entry.value}
    </span>
  );
};

export const DiffContentView = (props: DiffContentViewProps) => {
  const { content } = props;
  const [hideDeleted, setHideDeleted] = useState(false);

  const handleCheckboxChange = () => {
    setHideDeleted(!hideDeleted);
  };

  return (
    <>
      <label>
        <input
          type="checkbox"
          checked={hideDeleted}
          onChange={handleCheckboxChange}
        />
        Hide Deleted
      </label>
      <article
        className="DiffContentView"
        style={{ fontFamily: "serif", marginTop: 0 }}
      >
        {content.diff.map((diffs, dIndex) =>
          Array.isArray(diffs) ? (
            <p key={dIndex}>
              {diffs.map((diffEntry, deIndex) =>
                hideDeleted && diffEntry.removed ? null : (
                  <span
                    key={deIndex}
                    className={classNames({
                      added: diffEntry.added,
                      removed: diffEntry.removed,
                    })}
                  >
                    {diffEntry.value}
                  </span>
                )
              )}
            </p>
          ) : (
            !hideDeleted || !diffs.removed ? (
              <DiffEntry index={dIndex} entry={diffs} />
            ) : null
          )
        )}
      </article>
    </>
  );
};
