import type { ChatCompletionRequestMessage } from "openai";
import { Configuration, OpenAIApi } from "openai";
import { DEFAULT_SETTINGS } from "./config";
import type { Chunk, GPTModel, ProcessedChunk, Settings } from "./types";

type OpenAIWrapper = {
  current: OpenAIApi | null;
  settings: Settings;
};

type OpenAICredentials = {
  apiKey: string;
};

const wrapper: OpenAIWrapper = { current: null, settings: DEFAULT_SETTINGS };

const init = (openAICredentials: OpenAICredentials, settings: Settings) => {
  const configuration = new Configuration(openAICredentials);

  // workaround for "Refused to set unsafe header 'User-Agent'" error
  // taken from https://github.com/openai/openai-node/issues/6#issuecomment-1492814621
  delete configuration.baseOptions.headers['User-Agent'];

  wrapper.current = new OpenAIApi(configuration);

  if (settings) {
    wrapper.settings = settings;
  }
};

const updateSettings = (settings: Settings) => {
  wrapper.settings = settings;
};

const executeChatCompletion = async (
  model,
  prompts: string[],
  textJSObject: Chunk | string,
  respectJSONstructure: boolean,
): Promise<ProcessedChunk> => {

  // OpenAI API Request
  // If this request errors out, it throws an error
  const messages: ChatCompletionRequestMessage[] = [];
  prompts.forEach((prompt) => messages.push({ role: "user", content: prompt }));

  const content: string = respectJSONstructure
    ? JSON.stringify(textJSObject)
    : (textJSObject as string);

  // content finally.
  messages.push({ role: "user", content });

  console.log('openAI request message 1', messages[0].content);
  console.log('openAI request message 2', messages[1].content);

  const chat_completion = await wrapper.current?.createChatCompletion({
    model,
    messages,
    temperature: +wrapper.settings.temperature,
  });

  const data = chat_completion?.data;

  if (!data) {
    throw new Error("empty-data");
  }

  const responseContent = data.choices[0]?.message?.content;

  console.log('openAI responseContent', responseContent);

  if (!responseContent) {
    throw new Error("bad-resp");
  }

  if (respectJSONstructure) {
    try {
      JSON.parse(responseContent);
    } catch (err) {
      throw new Error("bad-json");
    }
  }

  const processedContent = respectJSONstructure
    ? JSON.parse(responseContent) // Classic respecting JSON paragraph structure
    : responseContent; // just text

  return {
    content: processedContent,
    meta: data.usage,
    requestMeta: {
      finalStatusCode: chat_completion.status,
      finalStatusMessage: "successful",
      statusCodes: [chat_completion.status],
    },
  };
};

const process = (
  model: GPTModel,
  prompts: string[],
  chunk: Chunk | string,
  respectJSONstructure: boolean = true,
) => executeChatCompletion(model, prompts, chunk, respectJSONstructure);

export default {
  init,
  current: wrapper.current,
  updateSettings,
  process,
};
