const CHAR_A = 65;
const CHAR_Z = 90;
const LETTER_INDEX_BASE = 26;

function checkLetterComponent(char) {
  const code = char.charCodeAt(0);
  return code >= CHAR_A && code <= CHAR_Z;
}

function letterToAbIndex(letter) {
  const normalized = letter.toUpperCase();

  const chars = normalized.split('');

  if (!chars.every(checkLetterComponent)) {
    throw new Error('Invalid AB index character, only A-Z are allowed');
  }

  const charCodes = chars.map(char => char.charCodeAt(0));

  switch (letter.length) {
    case 1:
      return charCodes[0] - CHAR_A;
    case 2:
      return (charCodes[0] - CHAR_A + 1) * LETTER_INDEX_BASE + (charCodes[1] - CHAR_A);
    default:
      throw new Error('Invalid AB index letter');
  }
}

module.exports = letterToAbIndex;
