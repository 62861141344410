import React, {useState, useEffect} from 'react';
import type { Settings } from '../types';
import abIndexToLetter from "../lib/abIndexToLetter";
import letterToAbIndex from "../lib/letterToAbIndex";

type BranchSelectorProps = {
  settings: Settings;
  onChangedSettings: Function;
};


export const BranchSelector = (props: BranchSelectorProps) => {
  const {settings, onChangedSettings} = props;

  const [branchStr, setBranchStr] = useState('A');
  const [invalidBranchStr, setInvalidBranchStr] = useState(false);

  const onChange = (inputBranch) => {
    const newSettings = {...settings, inputBranch};
    onChangedSettings(newSettings);
  };

  const onChangeTextInput = (input) => {
    const abIndex = (input || '').toUpperCase();
    setBranchStr(abIndex);
    try {
      letterToAbIndex(abIndex)
    } catch(err){
      setInvalidBranchStr(true);
      onChange('');
      return;
    }
    setInvalidBranchStr(false);
    const newInputBranch = letterToAbIndex(abIndex);
    onChange(newInputBranch);
  };

  const onToggle = () => {
    const nv = settings.inputBranch === 'main' ? '' : 'main';
    setBranchStr('');
    onChange(nv);
  };

  useEffect(() => {
    if(settings.inputBranch === ''){
      setInvalidBranchStr(true);
      return;
    }
    const newBranchStr = isNaN(+settings.inputBranch) ? '' : abIndexToLetter(+settings.inputBranch);
    setBranchStr(newBranchStr);
  }, [settings]);

  return (
    <div>
      <label htmlFor="mode">Input Branch</label>
      <div className="grid">
        <div>
          <label htmlFor="useMainBranch" className='mtba20'>
            <input
              type="checkbox"
              id="useMainBranch"
              name="useMainBranch"
              role="switch"
              onChange={onToggle}
              checked={settings.inputBranch === 'main'}
            />
            Use Main branch
          </label>
        </div>
        <div>
        {
            settings.inputBranch !== 'main' && (
            <input
              type="text"
              name="inputBranchStr"
              placeholder="Exact AB index of the branch"
              value={branchStr}
              aria-invalid={invalidBranchStr}
              onChange={(e) => onChangeTextInput(e.target.value)}
            />)
          }        
        </div>
        <div></div>
      </div>
    </div>
  );
  
};