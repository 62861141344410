import saveStory from "./lib/saveStory";
import { loadStory } from "./lib/storyLoader";
import { loadEpisode } from "./lib/episodeLoader";
import { contentToText, countTokens, contentFromStringChunks } from "./lib/contentPreprocessor";
import createEpisode from "./lib/createEpisode";
import { processChunk } from "./episodeProcess";
import openAI from "./openAI";

import type {
  Episode,
  Settings,
  MessageDataFromWorker,
  Story,
  EpisodeProcess,
  ProcessMap,
  ImmersivePrompts,
  StringMap,
  TagMap,
  TagMatchingResult,
  TagMatchingResultItem
} from "./types";
import workerManager from "./workerManager";

export const endStatusses = ["completed", "errored"];

/**
 * Contains the status of the process for books and the episodes inside them
 * Will be used as a reference fot the UI to be updated
 */
export const storiesProcesses: ProcessMap = {};

const makeBranchName = (settings: Settings) => {
  const date = new Date();
  const prefix = settings.branchPrefix;
  return prefix + `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}-${date.getHours()}:${date.getMinutes()}`;
};

const summarizeEpisodes = async (api, storyId: number, episodeBucket: Episode[]) => {

  const episodeSeparator = '\n\n';

  // Load all of them
  const episodePromises = episodeBucket.map(episodeMeta => loadEpisode(api, storyId, episodeMeta.id));
  const episodes = await Promise.all(episodePromises);

  // Summarize all of them
  const allEpisodesText = episodes.map(episode => contentToText(episode.content));
  return allEpisodesText.join(episodeSeparator);
};

const processSummaryAndStoreInBranch = async (
  storyId: number,
  openAI,
  api,
  summary: string,
  prompt: string,
  settings: Settings,
  onLogUpdate: Function,
  abIndex: number
) => {

  onLogUpdate(
    { text: `"Summarize" process started` },
  );

  const tokenCount = countTokens(prompt + '\n\n' + summary);
  if (tokenCount > settings.maxTokens) {
    onLogUpdate(
      { danger:true, text: `Token count for summary and prompt is too high. (Tokens per request limit: ${settings.maxTokens}). \n Process FAILED` },
      true
    );
    return Promise.reject();
  }

  // Send to GPT and return result, take care of retries and failures
  const processedResult = await processChunk(
    summary,
    openAI,
    [prompt],
    settings,
    settings.attempts
  );

  if (processedResult.meta?.retries === 0) {
    // REPORT FAILURE TO UI
    onLogUpdate(
      { text: `Process maxed out retries`, danger: true },
      true
    );
    return null;
  }

  onLogUpdate(
    { text: `GPT response is back, storing summary/result in first episode of new branch` }
  );

  const rawContent = processedResult.content as string;
  const content = contentFromStringChunks([rawContent], false, {}, null);

  const newEpisode = {
    title: "Summary",
    content,
    ab_index: abIndex /* AB index*/,
  };

  await createEpisode(api, storyId, newEpisode);

  onLogUpdate(
    { text: `Process completed successfully` },
    true
  );  
};

export const delegateBookProcessing = async (
  storyId: number,
  episodes: Episode[],
  prompt: string,
  beforePrompt: string,
  abIndex: number,
  settings: Settings,
  immersiveTags: StringMap,
  tagMatchingTagMap: TagMap | null,
  onLogUpdate: Function,
) => {
  const episodeBucket = [...episodes];

  storiesProcesses[storyId] = {
    id: storyId,
    episodes: [],
    originalNumberOfEpisodes: episodes.length,
  };

  // This is used only currently for tag matching,
  // as tag-matching does not create any new episodes, this function
  // is invoked when the process is completed for each worker/episode.
  const episodesProcessed: { episodeId: number, resultingTagsMap: TagMatchingResult }[] = [];
  const onStringContentReadyForEpisode = async (episodeId: number, resultingTagsMap: TagMatchingResult) => {
    // Double check needed here, probably refactoring.

    if (settings.mode !== 'tag-matching') {
      // no other modes supported by this callback
      return;
    }

    episodesProcessed.push({ episodeId, resultingTagsMap });

    if (episodesProcessed.length < episodes.length) {
      //Not all workers have finished
      return;
    }

    // Extract and deduplicate categories.
    const unifiedTagMap: TagMap = {}
    // For each Episode
    episodesProcessed.forEach(epResult => {
      // And each category
      epResult.resultingTagsMap.forEach((resultItem) => {
        const previousTags = unifiedTagMap[resultItem.category] || [];
        unifiedTagMap[resultItem.category] = Array.from(new Set([...previousTags, ...resultItem.tags]));
      })
    });

    // Convert the map into a TagMatchingResult
    const bookTagMatchingResult = Object.keys(unifiedTagMap).map(category => {
      return {
        category,
        tags: unifiedTagMap[category]
      }
    });

    // Write this into a new episode content
    const rawContent = bookTagMatchingResult.reduce( (partialContent, resultItem) => {
      return partialContent + `\n\nCategory: ${resultItem.category}\nTags:\n${resultItem.tags.join(', ')}\n\n---`
    }, '');

    const content = contentFromStringChunks([rawContent], false, {}, null);
    // With all tags in hand, create a new episode and put them there
    const newEpisode = {
      title: "Tags",
      content,
      ab_index: abIndex /* AB index*/,
    };

    await createEpisode(window.api, storyId, newEpisode);

    onLogUpdate(
      { text: `Process finalized for book ${storyId}, tags should be there.` },
      true
    );
  };

  if (settings.mode === 'summarize') {
    // Get a single string of text with all the episodes inside
    const summary = await summarizeEpisodes(window.api, storyId, episodeBucket);

    const logProcess: EpisodeProcess = {
      episodeId: 1,
      title: 'All episodes',
      number: 1,
      status: "started",
      log: [],      
    };
    storiesProcesses[storyId].episodes.push(logProcess);

    // send this single string to GPT and put result in a new branch
    await processSummaryAndStoreInBranch(storyId, openAI, window.api, summary, prompt, settings, onLogUpdate, abIndex);

    storiesProcesses[storyId].episodes = [];
    return;
  }

  while (episodeBucket.length) {
    // Waiting for the next worker to be available.
    const processWorker = await workerManager.getNextAvailableWorker(settings);

    const currentEpisode = episodeBucket.pop();

    if (!currentEpisode) {
      // All episodes have been processed
      break;
    }

    // Object to track process updates for current episode
    const episodeProcess: EpisodeProcess = {
      episodeId: currentEpisode.id,
      title: currentEpisode.title,
      number: currentEpisode.number,
      status: "not-started",
      log: [],
    };

    // Add object to current story tracker
    const storyProcess = storiesProcesses[storyId];
    storyProcess.episodes.push(episodeProcess);

    // Listener for worker messages
    const onWorkerMessage = (data: MessageDataFromWorker) => {
      const { status, logMessage, episodeId, stringContent } = data;

      if (status === 'post-process-needed' && episodeId && stringContent) {
        // Episode done processing, tags ready
        onStringContentReadyForEpisode( episodeId, stringContent);
        return;
      }

      // Update status for the Episode
      episodeProcess.status = status;

      // Message from worker
      episodeProcess.log.push(logMessage);

    };

    const options = {
      episodeId: currentEpisode.id,
      storyId,
      abIndex,
      onMessage: onWorkerMessage,
      settings,
      prompt,
      beforePrompt,
      immersiveTags,
      tagMatchingTagMap
    };

    // Start process worker
    processWorker.start(options);
  }
};

export const processBook = async (
  api,
  story: Story,
  prompt: string,
  beforePrompt: string,
  immersiveTags,
  tagMatchingTagMap: TagMap | null,
  settings: Settings,
  onLogUpdate: Function,
  fullBranchName?: string
) => {

  const branchName = fullBranchName || makeBranchName(settings);

  onLogUpdate(
    { text: `Fetching story: ${story.name} (ID: ${story.id})` },
    true
  );

  // 1. Fetch story again
  // This is needed because there is no other way to create an empty branch.
  const fullStory = await loadStory(api, story.id);

  onLogUpdate({ text: "Creating new branch: " + branchName }, true);

  const branches = fullStory?.branches || [];

  if (!branches.length) {
    return Promise.reject("Story has no branches");
  }

  // 2. Make a new EMPTY branch in Studio
  const branchAttributes = [
    ...branches,
    {
      name: branchName,
      main: false,
      chance: 0,
      season_mapping: settings.mode === 'tag-matching' ? [] : branches[0].season_mapping,
    },
  ];
  const newStory = await saveStory(api, story.id, {
    branches_attributes: branchAttributes,
  });

  const newBranch = newStory.branches.find(
    (branch) => branch.name === branchName
  );
  const abIndex = newBranch.index;

  onLogUpdate({
    text: `New Branch Created ${branchName} [abIndex: ${abIndex}]`,
  });

  onLogUpdate(
    { text: `Procesing ${story.mainBranchEpisodes.length} episode(s)` },
    true
  );

  // 3.- Process each episode, one worker/thread per episode.
  delegateBookProcessing(
    story.id,
    story.mainBranchEpisodes,
    prompt,
    beforePrompt,
    abIndex,
    settings,
    immersiveTags,
    tagMatchingTagMap,
    onLogUpdate
  );

  return Promise.resolve("Success");
};
