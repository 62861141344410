import React, { useRef } from "react";

type LoginFormProps = {
  onSubmit: Function;
  error: string;
};

export const LoginForm = (props: LoginFormProps) => {
  const { onSubmit, error } = props;

  const emailRef = useRef();
  const passRef = useRef();
  const keyRef = useRef();

  return (
    <dialog open>
      <article>
        <header>Login</header>

        <input name="apIkey" type="text" placeholder="API KEY" ref={keyRef} />
        <input name="email" type="text" placeholder="e-mail" ref={emailRef} />
        <input
          name="password"
          type="password"
          placeholder="password"
          ref={passRef}
        />
        <button
          onClick={() =>
            onSubmit(
              emailRef.current?.value,
              passRef.current?.value,
              keyRef.current?.value
            )
          }
        >
          Log in
        </button>
        {error && (
          <>
            <mark>{error}</mark>
            <p>
              <a href="https://www.notion.so/inkitt/Proof-Reading-And-Deep-Editing-tool-5e6611442b6b4bc596021f317367dac1">
                Manual
              </a>
            </p>
          </>
        )}
      </article>
    </dialog>
  );
};
