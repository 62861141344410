import { z } from 'zod';
import store from "store";
import { Settings, SettingsSchema, SlugOrId } from './types';

const parseWith = (schema) => (obj) => {
  const parseResult = schema.safeParse(obj);
  return parseResult.success ? parseResult.data: undefined;
}

const makeStore = <T>(key: string, parser?: Function) => {
  const self = {
    set: (value: T) => store.set(key, value),
    get: (): T => {
      const stored = store.get(key);
      return !parser ? stored : parser(stored);
    },
    restore: (stateApplier) => {
      const saved: T = self.get();
      saved && stateApplier(store.get(key));
    },
  };
  return self;
};

const StringSchema = z.string();

export const settingsStore = makeStore<Settings>("settings", parseWith(SettingsSchema));
export const storiesStore = makeStore("stories");
export const credentialsStore = makeStore("credentials");
export const deepEditingPromptStore = makeStore<string>("prompt", parseWith(StringSchema));
export const summarizePromptStore = makeStore<string>("summarize-prompt", parseWith(StringSchema));
export const immersivePromptStore = makeStore<string>("immersive-prompts", parseWith(StringSchema));
export const tagMatchingPromptStore = makeStore<string>("tag-matching-prompts", parseWith(StringSchema));
export const tagsStore = makeStore("tags");
export const tags2Store = makeStore("tags2");
