export const slugs_to_ids = {
  'a_humana_perdida_season_2_msbr':'7551',
  'snap_authorsi_4_6':'2799',
  'kenzo_book_2':'4196',
  'dangerous_love_s_2':'3090',
  'fairy_godmother_inc_edited':'1641',
  'torian_warriors_book_five':'4714',
  'wicked_barber':'7182',
  'nillium_neems_2':'1775',
  'tranquility_2_ptbr':'2994',
  'the_lairds_marker_c26':'831',
  'the_billionaire_ceo_book_2':'2892',
  'raising_the_mafias_child':'1549',
  'fairy_godmother_inc_2_turkish':'3253',
  'how_it_all_began':'6791',
  'test_3':'2895',
  'his_true_mate_book_two':'836',
  'colt_russian_2':'1556',
  'broken_queen_freelancer_1_11':'3063',
  'undressed_by_the_king_2_turkish':'2303',
  'her_familiar_stranger_32_36':'4835',
  'rebel_souls_mc_book_5_brick_by_brick':'6920',
  'alpha_kaden_2_turkish':'1766',
  'i_shouldnt_want_you_11_12':'4715',
  'feelin_the_burn_audiobook_edit':'7552',
  'taken_new_chapters':'2301',
  'dev_broken_queen_s2_alt_ending':'206',
  'yes_mr_knight_2_msbr':'4837',
  'cageddd':'3172',
  'wolf_riders_mc':'6340',
  'rogue_queen':'6409',
  'requiem_city_finale':'7183',
  'half_blood_ch37_47':'4197',
  'the_replacement_spanish_2':'1550',
  'the_flames_that_bind_us_2_turkish':'2835',
  'unclassic_hero_2_de':'5960',
  'immortal_dawn_2_turkish':'2840',
  'fairy_godmother_inc_full_sound':'5977',
  'uncontrollable_heat_c41_s2c5':'4505',
  'crimson_cup_ch_17':'7553',
  'in_the_shadows_3_de':'5961',
  'seeing_scarlett_book_2':'6921',
  'stray_puppy_book_3_stray_brat':'6922',
  'vendeleer_brothers_s_4':'2461',
  'norse':'7008',
  'hated_by_my_mate_remove_rejection_relaun':'3691',
  'coming_home_test_1':'2896',
  'roommate_fwb_the_game':'6929',
  'builder_chicks_series':'6963',
  'em_busca_da_felicidade_ms':'3953',
  'wwb2c8':'1029',
  'found_cliff_adjustments':'1647',
  'moon_river_2_turkish':'2580',
  'pricing_test_4':'6981',
  'mw_5_turkish':'2586',
  'her_last_hope_2_turkish':'2591',
  'marked_2_turkish':'2592',
  'italics_test_ms':'3754',
  'the_alphas_expectations':'322',
  'players':'1079',
  'alphas_maid':'434',
  'the_barbarian_book_3_the_slave':'6923',
  'gideon_3_turkish':'2593',
  'humanborn_2_de':'5967',
  'hbmm_remove_rejection_example':'3692',
  'walk_through_shadows_book_3':'973',
  'at_the_end_of_the_world_part_2':'636',
  'touch_ep_12':'847',
  'sons_of_dust':'2584',
  'hated_by_my_mate_no_classism_intro':'2720',
  'sour_apples':'6930',
  'broken_queen_dual_pov_romance':'6935',
  'collide_b2_c4':'922',
  'capturada_season_2_msbr':'6932',
  'broken_queen_5_turkish':'2594',
  'players_3_de':'5962',
  'silent_2_turkish':'3377',
  'alphas_call_4_turkish':'2599',
  'the_invitation':'516',
  'the_alphas_twins_book_2_james':'6924',
  'luna_awakened_s_3':'2473',
  'beautiful_belle_stockholm_syndrome':'7185',
  'the_wrong_move':'503',
  'haunted_2_turkish':'2464',
  'hated_by_my_mate_ep_1_3_meet_cute':'854',
  'fat_keily_less_mean_james':'2107',
  'mafia_entanglement_2_spanish':'6931',
  'rejected_warrior_s2':'1769',
  'evie_chase_s_2':'2469',
  'infinity_136_146':'2470',
  'alpha_kaden_3_ptbr':'2309',
  'marked_full_edit':'2106',
  'cidade_requiem_season_3':'2105',
  'luna_awakened_s_2':'2471',
  'the_lost_princess_2_ptbr':'1574',
  'extended_intro':'6933',
  'marrying_the_ceo2_6_10':'1563',
  'mated_to_the_werewolf_king_2_turkish':'2310',
  'wounded_kiss':'6715',
  'lilys_king_2':'4198',
  'marrying_the_ceo':'256',
  'jitb':'3174',
  'campus_flirt':'6716',
  'her_possessive_guard':'106',
  'nas_sombras_4_msbr':'6966',
  'mommy_to_be_s_1_edit':'3146',
  'flames_that_bind_us_3_turkish':'2837',
  'fighting_darius_hallucinating_darius':'6937',
  'rejected_warrior_s3':'1770',
  'landon_turkish':'3118',
  'ch_1_tension':'6182',
  'prometida_ao_rei_lobisomem_season_3_ms':'3757',
  'falling_for_the_mafia_brothers_full_edit':'2474',
  'pricing_test':'6942',
  'man_made':'109',
  'beautiful_mistake_freelance_cliffs_hooks':'3256',
  'inappropriatec31':'631',
  '10_years':'2796',
  'the_alphas_call_3_ptbr':'1650',
  'mw_in_chapter_summary_test':'6926',
  'when_the_night_falls_author_si_1_10':'3064',
  'alphas_call_2_turkish':'2317',
  'night_op_extra':'2601',
  'alphas_second_chance_nymph_2_ptbr':'3257',
  'meeting_my_abductor_3_spanish':'6943',
  'mateo_santiago_3_turkish':'2590',
  'os_lobos_do_milenio_human_translation':'2108',
  'sangue_magico_season_2_ms':'4083',
  'dragons_princess_2_turkish':'2598',
  'college_life':'7362',
  'requiem_city_4_ptbr':'2325',
  'more_danger':'6427',
  'sweet_kisses_series':'7009',
  'different_cliffs_rearrange':'1571',
  'first_chance_2_de':'5963',
  'o_segredo_do_meu_meio_irmao_s3_ms':'3879',
  'primeira_chance_season_2msbr':'6176',
  'tmw_immersive_test':'7006',
  'moon_river_edited':'1667',
  'cradle_snatcher_cold_open':'1684',
  'example_123':'6927',
  'sharing_delilah':'377',
  'touch_full_edit':'1772',
  'mateo_santiago':'107',
  'requiem_city_buy_1_chapter_get_1_free':'6944',
  'snap_4_spanish_book':'6659',
  'their_little_human_2_de':'5970',
  'arrangement_2_turkish':'1686',
  'os_lobos_do_oeste_guerra_sangrenta_ms':'3759',
  'amigos_por_acaso_missing_chapters':'3694',
  'lost_princess_book_three_2':'4594',
  'friendzoned':'2909',
  'bb4_besotted':'938',
  'yes_mr_knight_book_two':'4200',
  'alpha_and_aurora_book_four':'4310',
  'found':'487',
  'hardest_thing':'7360',
  'half_blood_wolf':'361',
  'ravenous_thriller_romance_hook':'1773',
  'wolf_wars_3_turkish':'1933',
  'ravenous':'1668',
  'dragons_slave_2_turkish':'2110',
  'players_book_3':'2477',
  'mmag_ch_16_gpt_4_fix':'7364',
  'when_a_moth_loves_a_bee':'6428',
  'bait_part_two':'4201',
  'chasing_the_omega_book_three':'4211',
  'finding_your_fate_4_7':'3325',
  'elven_crown_li_fakeout':'6954',
  'when_the_night_fall_dialogue_edits':'6969',
  'luna_awakened_av':'2475',
  'my_cowboy_russian_2':'2478',
  'atracao_fatal':'3552',
  'chamberlain_files_book_2':'1698',
  'the_arrangement_bg_rework_immersive':'7039',
  'bbag_immersive_test':'7028',
  'haunted_russian_2':'1704',
  'the_chamberlain_files_book_3':'1700',
  'marcello_mafia_missing_chapters':'2481',
  'springs_seduction':'254',
  'meeting_my_abductor_again_flash_forward':'6970',
  'touch_ep_11_fix':'1774',
  'fantasmagoria_season_2':'3553',
  'colt_and_summer':'6793',
  'from_the_ashes':'7021',
  'starlit_wolf_2_ptbr':'2809',
  'broken_queen_2_turkish':'1695',
  'confissoes_de_uma_ex_vadia_msbr':'4861',
  'requiem_city_2_ptbr':'1707',
  'fast_tension':'6985',
  'dev_ac_s1_ep9_17':'223',
  'dragons_slave_paced_scenes':'6955',
  'prince_of_the_deep_b':'7011',
  'riders_of_tyr':'177',
  'immortal_dawn_2':'2329',
  'my_sisters_wedding':'2067',
  'stolen_by_the_alpha_4_turkish':'2216',
  'campus_player':'7029',
  'bantering_with_murder_4':'4432',
  'mason_dream_sequence':'7012',
  'arrangement_intro_rework':'6971',
  'kenzo_season_3_msbr':'6986',
  'ameaca_em_alta_velcoidade_season_3':'3326',
  'fighting_darius_this_is_our_story':'7022',
  'mw_aidens_pov':'2871',
  'story_test_three':'951',
  'torian_warriors_edited':'1711',
  'broken_queen_4_ptbr':'1583',
  'fade_2_turkish':'2870',
  'broken_queen_4_turkish':'2341',
  'hyrbrid_2_tr':'2343',
  'gideon_s4_edited':'1725',
  'dragons_princess':'74',
  'unexpected_flame':'483',
  'my_grumpy_alpha_ep_1_3':'7365',
  'alpha_and_omega':'7013',
  'king_of_campus':'7030',
  'jogo_de_seducao':'3697',
  'twisted_together':'704',
  'os_lobos_do_oeste_season_3':'3494',
  'falling_for_my_bodyguard':'888',
  'kenzo_de_3':'6987',
  'moon_river_2_ptbr':'2631',
  'falling_for_the_ceo':'7041',
  'essence_of_darkness':'3763',
  'fine_lines':'6250',
  'beautiful_belle_and_alpha_grayson_his_lost_queen':'6941',
  'takenc61':'1041',
  'bb3_bedeviled':'927',
  'inappropriate_epilogue_edit':'779',
  'the_bearington_brothers':'309',
  'marcello_mafia_series_book_3':'6433',
  'daddys_waiting':'7031',
  'lycan_queen_immersive_check':'7189',
  'hated_by_my_mate_book_2':'6988',
  'wolf_wars_book_3':'683',
  'infinity_2_spanish':'1719',
  'chasing_the_beta_omega_book_2':'2486',
  'stillwell_cowboys':'7027',
  'haunted_book_2':'6973',
  'pact':'7042',
  'a_sombra_do_dragao_season_2':'3699',
  'vendeleer_brothers_s5':'2487',
  'vendeleer_brothers_s6':'2488',
  'testdev':'765',
  'capture_me_book_2_hate_me':'2490',
  'jogo_de_seducao_ptbr':'3698',
  'rescued':'7043',
  'broken_angels_mc_book_5_ghost':'6434',
  'man_of_the_match':'375',
  'mated_2':'2344',
  'enemies_to_lovers':'6958',
  'alpha_ares_4_7':'3556',
  'broken_queen_immersive_check':'7190',
  'the_devil_i_dont_know':'6728',
  'alpha_and_aurora_foreshadow_themes':'5980',
  'fgm_ltd_s_6':'2218',
  'chamberlain_files_3':'1732',
  'haunted_remove_dog_eating_body':'5140',
  'infinity_book_2':'6989',
  'infinity_book_3':'6990',
  'colt_violet':'7032',
  'htmb2c11_15':'1054',
  'bq_alpha_vs_alpha_test':'1021',
  'not_all_about_you_full_edit':'1730',
  'the_fairytales_series_book_5':'6959',
  'undressed_by_the_king':'81',
  'savage':'75',
  'hated_by_my_mate_music_only':'4311',
  'serojie_flashforward_hook':'1721',
  'alpha_and_aurora_2_ptbr':'1799',
  'tell_no_lies_c6_10':'5143',
  'sangue_de_dragao':'3497',
  'life_arranged':'6964',
  'em_busca_da_felicidade_season_3msbr':'5205',
  'arrangement_3_turkish':'1813',
  'bqueen2_turkish':'1814',
  'lost_princess_c_90_99_manuscript':'3701',
  'tester':'1791',
  'burned':'7033',
  'due_diligence':'7037',
  'tell_me_you_want_me':'7044',
  'requiem_city_3_turkish':'2614',
  'requiem_city_4_turkish':'2615',
  'snap_s2':'1797',
  'snap_s3':'1798',
  'broken_queen_portuguese':'1051',
  'snap_s4':'1810',
  'the_deal':'515',
  'miss_high_maintenance':'6960',
  'starlit_wolf_2_turkish':'2841',
  'chrysalis_club':'7034',
  'three_the_perfect_number_book_2_manu':'3702',
  'resisting_the_attraction':'785',
  'mason_new_chapters':'1496',
  'and_then_she_found_him_edited':'1494',
  'quincy_ch_1':'6252',
  'knocking_boots':'7046',
  'multi_branch_demo':'1373',
  'the_black_notebook':'781',
  'wkndor2':'1376',
  'vow_of_a_mate':'365',
  'friends_with_benefits':'606',
  'mason_suspense_rework':'7194',
  'end_of_mason_s_2':'2495',
  'punished_by_the_alpha_book_4':'3964',
  'schizophrenia_2':'1738',
  'love_at_stake_the_undead_next_door':'5575',
  'and_then_she_found_him_gramform':'1493',
  'skin_deep':'6731',
  'fada_madrinha_ltda_season_3':'3383',
  'beautiful_mistake_2_ptbr':'1491',
  'requiem_city_2_turkish':'2120',
  'the_price_possession':'80',
  'what_it_means_to_burn':'3611',
  'la_vie_en_rose':'7036',
  'shadow_pact_book_2':'787',
  'alphas_bargain_tony_si_contest':'5206',
  'punished_by_the_alpha_3_turkish':'2122',
  'beautiful_mistake_3_turkish':'1815',
  'wolf_intro':'7035',
  'billionaire_on_top_ch_1_v_2':'7195',
  'wolf_wars_new_chapters':'1497',
  'alphas_series':'7038',
  'the_arrangement_5_turkish':'2124',
  'andthen':'1495',
  'chamberlain_files_2':'1724',
  'mistletoe_and_secret_kisses':'740',
  'ascn_s2_pt2':'1503',
  'top_shelf_ogw_format':'1498',
  'top_shelf_edited':'1499',
  'secrets_of_sin_s2_1_10':'1754',
  'love_at_first_stoplight_og':'1504',
  'lycans_queen_deepl_season_1':'3328',
  'the_playmaker_book_two':'733',
  'lycans_queen_b3_1_14':'1509',
  'fragile':'6732',
  'hybrid_series':'5109',
  'werewolf_chronicles_2_turkish':'2497',
  'broken_queen_season_1_deepl':'3329',
  'twice_a_wish':'686',
  'loving_my_brothers_best_friend':'4264',
  'three_the_perfect_number_1_7':'3500',
  'because_youre_mine':'7050',
  'love_at_first_stoplight_gramform':'1505',
  'humanborn_2_lycanborn':'783',
  'rachel_van_dyken_story_1':'7142',
  'starlit_wolf_full_edit':'2352',
  'snap_s_5':'2499',
  'him_and_i_redo':'649',
  'ravenous_book_2_manuscript':'3770',
  'secrets_of_sin_s2_1_10_again':'1757',
  'ascn_s_2_pt_4':'2503',
  'button_check':'1817',
  'malik_turkish':'3119',
  'grayson_tr':'3123',
  'family_matters_2_love_matters':'1750',
  'dragons_blood_book_2':'773',
  'moon_river_book_2':'1758',
  'tafc_the_depths_we_go_to':'7096',
  'sexy_as_sin':'7048',
  'pricing_test_2':'6979',
  'winning_the_custody_of_three':'7071',
  'bitten_by_the_alpha_3_ptbr2':'2501',
  'hbmm_chapters':'1513',
  'bewitched_arrows':'6735',
  'marrying_the_ceo2_1_5':'1502',
  'sweet_temptation':'1510',
  'mateo_santiago_season_3_ptbr2':'2502',
  'fit_for_fire_s2':'1518',
  'at_the_end_of_the_world_b2_part_2_v2':'1523',
  'infinity_tension_builders':'7105',
  'at_the_end_of_the_world_b2_part_2':'1522',
  'sweet_temptation_gramform':'1511',
  'sweet_temptation_edited':'1512',
  'king_without_a_queen':'2334',
  'another_shot':'7097',
  'an_alphas_vixen_drama_danger':'6437',
  'shadows':'7049',
  'protecting_our_mate':'7051',
  'love_at_first_thrust':'7072',
  'unraveled':'7100',
  'gideon_s4':'1525',
  'mateo_santiago_book_1_source_material':'660',
  'my_sexy_stepbrother_is_a_werebear_part_2':'7197',
  'pricing_test_3':'6980',
  'my_sexy_stepbrother_is_a_werebear_3_turk':'2366',
  'gideon_ptbr_human_translation':'2506',
  'mason_10_13_rework':'1077',
  'eyes_on_us_author_original':'3175',
  'wolves_of_the_west_russian_ms':'3169',
  'alpha_and_aurora_3_turkish':'2377',
  'alpha_and_aurora_qa':'6255',
  'beautiful_belle_romance_cliffs_3_6':'2916',
  'o_castigo_do_alfa_season_4_ms':'4068',
  'colt_2_ptbr':'1805',
  'diablon_chronicles':'7073',
  'testy_mc_test_test':'1762',
  'mated_to_the_alpha':'7000',
  'rose_series_book_2_shattered_rose':'576',
  'mmaa_darker_aesthetic_shift':'6178',
  'love_bites_full_edit':'1761',
  'marcello_mafia_intro_rework':'7198',
  'billionaires_masquerade':'7003',
  'the_starlit_wolf_book_2':'782',
  'tempted_by_my_boss_book_2':'6179',
  'clausura_ms':'3774',
  'cowboy_boots_and_combat_boots':'147',
  'eclipse':'7074',
  'i_shouldnt_fall_for_you_s_2':'2627',
  'a_maldicao_season_3_msbr':'6439',
  'brookings_forever':'6738',
  'lycans_queen_2_ptbr':'1759',
  'my_sexy_stepbrother_is_a_werebear_2_ptbr':'1764',
  'the_millenium_wolves_5_ptbr':'1887',
  'mason_add_chapters':'674',
  'tech_billionaires_assistant':'1288',
  'alpha_kaden':'91',
  'another_charge':'7098',
  'the_twins':'324',
  'author_magic':'5042',
  'three_the_perfect_number_deep_edit':'7075',
  'casanova_at_law_c_36_41':'1824',
  'when_tables_turn_complete':'935',
  'alphas_arrangement_redo':'677',
  'coastal_killings':'1531',
  'lady_susan':'7113',
  'i_shouldnt_want_you_s_2_c_1_8':'2387',
  'silent_embrace_author_vs_adaptation':'3148',
  'test_for_translations_0':'6442',
  'smoldering_heat':'7099',
  'inappropriate':'609',
  'london_fairy_tales':'465',
  'alpha_kaden_2_ptbr':'2137',
  'broken_queen_3_turkish':'1826',
  'theiroafskbjsdf':'1249',
  'her_last_hope_book_2_21_35':'2391',
  'marrying_the_ceo_2_11_15':'2140',
  'reapers_claim_resound_si_test_win_aj':'7108',
  'the_arrangement_less_whining':'7119',
  'arrangement_human_translation_turkish':'2127',
  'arrangement_ch_31_test':'7126',
  'colt_3_turkish':'2128',
  'feelin_the_burn_foreshadow_cliff':'2220',
  'first_chance_s_3':'1881',
  'mateo_santiago_3_ptbr':'2393',
  'a_room_with_a_view_pd':'7058',
  'moon_river_identity_confusion':'1954',
  'mw_turkish_human_translation':'1809',
  'requiem_city_german_test':'639',
  'northanger_abbey':'7114',
  'lycans_queen_3_ptbr':'1842',
  'first_chance_s_2':'1880',
  'just_one_night':'7101',
  'sold_to_the_berserkers':'6740',
  'chasing_kiarra_c_branch_full_edit':'1834',
  'o_ceo_bilionario_msbr':'4797',
  'meeting_my_abductor_2_turkish':'1836',
  'o_alfa_do_milenio_season_2':'2818',
  'mw_3_turkish':'1844',
  'home_wrecking_alpha_10_ch_sound_test':'3570',
  'half_blood_s_2':'1888',
  'walk_through_shadows_s4_c21_22':'1838',
  'infinity_126_135':'1956',
  'test_test_test':'1835',
  'first_chance_s_4':'1882',
  'first_chance_s_5':'1883',
  'first_chance_s_6':'1884',
  'i_didnt_belong_to_you_s_2':'1885',
  'finding_my_way_s_2':'1886',
  'the_alpha_kings_claim_fantasy_tension':'7120',
  'beautiful_mistake_russian_2':'1433',
  'moon_river_no_sex_cheating':'1955',
  'the_end_of_the_world_part_3':'685',
  'chasing_the_omega_2_ptbr':'2819',
  'marked_4_10':'3571',
  'the_relationtrip':'7102',
  'bitten_by_alpha_2_turkish':'1828',
  'mason_immersive_resound_branch_aa':'7078',
  'donor_to_a_vampire_s2_nonsplit':'630',
  'secrets_cliff_rearrange':'1846',
  'different_cliff_split':'1889',
  'silent_embrace_author_vs_adaptation_2':'3150',
  'alpha_kaden_season_4_salvage_project':'669',
  'ravens_of_the_mist_book_3':'7110',
  'marked_2_ptbr':'2634',
  'ewige_dammerung_3':'2514',
  'walk_through_shadows_book_4':'989',
  'her_last_hope_79_93':'2392',
  'walk_through_shadows_s_5_c_pro_1':'1839',
  'not_all_about_you_decision_suspense':'2397',
  'her_familiar_stranger_c_19_26':'2396',
  'hated_by_my_mate_2_turkish':'2221',
  'alpha_and_aurora_testing_image_studio':'7127',
  'my_sexy_stepbrother_is_a_werebear':'179',
  'punished_by_the_alpha_russian_2':'1434',
  'lycans_queen_russian_2':'1435',
  'sense_and_sensibility':'7079',
  'love_an_alpha':'2062',
  'whats_a_luna_c_24_28':'2398',
  'picture_of_dorian_gray':'7060',
  'half_blood_s_3':'1890',
  'wolf_wars_3_ptbr':'1913',
  '2_abby_2_pov':'7116',
  'os_guerreiros_de_torian_season_4_ms':'4219',
  'os_lobos_do_oeste_season_2':'3416',
  'alpha_and_aurora_test_image':'7128',
  'mason_2_turkish':'2859',
  'desire':'2729',
  'the_great_gatsby':'7061',
  'requiem_blood_c_1_5':'2399',
  'mateo_santiago_temp_3':'2403',
  'i_didnt_belong_to_you_s_2_c_11_15':'2406',
  'alpha_kings_daughter_epilogue':'1014',
  'arrangement_rewrite_s2':'969',
  'caught_up_in_between_bonus':'2411',
  'in_the_shadows_s_4':'2412',
  'in_the_shadows_s_5':'2413',
  'si_example':'2520',
  'a_ultima_esperanca_22_msbr':'5483',
  'erotic_2':'7082',
  'vendeleer_brothers_s_3':'2417',
  'beautiful_mistake_pacing_intrigue':'4840',
  'mw_ger_denglisch':'274',
  'req_city_boarding_school_start':'7133',
  'as_armadilhas_do_diabo_2_msbr':'7146',
  'half_blood_4_spanish_launch':'7149',
  'kenzo_book_4':'7169',
  'flash_4_ward':'7166',
  'unclassic_hero_s_2_full_edit':'1893',
  'escrava_do_dragao_de_onix_msbr':'4603',
  'wuthering_heights':'7062',
  'tafc_rearranging_you':'7103',
  'half_blood_full_sound_new_script':'7167',
  'real_evie_chase_s_2_c_1_10':'2407',
  'erotic_1':'7081',
  'lost_princess_book_three':'4562',
  'amidst_chaos_redo':'554',
  'chasing_the_omega_2_german':'2821',
  'a_dark_witch_series_book_3_malicious':'7170',
  'seal_team_one_s_3_c_27_31':'1831',
  'alpha_kings_hybrid_mate_2_tr':'2150',
  'es_vegas_underground_manuscript':'3507',
  'my_cowboy_2_ptbr':'2919',
  'bad_boy_alphas_book_2':'1979',
  'gideon_speak_now':'7125',
  'lycans_queen_de':'4321',
  'a_filha_do_alfa_rei_season_3_msbr':'7130',
  'twisted_minds_emotional_tension':'7134',
  'paired_in_class':'7177',
  'alpha_kings_hybrid_mate_2_turkish':'1974',
  'alphas_second_chance_nymph_2_turkish':'1983',
  'the_arrangement_less_whining_2':'7131',
  'bitten':'506',
  'damaged_doll':'593',
  'dering_siblings_author_test_extensive':'5044',
  '2nd_version':'7083',
  'fairy_godmother_spanish_7_launch':'7199',
  'marcello_mafia_s2':'3575',
  'marrying_the_ceo_2_ptbr':'1895',
  'na_mira_dos_holofotes_season_3':'3714',
  'the_marking':'353',
  'mafia_entanglement_season_2_msbr':'6753',
  'beautiful_mistake_pacing_allure':'4841',
  'secrets_of_sin_76_83':'1982',
  'unclassic_hero_s_2_full_edit_1':'1894',
  'a_serva_do_dragao_season_2':'3337',
  'vendeleer_brothers_s_2':'2415',
  'alphas_little_mate':'7178',
  'hard_knock_life':'7151',
  'trapping_quincy_liven_up':'7168',
  'half_blood_german_3':'6220',
  'black_heart_book_2_damaged_strings':'6259',
  'testestest':'7084',
  'o_segredo_da_montanha_ms':'3979',
  'lie_to_me_c8_14':'7104',
  'punished_by_the_alpha_2_turkish':'1985',
  'stolen_by_the_alpha_3_turkish':'1987',
  'fit_for_fire_2_turkish':'1988',
  'enemies':'3576',
  'shame':'476',
  'his_haze_german_test':'3191',
  'dog_walking_in_heels':'7179',
  'lycans_queen_german':'4323',
  'beautiful_mistake_3_ptbr':'1833',
  'millenium_wolves_4_turkish':'2004',
  'rylan_o_alfa_ms':'3715',
  'soul_sisters_series':'7080',
  'at_the_end_of_the_world_2_turkish':'2007',
  'friends_falling_book_3_when_loves_comes':'6260',
  'seeking_happiness_s_2':'2429',
  'gideon_first_chapter':'7085',
  'silent_embrace':'108',
  'alpha_kings_hybrid_mate':'406',
  'the_barbarian_book_2_mock_and_grinda':'6761',
  'wolf_riders_mc_book_3_the_rogue_wolf':'7172',
  'alpha_kaden_si_hire':'3980',
  'by_the_billionaire_series':'7180',
  'mafias_obsession':'2061',
  'my_cowboy_2_turkish':'1986',
  'colt':'83',
  'torian_2_turkish':'3339',
  'alphas_match':'387',
  'bitten_by_alpha_3_turkish':'1990',
  'gideon_first_chapter_test':'7086',
  'yes_mr_knight_ptbr':'3273',
  'artemis_gift_si_hire':'3981',
  'it_bad_boy_alphas_series_manuscript':'3510',
  'obsession':'7173',
  'abocanhada_por_um_alfa_season_2':'1209',
  'just_one_night_io':'7181',
  'alpha_kings_claim_s_1_edited_fixed':'3151',
  'artemis_gift_3_spanish_launch':'7137',
  'raised_by_vampires_exta_chapter':'5045',
  'love_thy_alpha':'7200',
  'testexample_123':'3717',
  'no_fim_do_mundo_season_2_msbr':'4648',
  'the_getaway':'6754',
  'the_moons_blessing':'520',
  'burning_desire':'4731',
  'heal_my_soul_full_edit':'2437',
  'alphas_vixen_si_hire':'3982',
  'mason_2_german':'2920',
  'seeking_happiness_s_3':'2436',
  'broken':'7155',
  'first_chance_3':'6262',
  'making_love':'7201',
  'o_infinito_2_msbr':'5265',
  'mate_mio':'7381',
  'uncontrollable_heat_full_edit':'2435',
  'irresistible_invitation_1_5':'7174',
  'tech_billionaires_assistant_full_edit':'2438',
  'coast_to_coast_full_edit':'2439',
  'alpha_and_aurora_2_turkish':'2240',
  'abandoned':'5238',
  'half_blood_fantasy_emphasis':'4798',
  'phoenix':'445',
  'not_all_about_you_ch20':'1072',
  'alpha_ares_c22':'1253',
  'werewolf_chornicles_2_ptbr':'3274',
  'beautiful_belle_faster_pacing_8_11':'3983',
  'test_test':'1438',
  'lie_to_me':'7010',
  'ascn_s_2_pt_3':'2035',
  'fairy_godmother_inc':'1172',
  'no_jodo':'6264',
  'how_to_find_a_mate':'7156',
  'fade_book_2':'923',
  'te_ensenare_lo_que_es_amar':'7382',
  'walk_through_shadows_book_2':'967',
  'coming_of_age':'7407',
  'sangue_magico':'3584',
  'savage_refocus_structure':'4799',
  'erotic_warning':'6758',
  'beautiful_belle_8_11_faster_pacing_fixed':'3984',
  'gideon_party_crashers':'7383',
  'wotw_the_hunt_tension_pacing':'5228',
  'eyes_on_us_2_turkish':'2639',
  'a_plea_for_helpp':'3173',
  'the_arrangement_5_ptbr':'2755',
  'royal_blood':'89',
  'lutando_contra_o_amor_msbr':'4721',
  'chasing_kiarra_accelerated_intro':'2756',
  'mateo_santiago_2_tr':'2447',
  'sequestrada_por_um_alfa_deepl':'3275',
  'tales_of_the_others_book_2':'7432',
  'queen_of_my_heart':'7423',
  'vendeleer_brothers':'2414',
  'mateo_santiago_2_turkish':'2448',
  'bewitched_knight':'7007',
  'meet_him_at_the_altar':'7157',
  'intoxicating_miles':'1239',
  'the_alphas_of_south_forest_book_2':'6447',
  'sapphire_queen':'7424',
  'despida_para_o_rei_season_2':'3585',
  'gym_in_ch_1':'7202',
  'author_more_intrigue':'5047',
  'marcello_mafia_spanish_3':'7384',
  'the_arrangement_4_turkish':'1856',
  'my_sisters_wedding_s_1_edit_fixed':'3152',
  'coming_of_age_split_release':'7408',
  'wolf_wars_book_3_c6_7':'743',
  'the_arrangement_pregnancy_intro':'7420',
  'gideon_meet_cliffs':'2852',
  'her_possessive_guard_2_turkish':'2924',
  'dantes_possession_split_release':'7433',
  'lycans_queen_1_6':'2853',
  'resisting_you_update':'680',
  'resisting_you_add_chapers':'675',
  'at_the_end_of_the_world_part_3':'692',
  'neon':'7158',
  'the_outcasts_book_3':'6448',
  'community_vote_results':'7401',
  'donor30':'1242',
  'the_alpha_and_luna_series_split_release':'7409',
  'capturada_season_3_msbr':'7159',
  'kidnapped_stockholm_syndrome_simplified':'7203',
  'stray_puppy_spanish_2':'7556',
  'ch_1_meeting_12':'7425',
  'paid_only_chapters':'7141',
  'king_of_ruin':'7472',
  'walk_through_shadows_1_4':'3276',
  'prophecy_series':'6248',
  'punished_by_the_alpha_2_ptbr':'2532',
  'test_ptbr':'3586',
  'mason_2_ptbr':'2970',
  'gideon_2_ptbr':'2244',
  'we_are_bear':'73',
  'lupus_paulo':'97',
  'romantique':'7385',
  'blind_love':'7410',
  'soren_sexy_pacing':'5268',
  'her_reluctant_warrior':'7160',
  'their_little_human_book_2_manuscript':'4107',
  'the_outcasts_book_4_the_dancer':'6449',
  'luna_awakened_author_version':'2056',
  'galahte':'7441',
  'love_bites_action_cliff':'2053',
  'mason_interview_setup':'7426',
  'galatea_university':'7434',
  'kenzo_spanish_4':'7459',
  'stoplight_missing_chapters_msbr':'4518',
  'bright_star_immersive_final_qa':'7493',
  'hated_by_my_mate_2_ptbr':'2052',
  'wtnf_interview_rework':'7405',
  'endgame_season_3':'1320',
  'blind_love_split_release':'7411',
  'sold_on_you_4_tr':'7427',
  'deerborn_book_2_manuscript':'4108',
  'gugu':'7435',
  'fightforyou_c_4':'2533',
  'gfo':'7437',
  'forbidden_touches_e_23_28':'2928',
  'author_restructure':'5049',
  'from_smoke_to_flames':'7090',
  'his_haze_tr':'3277',
  'gold_wolves_series':'7161',
  'rejected_warrior_3_tr':'7486',
  'the_hunt_de':'4722',
  'ghosted_soul':'116',
  'lilac_sisterhood':'7412',
  'marrying_the_ceo_continuity_test':'1303',
  'tranquility':'66',
  'seeking_happiness_s_4':'2643',
  'marrying_ceo_ch_16_to_20':'2645',
  'alvorada_perpetua_season_2_msbr':'4437',
  'when_tables_turn_book_2_a_new_age':'6451',
  'bright_start_book_2_immersive':'7428',
  'crystal_commander_tuck_and_roll':'7406',
  'donor_to_a_vampire_s_2_c_32_36':'1861',
  'torian_warriors_s4':'3589',
  'seeking_happiness_s_5':'2644',
  'gug':'7436',
  'mafia_entanglement_s_2':'2646',
  'the_wolfskin_series_book_2_wolvenpower':'6452',
  'galatea_university_16':'7461',
  'vfx_block_test':'7487',
  'fighting_darius_fortune_teller':'7494',
  'jack_in_the_box':'129',
  'from_ice_to_flames':'7091',
  'wolfe_ranch':'7162',
  'beloved_runt_series':'7475',
  'domination_game':'6398',
  'space_landing_one_giant_leap_for_love':'6714',
  'from_ashes_to_flames':'7092',
  'the_domination_game_book_2_checkmate':'6453',
  'i_didnt_belong_to_you_s_2_e_16_25':'2927',
  'fighting_darius_waiting_for_penny':'7143',
  'found_s_2_c_1_5':'1862',
  'take_me_home':'7413',
  'twisted_minds_s_2':'2084',
  'capture_me_3_tr':'6662',
  'wolf_ranch_german':'3278',
  'yes_mr_knight_relationship_focus':'7163',
  'deerborn_book_3_manuscript':'4109',
  'cross_to_bear_2_de':'6187',
  'pregnant_and_rejected_gpt_4_edit':'7429',
  'alpha_wolfs_fated_mate':'7476',
  'guefqge':'7438',
  'galatea_university_17':'7462',
  'hunger_awaits':'6039',
  'capture_my_heart':'6804',
  'midnight_favor':'6806',
  'story_test_four':'954',
  'alpha_kaden_g':'265',
  'seeing_scarlet_2_tr':'7388',
  'alguem_como_xavier_ms':'4334',
  'town_with_no_memory':'7414',
  'wolves_of_artemis':'7477',
  'eresthai_series':'6805',
  'nas_sombras_5_msbr':'7164',
  'at_the_end_of_the_world_132_138':'2651',
  'savage_3_ptbr':'2089',
  'the_scarlet_night':'7093',
  'uncontrollable_heat_c_31_40':'2535',
  'coming_home_to_a_place_ive_never_been_ru':'3153',
  'wefgieqgy':'7439',
  'kenzo_dantes_possession':'7430',
  'galatea_university_18':'7463',
  'galatea_university_20':'7465',
  'galatea_university_30':'7488',
  'secrets_of_sin_full_edit':'2095',
  'home_wrecking_alpha_full_edit':'2092',
  'another_hit':'7094',
  'twisted_minds_s_3':'2085',
  'wdqegyf':'7442',
  'power_book_4_dragons_resurrection':'1359',
  'gfh':'7440',
  'a_chama_nos_une_season_3':'3426',
  'galatea_university_19':'7464',
  'at_the_end_of_the_world_122_131':'2086',
  'surrender':'460',
  'love_happens_author_edit':'574',
  'galatea_university_111':'7490',
  'home_on_the_range':'7505',
  'damned_doll':'594',
  'feelin_the_burn_full_edit':'2093',
  'fade':'71',
  'galatea_university_53':'7549',
  'touch':'846',
  'catching_harley':'6807',
  'escaping_fate_book_3_the_mateless_queen':'6663',
  'snowed_in':'7211',
  'galatea_university_1':'7443',
  'riders_of_tyr_resound':'7550',
  'galatea_university_21':'7466',
  'galatea_university_22':'7467',
  'alpha_and_aurora_3_ptbr':'2090',
  'ch_1_meeting':'7415',
  'wolf_ranch_french':'3280',
  'broken_queen_dream_world':'7480',
  'hated_by_my_mate_2_italian':'7492',
  'unclassic_hero_2':'882',
  'i_didnt_belong_to_you_2_tr':'7536',
  'his_nerd_german_q234twhrbgfvsxdwqefwrth':'917',
  'yes_mr_knight_tension_cliff':'7497',
  'scarlet_ocean':'7506',
  'i_didnt_belong_to_you_spanish_2':'7520',
  'aether_book_5':'1015',
  'lovers_secrets':'7361',
  'endgame':'1318',
  'the_alphas_call_4_ptbr':'2077',
  'my_sisters_wedding_si_hire':'3987',
  'dev_test_story_nikita_one':'916',
  'alpha_and_aurora_2_russian':'1449',
  'i_shouldnt_fall_for_you_full_edit':'3530',
  'emma_book_two':'2662',
  'aether_book_4':'1009',
  'o_segredo_da_montanha_2_msbr':'5050',
  'snappin_the_burn':'7389',
  'romance_focus_1':'7416',
  'sharing_delilah_book_3_true':'1027',
  'seal_team_one_25_31':'918',
  'claws_ep_1_4_author_test':'2764',
  'galatea_university_23':'7468',
  'lilys_choice_epilogue':'1250',
  'artemis_gift_s_1_full_edit':'3159',
  'aether_book_2':'1005',
  'galatea_university_2':'7444',
  'double_trouble':'7391',
  'resisting_the_attraction_2':'859',
  'aether_book_3':'1006',
  'sharing_delilah_book_2':'1020',
  'big_country_redo':'543',
  'sangue_magico_season_3_ms':'7507',
  'alpha_and_aurora':'374',
  'galatea_university_3':'7445',
  'millenniumwolves_sitest_eps22_2428_34_m':'852',
  'my_grumpy_alpha_audiobook_script':'7496',
  'rebel_souls_mc_book_6':'7212',
  'galatea_university_24':'7469',
  'the_blue_wolf_diana_si_test_author':'5484',
  'ponto_de_virada_season_2_msbr':'7499',
  'resisting_the_attraction_book_2':'1010',
  'revenge_on_the_billionaire':'7547',
  'mw_fr_extensive_machine_edit':'2663',
  'primal_fears':'1899',
  'sunset_down_under':'7417',
  'bad_boy_craves_me':'439',
  'birthday_surprise':'7390',
  'galatea_university_13':'7455',
  'king_without_a_queen_gpt_4_rewrite':'7213',
  'morning_romp':'7392',
  'galatea_university_25':'7470',
  'alpha_kaden_3_russian':'1453',
  'rachel_van_dyken_ep_7_14':'7500',
  'galatea_university_6':'7446',
  'mw_6_turkish':'2763',
  'galatea_university_8':'7450',
  'stolen':'1661',
  'bossmanchapter33':'627',
  'phoenix_missing_chapters':'756',
  'chasing_kiarra':'886',
  'saving_maximus':'169',
  'tafc_love_arrangements':'7418',
  'the_storm_gpt_4_rewrite':'7214',
  'trapping_quincy_inevitable_meeting':'5485',
  'audiobook_fighting_darius_voice_select':'7501',
  'galatea_university_4':'7447',
  'faster_start_gid':'6664',
  'as_cronicas_do_lobisomem_season_3':'3427',
  'collide_book_2_conflicted':'744',
  'falling_for_the_mafia_brothers_2_en':'2929',
  'alphas_vixen_edited':'2973',
  'elven_crown':'6681',
  'audiobook_wtnf_voice_select':'7514',
  'galatea_university_50':'7540',
  'morretti_brothers':'7546',
  'empty_dev_story':'838',
  'capture_me':'1634',
  'help_me_alpha':'348',
  'shadow_moon_series':'7393',
  'bb_beholden':'903',
  'my_sisters_wedding_gpt_4_rewrite':'7215',
  'test_dev_story':'738',
  'the_realm_book_2_integration':'6188',
  'dracones_awakening':'6743',
  'trapping_q_inevitable_meeting_bundle':'5486',
  'the_arrangement_4_russian':'1451',
  'his_sex_kitten':'7359',
  'donorb2c29':'1030',
  'beholden_fixed':'926',
  'infinityb274_103':'1162',
  'sharing_delilah_book_4':'1028',
  'galatea_university_5':'7448',
  'beautiful_mistake_book_4':'600',
  'unfortunate_friends_edit':'2974',
  'sam_pilot':'5488',
  'i_shouldnt_want_you_epilogue':'900',
  'nunca_fui_sua_season_2_msbr':'7471',
  'wild_howl':'1247',
  'pregnant_and_rejected_deep_edit':'7502',
  'frozen_desires':'7515',
  'audiobook_kmmb_select_voice':'7510',
  'daughter_of_albion':'7548',
  'galatea_university_51':'7541',
  'strictly_baby_business_gpt_4_rewrite':'7216',
  'the_fairytales_series_book_3':'6272',
  'galatea_university_7':'7449',
  'the_fairytales_series_book_4':'6273',
  'help_me_alpha_chapters_38_39':'1251',
  'mason_2_fearless_king':'947',
  'first_chance_6_de':'6766',
  'phoenix_c4250':'605',
  'hma33_35':'1001',
  'dark_ones_book_2':'638',
  'requiem_city':'82',
  'sold_on_you_2':'797',
  'the_arrangement_immersive_test_ch1':'7503',
  'short_test_importer':'1252',
  'de_wolf_ranch_book_2':'3737',
  'guns_or_royalties_ch18_19':'4235',
  'trapping_q_inevitable_meeting':'5487',
  'second_debt':'697',
  'the_seven_sinners_book_two':'5491',
  'mistletoeandsecretkisses':'748',
  'when_maeve_met_caleb':'7516',
  'falling_for_storm':'395',
  'the_kings_gift_book_3':'6274',
  'aether_book_5_ch11_and_c14':'1017',
  'test_story_1123123112312312312097':'960',
  'galatea_university_9':'7451',
  'falling_for_a_cowboy':'7419',
  'dev_broken_queen_ql_test':'166',
  'gideon_immersive_test_ch1':'7504',
  'galatea_university_40':'7526',
  'story_test':'949',
  'panthers_cage':'569',
  'gideon_book_2_new':'1256',
  'dering_siblings_book_2':'1058',
  'galatea_university_41':'7528',
  'dev_emma':'63',
  'kenzo_book_three':'4236',
  'carved_in_crystal':'98',
  'trapping_quincy_quirky_quincy':'6818',
  'galatea_university_10':'7452',
  'lqb2c33_34':'1039',
  'fit_for_fire':'347',
  'arrangement_clean_ep_1':'4118',
  'dragons_slave_deep_edit':'7517',
  'reapers_claim_ptbr_2':'1369',
  'story_test_two':'950',
  'yes_mr_knight_full_edit':'3535',
  'wolf_ranch_italian':'3286',
  'at_the_end_of_the_world_s2_1_10':'1305',
  'his_lovely_pet':'1697',
  'konigin_der_lykaner_2':'1018',
  'my_warrior':'2153',
  'a_dark_witch_series_book_2_vengeful':'6275',
  'donor_to_a_vampire_chapter_25':'678',
  'meeting_my_abductor_again_book_2':'730',
  'torian_warriors_5':'5146',
  'mw_clean_ep_1':'4119',
  'dev_price_possession_c_test':'134',
  'galatea_university_11':'7453',
  'die_gute_fee_3_german':'3429',
  'alpha_and_aurora_deep_edit':'7518',
  'dev_bright_star':'165',
  'just_friends_nonsplit':'628',
  'alpha_and_aurora_s3_c82_111':'1259',
  'marked_book_3':'1045',
  'galatea_university_14':'7456',
  'bitten_5_sister':'1221',
  'test_story':'736',
  'meeting_him_in_between_final':'635',
  'the_alphas_call_finale':'7219',
  'captured':'1736',
  'born_regal_book_2_born_shameless':'7220',
  'apaixonada_por_um_mafioso_msbr':'5275',
  'galatea_university_42':'7530',
  'mw_rewrite_structural_cliffs':'1050',
  'galatea_university_12':'7454',
  'flames_that_bind_us_2_ptbr':'2766',
  'marked_1_5':'2931',
  'i_didnt_belong_to_you_c17_29':'6189',
  'painted_scars':'735',
  'marked_book_2':'1042',
  'resisting_the_attraction_book_1_edited':'1043',
  'dev_eyes_on_us_h':'138',
  'her_possessive_guard_book_five':'5183',
  'family_matters_3_matters_of_the_heart':'2843',
  'eigengrau_effect':'1863',
  'born_regal_edited':'1370',
  'the_greystone_ridge_pack_book_2':'7221',
  'beholden':'764',
  'ruling_the_ceo_russian_ms':'3199',
  'galatea_university_15':'7457',
  'dragons_slave_book_2':'768',
  'hating_my_mate':'731',
  'millennium_wolves_2':'1194',
  'alpha_kings_hybrid_letter_from_the_king':'7519',
  'virgin_bride':'7531',
  'alpha_kings_daughter_book_two_axel':'5494',
  'broken_queen_xavier_romance':'737',
  'donor_to_a_vampire_notsplit':'629',
  'lupus_alba_book_three_taken':'5184',
  'konigskriegerin_4':'758',
  'dering_siblings_book_3':'1064',
  'three_the_perfect_number':'1749',
  'requiem_city_10ch_fewer_sfx':'6277',
  'o_lobo_mau_msbr':'4442',
  'way_out_of_the_dark':'1841',
  'sold_to_the_alpha_gpt_4_rewrite':'7222',
  'take_the_risk_book_2':'1106',
  'resisting_you_final':'681',
  'choose_me_or_loose_me':'540',
  'taken_deep_edit':'7533',
  'once_a_myth':'682',
  'fifth_a_fury':'688',
  'our_dirty_little_secret':'741',
  'debt_inheritance':'695',
  'the_replacement_book_4':'602',
  'the_score':'1976',
  'alpha_kaden_season_3_salvage_rewrite':'668',
  'akhm_s2':'1268',
  'my_very_own_pirate':'2033',
  'hated_by_my_mate_redo':'790',
  'it_wolf_ranch_book_2_manuscript':'3740',
  'dev_the_millennium_wolves_delayed_sex':'198',
  'dev_the_millennium_wolves':'195',
  'dragao_de_estimacao_ms':'4123',
  'first_debt':'696',
  'fairy_godmother_inc_book_5':'1265',
  'kidnapped_by_my_mate_slower_burn':'6278',
  'infinityb3':'1122',
  'playersb2':'1118',
  'alpha_and_aurora_6_14':'3432',
  'leap_before_you_look':'2068',
  'wotw_concision_restructure':'5352',
  'the_playmaker_book_three':'734',
  'gideon_russian_5_ch_ext_edit':'3203',
  'fairy_godmother_inc_book_3':'1200',
  'immortal_dawn_edited':'721',
  'fada_madrinha_ltda_season_4_ms':'4124',
  'chasing_kiarra_final':'1378',
  'youve_got_a_dm_edited_manuscript':'3607',
  'bm_9_10_prop':'331',
  'mssiawb_f_delayed_sex_test':'338',
  'mssiawb_si_less_cheaty_more_sam':'339',
  'beautiful_belle_alpha_grayso_slow_burn':'6279',
  'beautiful_belle_c49_61_no_sound':'723',
  'mistletoesandsecretkisses':'747',
  'dark_surrender_2':'641',
  'alpha_jasper_two':'718',
  'darkest_temptation_2':'642',
  'darkest_sinner_2':'643',
  'rose_book_4_blood_soaked_rose':'671',
  'akcb2':'1078',
  'teenage_dreams_stolen_hearts':'5230',
  'my_brothers_roommate':'2768',
  'when_i_look_at_you':'3367',
  'not_the_daddies_she_expected':'7225',
  'the_alphas_runt_book_2_destan':'724',
  'mine_to_claim_enhanced_copy_paste':'715',
  'seal_team_one_s_3_e_32_42':'2667',
  'fighting_my_orc_manuscript':'3608',
  'evie_chase_inkitt_copy_paste':'754',
  'dragons_blood_book_3':'774',
  'wanting_the_man_book_2':'1115',
  'mending_a_shat_1':'2664',
  'hated_by_my_c20_22':'803',
  'in_the_shadows_s_1_full_edit':'3204',
  'my_forever':'7226',
  'gideon_2_turkish':'2255',
  'fr_wolf_ranch_book_2_manuscript':'3742',
  'dragons_shadow_book_2':'776',
  'donor_to_a_vampire_c26':'809',
  'entre_dois_alfas_4_msbr':'6280',
  'gideon_02_turkish':'2256',
  'sharing_delilah_book_3':'1024',
  'rose_book_3_academy_of_the_rose':'623',
  'dancing_naked_2':'1081',
  'clawsc29_30':'571',
  'awakening_series':'7227',
  'sold_on_you_3':'800',
  'artemis_gift_book_2_manuscript':'3743',
  'test_story_lauren':'389',
  'emma_series_s_1_full_edit':'3205',
  'sold_on_you_4':'801',
  'primeira_chance_season_6_msbr':'6771',
  'meeting_him_turkish':'3435',
  'emma_series_c27_36':'4673',
  'finding_your_fate_yearning_test':'430',
  'beyond_the_pack_redo':'673',
  'carrero_series':'7228',
  'italian_heart_redo':'549',
  'torian_warriors_book_three':'3133',
  'fairy_godmother_inc_book_4':'1216',
  'i_didnt_belong_to_you_c5_11':'4674',
  'book_1_truth_and_lies':'699',
  'book_2_truth_and_lies':'700',
  'tears_of_tess':'702',
  'quintessentially_q':'703',
  'wolf_ranch_spanish':'3290',
  'the_body_painter':'705',
  'alpha_kings_claim_s_2_edited':'3206',
  'mateo_santiago_german_2':'646',
  'alphas_prey_bloodbound':'2728',
  'recomeco_ms':'3744',
  'indebted_book_one_ch_2':'712',
  'artemis_gift':'2065',
  'meeting_tr':'3437',
  'nephilimredo':'532',
  'indebted_missing_chapters':'711',
  'unfortunate_friends_s_2':'3134',
  'the_love_shack':'2071',
  'beautiful_mistake_s3':'441',
  'help_me_alpha_chapters_22_28':'566',
  'emma_book_three_c_1_15_manuscript':'3612',
  'wolfs_pursuit_copy':'725',
  'meeting_him_in_between_gpt_4_rewrite':'7230',
  'prologue_for_ash_falls':'726',
  'she_can_easily_kill_you':'2059',
  'someone_like_xavier':'2075',
  'tutor':'2076',
  'test_4':'6834',
  'rastros_de_furia_msbr':'4676',
  'dev_cowboy_boots_and_combat_boots':'137',
  'beautiful_belle_and_grayson_c49_61':'714',
  'mw_tr_7':'3207',
  'later_sex':'6461',
  'lost_princess_si_1_10':'2771',
  'twisted_minds_murder_wedding':'6460',
  'loving_mr_steel_io':'380',
  'conflict_of_interest':'583',
  'we_shouldnt':'619',
  'i_didnt_belong_to_you_gpt_4_rewrite':'7231',
  'superstar_and_the_super_nerd':'2073',
  'dev_survival_of_the_rose_action_edit':'157',
  'mated_king_2_2_ptbr':'3291',
  'sisters_wedding_tr':'3438',
  'a_love_like_this':'2170',
  'mafia_entanglement':'2204',
  'way_of_steel_tr':'3208',
  'dev_seeing_scarlett_c_test':'151',
  'falling_for_the_mafia_brother_gpt_4_rewri':'7232',
  'touchepi':'1083',
  'rebellious_surrender':'587',
  'reckless_surrender':'588',
  'advice_from_a_jilted_bride':'2246',
  'roomhate':'2298',
  'dev_undressed_by_the_king_synopsis_first_taps':'190',
  'dev_her_possessive_guard_j':'215',
  'millennium_wolves_s2_portuguese':'1088',
  'wolves_of_the_west_the_hunt_book_two':'4629',
  'belles_beast':'2189',
  'requiem_city_f_rewrite':'207',
  'you_left_me_gpt_4_rewrite':'7233',
  'requiem_city_g_test':'208',
  'dev_tranquility_resound':'185',
  'dev_colt_resound':'143',
  'oana_schuller_3_episode_test':'367',
  'half_blood_s_4_e_1_8':'2673',
  'requiem_city_freelancer_1_5':'3035',
  'main_source_ch36_45':'4240',
  'alpha_titus':'2753',
  'initials_only_flake_chapter':'379',
  'confessions_of_a_naughty_nanny':'2267',
  'fade_2_german':'2183',
  'seeing_scarlett_book_3_bordering_black':'7235',
  'her_possessive_guard_her_perfect_reboun':'4216',
  'stob3c24_26':'1101',
  'clawsredo':'545',
  'fighting_fate_chapter_30':'565',
  'the_wolf_wars_2_the_alphas_daughter':'519',
  'secrets_series':'6836',
  'mafia_royals_romance_book_three':'4894',
  'tmw_his_haze_c_5_end':'2933',
  'werewolf_king_tr_3':'3210',
  'my_brothers_roommate_c_21':'2769',
  'unexpected_flame_redo':'550',
  'reapers_claim_2_turkish':'3004',
  'guns_and_royalties_book_2':'2793',
  'barbarian':'2582',
  'fairy_godmother_7_ch1_6':'4493',
  'dev_her_possessive_guard_h':'194',
  'tranquility_pov_test':'196',
  'savage_3_turkish':'3068',
  'dev_bright_star_c_start':'181',
  'bqs3':'244',
  'michael_book_two':'1148',
  'colt_s_3_edited':'2182',
  'bq3_alt_op_fb':'246',
  'the_torian_warriors':'1151',
  'broken_queen_russian_2':'1181',
  'evie_darling_c51_58':'4339',
  'colt_3_ptbr':'2292',
  'grey_girl':'2492',
  'mssiawb_book_2':'249',
  'broken_queen_s4_real':'258',
  'fairy_godmother_inc_book_2':'1182',
  'wolves_of_the_west_the_hunt_book_three':'4630',
  'a_pequena_loba_2_msbr':'5187',
  'half_blood_author_prologue_rewrite':'2777',
  'strictly_baby_business':'2378',
  'ta_g_s3':'297',
  'lupus_paulo_russian_2':'1184',
  'ascn_s2_pt1':'1388',
  'fairy_god_mother_music_only_test':'4677',
  'tranquility_i_test_new_religious_themes':'239',
  'the_arrangement_xavier_test':'306',
  'andrea_tsurumi_accident':'5547',
  'the_boss':'7238',
  'aisling_triplets_a_stranger_s_touch':'5586',
  'the_final_diary_book_two_community':'4631',
  'os_lobos_do_oeste_guerra_sangrenta_2_ms':'4340',
  'imperfect_strangers':'2522',
  'alpha_and_aurora_relationship_cliffs':'4916',
  'caught_up_in_between_book_2':'1212',
  'mason_b2_c22_26':'1213',
  'uncontrollable_heat_c2_4_redo':'1285',
  'bq3_alt_op_action':'248',
  'bq3_alt_op_emotion':'247',
  'the_ex':'7239',
  'alpha_kaden_4_ptbr':'2865',
  'no_rastro_das_estrelas_deepl':'3293',
  'the_real_evie_chase_inkitt':'1292',
  'dev_story_testworkers':'62',
  'in_the_shadows_s_2':'2193',
  'os_lobos_do_oeste_season_4_ms':'3922',
  'best_and_honor':'79',
  'at_the_end_of_the_world_b2_1_10':'1306',
  'wolf_wars_russian_2':'1308',
  'undressed_by_the_king_b2_31_66':'1309',
  'uncontrollable_heat_c19_30':'1302',
  'dark_pleasures':'7242',
  'her_last_hope_book_2':'1290',
  'marrying_the_ceo_ep22_36':'1307',
  'the_sins_of_the_mother':'2543',
  'takenc65':'1294',
  'infinity_b2_c104_125':'1304',
  'walk19_20':'1299',
  'htmfinale':'1300',
  'claws_edited':'585',
  'mafia_royals_romance_book_two':'4893',
  'donor24':'1298',
  'reap_ch_7_10':'261',
  'dev_undressed_by_the_king_i':'200',
  'marked_3_ptbr':'3005',
  'heal_my_soul_ep_7_8':'2684',
  'millenium_wolves_telepathic_connection':'5985',
  'caught_between_two_billionaires_season_2':'1324',
  'her_blood':'1341',
  'at_the_end_of_the_world_1_2':'3214',
  'hidden_truth':'2587',
  'test_pepper_winters':'1336',
  'akhm_s2_ch45_55':'1328',
  'power_book_2_pearl_dragon':'1346',
  'their_little_human_final_chapters':'1245',
  'kings_series_book_2':'1350',
  'kings_series_book_3':'1351',
  'in_the_shadows_s_3':'2196',
  'stolen_by_the_alpha_3_russian':'1455',
  'wolves_of_the_west_book_3':'3137',
  'the_millennium_wolves_2_android':'118',
  'the_alphas_call_no_two':'209',
  'the_milf':'7241',
  'alpha_loren_1_6':'3924',
  'dev_ac_s3_copy':'302',
  'sexy_stepbrother_russian_2':'1344',
  'werewolf_chronicles_2_russian':'1452',
  'marked_3_turkish':'3091',
  'the_deerborn_series_book_5_c1_10':'6841',
  'falling_for_the_bad_boys':'7243',
  'madhouse_c43_52':'5106',
  'power_book_3_war_of_the_dragons':'1347',
  'dev_broken_queen_s1_opening':'214',
  'testing_branches':'6843',
  'ntoobook2':'1314',
  'mw_his_haze':'2652',
  'we_are_bear_tr':'3215',
  'vendeleer_brothers_s_6':'2938',
  'werewolf_chronicles_3_turkish':'2688',
  'power_book_5_reign_of_the_dragon_queen':'1360',
  'marketing_test':'529',
  'mateo_santiago_ptbr_2':'1364',
  'meeting_him_in_between_redo':'557',
  'her_beautiful_addiction':'2733',
  'the_alpha_and_aurora_the_finale':'7244',
  'beta_stone_b':'260',
  'alpha_kaden_russian_2':'1399',
  'alpha_kaden_copy_paste_test':'266',
  'dev_tranquility_i_test':'217',
  'fable_of_happiness_book_2':'1363',
  'power_book_6_dragons_wrath':'1361',
  'quicker_intro':'6842',
  'her_possessive_guard_2_ptbr':'2210',
  'immersive_demonstration':'6846',
  'her_last_hope_2_ptbr':'2208',
  'wolf_wars_book_3_c_22_26':'2211',
  'savage_finale':'7245',
  'broken_queen_5_ptbr':'2732',
  'silent_embrace_tr':'3216',
  'contract_killer_test':'3138',
  'my_sexy_stepbrother_is_a_werebear_2_turk':'2295',
  'fairy_tale_twists_and_original_stories':'3102',
  'the_lost_princess_deepltest_ptbr':'3108',
  'you_left_me':'2789',
  'tormenta_ms':'3818',
  'lupus_paulo_3_ptbr':'2207',
  'table_eleven':'2794',
  'perfect_spiral':'2797',
  'mated_king':'120',
  'madilynne':'3103',
  'beautiful_killer_s_2_c_1_3':'3220',
  'dev_broken_queen':'152',
  'toxic':'471',
  'alphas_king_claim_german_2':'3007',
  'touch_43':'5177',
  'seeking_happiness_s_5_c_37_44':'2692',
  'dev_reapers_claim_e':'212',
  'an_alphas_vixen_c_38_44_epi':'2693',
  'mason_book_3':'6286',
  'her_possessive_guard_author_test':'2698',
  'claiming_celia':'345',
  'tranquility_h_test':'210',
  'cross_to_bear_s_2':'2694',
  'dev_her_possessive_guard_i':'213',
  'cross_to_bear_s_3':'2696',
  'ac_s1_ep21_30':'240',
  'wolf_ranch_book_3_spanish':'4244',
  'the_alphas_call_4_french':'2695',
  'fairy_godmother_inc_s_2_5_edited':'2699',
  'mssiawb3':'268',
  'ravens_of_the_mist_book_2_endless_love':'6471',
  'fairy_godmother_inc_1_6':'4760',
  'wolf_ranch_book_3_german':'4245',
  'three_the_perfect_number_2':'4130',
  'if_these_walls_could_talk':'2735',
  'help_me_alpha_reissue':'2808',
  'lycans_queen_s3_edited':'4248',
  'fairy_godmother_inc_s_5_edited':'2700',
  'bantering_with_murder_3':'4347',
  'reap_claim_arr_inspo':'344',
  'mw_german_s5_dev':'269',
  'touch_43_new':'5179',
  'bantering_with_murder_2':'4256',
  'zach_ch_1':'6670',
  'eyes_in_us_3_german':'3821',
  'different_full_sound':'7249',
  'jasper_o_alfa_season_2':'3626',
  'coming_home_to_a_place_ive_never_been_sp':'2981',
  'the_replacement_rewrite':'211',
  'guns_and_royalties':'2792',
  'finding_your_fate_mate_anticipation':'2876',
  'dev_lupus_paulo_f':'204',
  'a_virada_season_2_ms':'3822',
  'marrying_the_ceo_s2_ch37_40':'4257',
  'wolf_ranch_book_3_feral_italian':'4246',
  'a_vida_de_emma_season_2_ms':'4249',
  'chamberlain_test':'3139',
  'onyx_dragon_si_hire':'4133',
  'underground_kings_season_3':'1329',
  'my_cowboy_s2':'176',
  'fftmb_gpt_4_rewrite':'7250',
  'trick_or_treat':'2904',
  'punished_by_the_alpha_3_ptbr':'2740',
  'queen_in_shadows':'2936',
  'wolf_ranch_book_3_french':'4247',
  'the_alphas_call_s4':'175',
  'dev_saving_maximus':'159',
  'perigo_a_espreita_msbr':'4349',
  'no_split':'5277',
  'ch_1_rewrite':'6293',
  'chasing_omega_2_turkish':'2736',
  'dev_dragons_princess_j':'135',
  'dev_evie_trial':'155',
  'dev_tranquility_j_test_lilac_pov':'216',
  'fighting_darius_1_2_6_8_resound':'7251',
  'mr_knight_tr':'3224',
  'alpha_3':'3449',
  'dev_content_story':'65',
  'caged_book_two':'4607',
  'si_test_example':'4134',
  'nico_dev_story':'262',
  'the_alphas_call_s1_b':'191',
  'home_wrecking_alpha_1_4':'2877',
  'trapping_quincy':'305',
  'danas_dev_story':'318',
  'death_and_all_his_friends':'1536',
  'the_arrangement_brads_death':'199',
  'the_alphas_call_new_opening_one':'202',
  'ao_cair_da_noite_msbr':'4623',
  'ravenous_episode_1':'2875',
  'bm_ch_1_test':'264',
  'a_procura_de_um_pretendente_msbr':'5239',
  'tmw_his_haze_german_cs':'2986',
  'dev_story':'61',
  'reapers_claim_copy_paste_oskar':'270',
  'her_last_hope_full_edit':'3140',
  'journeys_end_edit':'2544',
  'the_arrangement_book_6_manuscript':'3826',
  'ta_ger_verdeutscht':'276',
  'high_school_with_my_fiance_author_test':'5052',
  'mating_ceremony':'5499',
  'wolves_of_the_west_book_2':'3136',
  'saving_maximus_cp':'291',
  'alpha_kaden_heat_test':'308',
  'madhouse_ch33_37':'4380',
  'risking_it_all':'1639',
  'destroying_the_ceo':'252',
  'dragons_blood':'772',
  'at_the_end_of_the_world_s_3_c_1_12':'3827',
  'uncontrollable_heat_c6_10':'5416',
  'the_fire_seal_book_two':'5500',
  'marked_shay_cliffs':'5053',
  'onyx_dragon_example':'4381',
  'marrying_the_ceo_enhanced_drama':'5114',
  'falling_for_my_bodyguard_author_test':'5054',
  'indebted':'690',
  'damien':'444',
  'silent_embrace_si_example':'4370',
  'i_didnt_belong_to_you_c12_16':'5180',
  'end_of_the_world_de_2':'4456',
  'the_chamberlain_files_book_four':'5415',
  'forbidden_touches':'1672',
  'in_it_to_win_it_book_two':'4613',
  'the_wicked_barber_split_release':'7257',
  'alphas_call_season_2_new':'283',
  'broken_queen':'69',
  'torian_warriors_2_ptbr':'3043',
  'alpha_kaden_2':'280',
  'the_final_diary_author_test':'5055',
  'entre_dois_alfas_msbr':'4371',
  'destroying_the_ceo_cp':'292',
  'the_arrangement':'84',
  'beautiful_belle_and_alpha_grayson_s2':'301',
  'body_37_c33_37':'4398',
  'mated_king_2':'90',
  'alpha_titus_c26_31':'4682',
  'bq_s1_ep21_30':'310',
  'the_alphas_call_sugarandspice_test':'332',
  'alpha_and_omega_split_release':'7259',
  'safety_of_his_arms_book_two_connor':'5355',
  'artemis_3_tr':'6851',
  'fairy_godmother_inc_2_ptbr':'3110',
  'marcello_3_tr':'7260',
  'touch_human_translation_german':'3361',
  'dangerous_love':'3084',
  'close':'3135',
  'gideon_cliff_tests_15_17':'423',
  'broken_queen_s1_ger_3_ep_am':'272',
  'onyx_dragon_cliff_subdom':'4762',
  'ta_ger_denglish':'277',
  'mw_ger_denglish_test':'275',
  'meeting_my_abductor_aesthetic_shift':'5315',
  'somewhere_above_the_clouds':'3338',
  'meu_alfa_mw_odeia':'1089',
  'polyamory_loving_the_carters':'3092',
  'at_the_end_of_the_world_c162_166':'4527',
  'the_arrangement_cp':'300',
  'an_alphas_vixen_pacing_tension':'4763',
  'fairy_godmother_s_6_e_19_24':'2711',
  'bm_si_kein_alden':'333',
  'half_blood_s_4_e_9_15':'2714',
  'lovely_liaison':'121',
  'lp_test_ch_six':'319',
  'finding_your_fate_polish_cs':'3156',
  'alpha_kaden_romance_test_s1':'295',
  'ruling_the_ceo_german_2':'3074',
  'different_book_two_times_two':'5313',
  'lycans_queen_7_10':'3229',
  'beautiful_mistake_test_21_22_23':'282',
  'hotcast_c22_26':'4458',
  'nos_bracos_do_silencio_season_3_msbr':'4387',
  'brokenqueens1_hea_test':'307',
  'wotw_feral_war_book_3':'3637',
  'a_vida_dos_sonhos_msbr':'5113',
  'marrying_ceo_ch_21_25':'2712',
  'coming_home_to_a_place_ive_never_been_tr':'3075',
  'page_splitting_test':'346',
  'twisted_minds_2_ptbr':'2568',
  'tell_no_lies_c11_24':'5314',
  'gideon_turkish_human_translation':'2573',
  'de_arrangement_machine_edit':'2569',
  'my_master':'497',
  'fairy_godmother_5':'4016',
  'gideon_agency_sequel':'4367',
  'devassa_msbr':'4528',
  'guerra_sangrenta_season_3_msbr':'4459',
  'alphas_call_3_turkish':'2570',
  'tranquility_2_turkish':'2571',
  'twisted_minds_2_turkish':'2572',
  'torian_warriors_german_4':'4267',
  'reapers_claim':'92',
  'correntes_de_dragao':'3638',
  'just_the_little_sister':'518',
  'a_humana_perdida_ms':'4017',
  'seeking_happiness_2_de':'4529',
  'fr_arrangement_machine_edit':'2564',
  'yes_mr_larson_s_2':'3096',
  'erro_fatal_ms':'3943',
  'alpha_ares_e_22_29':'2713',
  'colisao_msbr':'4554',
  'the_lost_princess':'325',
  'seduced_by_the_moon':'6855',
  'gideon_4_polish':'2860',
  'alpha_kings_hybrid_mate_2_ptbr':'2861',
  'half_blood_full_sound':'6868',
  'lost_princess_3_turkish':'4868',
  'sangue_de_dragao_season_2':'3643',
  'pt_2_foreshadowing':'6870',
  'al_nadaha_ptbr_ms':'3944',
  'marked_book_four_eyes_of_shadow':'4877',
  'my_dark_romeo_bonus_epilogue':'6886',
  'tres_o_numero_perfeito_season_2_msbr':'4403',
  'alvorada_perpetua_season_3_msbr':'4579',
  'guerra_dos_lobos_season_3_pt_2msbr':'4617',
  'the_king_without_a_queen_book_2_c1_10':'6904',
  'dragons_shadow_tr':'3460',
  'the_arrangement_6_ptbr':'2880',
  'reapers_claim_test_4_1_9_age_test':'327',
  'help_me_alpha_c_41_44':'2947',
  'mason_edited_first_5':'2882',
  'rc_rdl_test':'354',
  'run':'6869',
  'mafia':'6857',
  'snap_47_51':'5119',
  'frente_a_frente_com_meu_raptor_3_msbr':'6889',
  'rc_s1_22_27':'362',
  'reapers_claim_copypaste_s2':'288',
  'alpha_and_aurora_1_7':'2719',
  'propriedade_do_alfa_season_2_msbr':'6894',
  'pedaco_do_ceu_msbr':'4404',
  'mason_1_5':'2884',
  'mason_story_intel_1_5':'2885',
  'in_the_shadows':'2191',
  'a_princesa_perdida_season_3_msbr':'4869',
  'when_the_night_falls_codename_the_devil':'6858',
  'close_s_2':'3232',
  'my_cowboy':'67',
  'reapers_claim_copypaste_s4':'290',
  'my_sisters_wedding_s_1_edit':'3141',
  'alpha_kaden_s2':'330',
  'rescue_my_drowning_heart':'6906',
  'fairy_godmother_3_tr':'3461',
  'torian_warriors_book_two':'2832',
  'hated_by_my_mate_remove_rejection_cliffs':'3128',
  'the_fairytales_series_book_two_love_you':'5999',
  'potential_suitor_book_2':'2893',
  'a_vida_de_emma_ms':'4135',
  'brazen_expanding_lovetriangle':'4872',
  'their_little_human_42_55':'4881',
  'way_dark_test':'3142',
  'alpha_and_aurora_dream_mates_buildup':'6859',
  'mason_suspense_rework_2':'7268',
  'fairy_godmother_4_tr':'3462',
  'meeting_my_abductor_again_1_17':'4917',
  'the_millenium_wolves_cp':'311',
  'help_me_alpha_s_2_c_1_11':'2949',
  'tmw_his_haze_c_1_3':'2834',
  'os_guerreiros_de_torian_season_3_ms':'4136',
  'al_nadaha':'3580',
  'at_the_end_of_the_world_c_139_150':'2950',
  'marcello_o_mafioso_season_3_msbr':'6860',
  'table_eleven_42_52':'4918',
  'artemis_2_tr':'4686',
  'the_heir_of_the_beast':'6676',
  'falling_for_mafia_2_turkish':'3009',
  'tmw_happy_holidays':'110',
  'fgi_the_heir_of_the_beast':'6677',
  'reapers_claim_copypaste_s3':'289',
  'my_possessive_mate_s_2':'3098',
  'captured_by_the_mafia_king_gpt_4_rewrite':'7270',
  'initials_only_edited':'393',
  'humanborn_edited':'404',
  'desires_beyond_the_lens':'424',
  'emma_series_c37_41':'5281',
  'dragons_leash_tr':'3464',
  'contract_killer':'562',
  'tas1_re_s':'352',
  'alpha_kaden_freelancer_3_7':'3086',
  'emma_test':'337',
  'stockholm_syndrome_v_2':'7271',
  'mssiawb_f_less_douchey_test':'340',
  'alpha_kaden_s3':'349',
  'help_me_alpha_chapters_20_21':'402',
  'fighting_fate_chapter_27':'397',
  'capture':'456',
  'fighting_fate_chapter_28_29':'409',
  'lqs2':'427',
  'jogo_de_seducao_2_msbr':'4920',
  'a_rogues_choice_chapter_26_27':'398',
  'the_millenium_wolves_6_ptbr':'3077',
  'tension_mystery_cliffs':'6210',
  'refocus_structure_2':'5368',
  'beautiful_killer':'3219',
  'beastly_lights':'146',
  'acs2_wed_intro':'396',
  'the_way_to_steel':'76',
  'aa_secret_test':'429',
  'finding_your_fate_lighten_up_test':'431',
  'g_19_to_20_rearranged':'412',
  'tamera_test':'417',
  'feral_wars_4_de':'4921',
  'fast_pacing':'5317',
  'alphas_king_claim_2':'4159',
  'her_light_1_3':'4280',
  'seeking_happiness_s_1_edit':'3143',
  'coming_home_to_a_place_ive_never_been_it':'3078',
  'valentines_day00the_wolf_wars_beyond_the_border00':'6480',
  'meu_alfa_me_odeia':'1091',
  'my_favor_to_the_bad_boy':'3222',
  'chasing_kiarra_wolf_attack':'3234',
  'my_favour_to_the_bad_boy':'3231',
  'gideon_4_turkish':'2951',
  'wotw_feral_war_book_4':'3651',
  'finding_your_fate_2':'572',
  'the_arrangement_s1_ep_1_less_explicit_xa':'446',
  'd_copy_nosound':'5318',
  'high_school_w_my_fiance_1_3':'4281',
  'never_have_i_ever':'3457',
  'gideon_destined_for_more':'6882',
  'falling_for_the_mafia_brothers_2_ptbr':'3235',
  'd_copy_no_audio':'5319',
  'stolen_by_the_alpha_book_4_c124_138':'6221',
  'nos_bracos_do_silencio_season_2_ms':'4161',
  'lillys_king_1_3':'4282',
  'restructure':'4928',
  'gideon_destined_for_true_love':'6883',
  'wolves_of_the_west_book_4':'3236',
  'youve_got_a_dm':'3450',
  'amor_que_consome_ms':'4027',
  'bodyguard':'3923',
  'beautiful_belle_and_grayson_c44_48_io':'416',
  'feast_of_crones':'3949',
  'when_the_night_falls_c32_44':'4410',
  'na_mira_dos_holofotes_season_2':'3468',
  'broken_queen_s4_happy_ending_test':'440',
  'lawyer_up':'3454',
  'dragons_blood_2_german':'3469',
  'despida_para_o_rei':'3237',
  'na_mira_dos_holofotes_season_4_ms':'4028',
  'immersive_sound_settings':'7275',
  'crane':'3491',
  'bitten_book_2_bitten_again':'526',
  'bitten_book_3_bitten_babe':'527',
  'journeys_end_part_2_german':'3238',
  'os_lobos_do_milenio_season_7':'3316',
  'na_mira_dos_holofotes_season_2_ptbr':'3470',
  'deerborn':'3578',
  'hated_by_my_mate_c35_39':'4855',
  'falling_for_my_bodyguard_1_3':'4284',
  'artemis_tr':'3471',
  'wolfs_pursuit':'449',
  'bitten_book_4_bitten_kitten':'528',
  'mason_6_15':'3317',
  'falling_for_the_mafia_brothers_1_3':'4283',
  'kryn':'508',
  'neil_gaiman_neverwhere':'5558',
  'wolf_wars_cliffs':'1123',
  'guns_and_royalties_c54_74':'4924',
  'new_beautiful_mistake_s3':'442',
  'slave_of_the_onyx_dragon_1_3':'4285',
  'black_heart_book_4_fevered_keys':'6485',
  'my_sexy_stepbrother_is_a_werebear_french_cs':'3593',
  'a_sombra_do_dragao_ptbr':'3473',
  'ceo_turkish':'3121',
  'alphaa_turkish':'3122',
  'romance_focus':'6482',
  'my_warrior_1_3':'4286',
  'mafia_royals_romance_series':'4878',
  'two_years_gone':'4923',
  'feita_para_o_alfa_rei_season_2_ms':'4146',
  'half_blood_s_1_full_edit':'3144',
  'chasing_kiarra_extensive_test_1':'1238',
  'final_diary_1_3':'4287',
  'hated_by_my_mate_s2_cliffs':'1145',
  'seeking_happiness_4_tr':'5195',
  'just_friends':'505',
  'just_another_wolf_story':'3634',
  'eyes_on_us_s_3':'3242',
  'imperial_kings':'7278',
  'fada_madrinha_ltda_season_5_ms':'4294',
  'finding_your_fate_1_10':'4598',
  'fight_darius_more':'6782',
  'living_louder':'538',
  'moon_river_s2_e20_21':'4036',
  'immortal_3_tr':'3243',
  'fgi_higher_stakes':'6777',
  'snap_s5_ch30_35':'4295',
  'erotic':'5191',
  'colt_polish_cs':'3710',
  'nascida_humana_season_2_msbr':'7280',
  'grayson_no_b':'5130',
  'feral_wolves_3_de':'4289',
  'pride_and_prejudice':'7077',
  'a_rainha_das_sombras_msbr':'5282',
  'wotw_feral_war_book_2':'3246',
  'haunted_copy_paste_fixed':'1642',
  'capture_me_book_three_love_me':'4644',
  'casando_com_o_ceo_extensive_edit':'5367',
  'emma':'7281',
  'dublin_files':'4993',
  'vida_de_urso_msbr':'4932',
  'o_guardiao_possessivo_season_2_msbr':'4657',
  'scotch_on_the_rocks':'2802',
  'difficult_dukes_a_duke_in_shining_armor':'5008',
  'rejection_and_its_consequences':'4913',
  'o_ceo_bilionario_2_msbr':'5325',
  'author_more_angst':'5059',
  'tension':'6688',
  'hellion_mc_book_two_the_reverb_effect':'6006',
  'amigos_por_acaso_season_2_ms':'4039',
  'fayre':'4218',
  'mansfield_park':'7282',
  'wolves_of_the_west_2_german':'3319',
  'touch_2_de':'5323',
  'infinity_human_translation_german':'3369',
  'a_broken_promise':'1411',
  'persuasion':'7283',
  'comicstest_mw_illustrated_version':'6689',
  'walsh_family_watermelon':'5004',
  'hidden_legacy_burn_for_me':'5016',
  'i_choose_you':'4713',
  'blue_line_detective':'4972',
  'then_and_now_c17_21':'5061',
  'mordida_por_el_alfa_2':'869',
  'streamlined_intro':'7284',
  'alpha_and_the_warrior':'4626',
  'doce_tentacao_msbr':'4502',
  'mein_heisser_stiefbruder_ist_ein_werbar_3':'864',
  'ai_rewrite':'7285',
  'alpha_kings_claim_s_1_edited':'3145',
  'lost_princess_timeskip_fill':'4556',
  'more_werewolf':'4935',
  'friends_falling_book_two':'5504',
  'recomeco_2_msbr':'4503',
  'alpha_loren_book_4':'7286',
  'walsh_family_angels':'4999',
  'walsh_family_anybody_out_there':'5001',
  'mason_16_70_edit':'4042',
  'seeking_happiness_more_werewolf':'4936',
  'a_princesa_e_o_dragao_season_2':'3667',
  'meu_alfa_me_odeia_extensive_edit':'5365',
  'cura_para_um_coracao_msbr':'4419',
  'kings_gift_series':'5232',
  'fairytales_series':'5079',
  'healing_hands':'5085',
  'youre_mine_and_only_mine':'5229',
  'eyes_on_us_s_4':'3322',
  'devil_vs_alpha_spanish_2':'1458',
  'seven_sinners_series':'5182',
  'filthy_rich_husband':'7287',
  'reapers_claim_3_turkish':'3252',
  'my_perfect_idiot':'732',
  'death_series':'5185',
  'broken_wings':'2579',
  'vixen_turkish':'3374',
  'broken_queen_ep3_water_tower_test':'494',
  'faithless':'5201',
  'my_first_i_can_read_the_adventures_of_paddington_pancake_day':'5542',
  'dirk_steele_series_the_last_twilight':'5623',
  'not_another_office_romance':'7288',
  'his_christmas_wish':'6200',
  'fairy_godmother_2_german':'2995',
  'no_split_s_2':'6011',
  'o_chamado_do_alfa_extensive_edit':'5366',
  'bq_chapter_7_alpha_vs_alpha_test':'495',
  'finding_your_fate_cliff_test':'604',
  'blame_it_on_paris':'5449',
  'the_offspring_beyond_the_darkness':'5629',
  'finding_a_mate':'6010',
  'o_dom_de_artemis_season_3_msbr':'7289',
  'i_was_a_bitch':'4615',
  'the_alphas_twins':'4974',
  'whats_done_in_the_dark':'5386',
  'when_you_least_expect_it':'5450',
  'pete_the_cat_pete_the_kitty_s_first_day_of_preschool':'5693',
  'neil_gaiman_american_gods_the_tenth_anniversary_edition':'5695',
  'mhairi_mcfarlane_don_t_you_forget_about_me':'5727',
  'the_ravenels_cold_hearted_rake':'5740',
  'the_brown_sisters_act_your_age_eve_brown':'5770',
  'cynthia_d_aprix_sweeney_good_company':'5772',
  'hate_to_love_you':'6711',
  'then_and_now':'3486',
  'sweet_temptation_gpt_4_edit':'7290',
  'hotcast':'3753',
  'walsh_family_rachels_holiday':'5007',
  'bridgerton_the_viscount_who_loved_me':'5017',
  'a_vamp_city_novel_a_blood_seduction':'5018',
  'artemis_gift_de_2':'4829',
  'wait_for_you_stay_with_me':'5039',
  'alphas_pet_book_2_the_alphas_mate':'6308',
  'heartless_summer':'6712',
  'julia_quinn_minx':'5869',
  'wntf_ch_2_edit':'7291',
  'back_into_darkness':'1067',
  'alfa_e_aurora_season_4_msbr':'4882',
  'wudang_heaven_to_wudang':'5038',
  'a_legacy_for_katherine':'3954',
  'more_jason':'6497',
  'ravens_of_the_mist':'5046',
  'i_heart_i_heart_new_york':'5009',
  'bride_trilogy_with_my_body':'5014',
  'adrian_dev_story':'1554',
  'hidden_legacy_white_hot':'5019',
  'ruth_galloway_mysteries_the_night_hawks':'5719',
  'a_vamp_city_novel_a_kiss_of_blood':'5021',
  'the_billionaire_beach_boy':'4745',
  'sangue_de_dragao_season_3_ms':'4174',
  'hidden_legacy_wildfire':'5022',
  'one_of_us':'6498',
  'love_hate_mail':'5110',
  'au_with_al_editing':'6499',
  'mating_dream':'6309',
  'hellion_mc_series':'5237',
  'honoree_fanonne_jeffers_the_love_songs_of_w_e_b_du_bois':'5699',
  'the_adventures_of_paddington_the_adventures_of_paddington_the_monster_hunt':'5541',
  'vampires_of_crimson_bay_series_vamped_up':'5572',
  'margaret_weis_fallen_angel':'5578',
  'bending_the_rules':'263',
  'broken_pieces':'3955',
  'bride_trilogy_i_take_you':'5024',
  'author_062023':'7293',
  'bridgerton_the_duke_and_i':'5025',
  'the_ether_chronicles_skies_of_fire':'5597',
  'grant_county_thrillers_blindsighted':'5704',
  'wudang_hell_to_heaven':'5037',
  'criada_por_vampiros_msbr':'4421',
  'beneath_the_scars':'5181',
  'time_chronicles':'5186',
  'friends_falling_series':'5236',
  'jennifer_robson_the_gown':'5906',
  'the_bronze_horseman_the_summer_garden':'5929',
  'silverbone':'6005',
  'al_with_a_ufirst_5_eps':'6500',
  'racing_hearts':'1570',
  'alpha_and_luna_series':'7296',
  'vanessa_riley_island_queen':'5835',
  'karin_slaughter_the_silent_wife':'5903',
  'the_seen_trilogy_he_watches_me':'5935',
  'billionaires_and_bikers_sinful_rewards_2':'5943',
  'penny_junor_the_duchess':'5990',
  'fate_of_the_fathers_c27_50':'4942',
  'kate_quinn_the_huntress':'5850',
  'tessa_bailey_it_happened_one_summer':'5851',
  'world_of_howl_howl_s_moving_castle':'5863',
  'giver_quartet_the_giver':'5868',
  'neal_stephenson_cryptonomicon':'5872',
  'michael':'871',
  'my_dream_boy':'2650',
  'liane_moriarty_three_wishes':'5563',
  'love_at_stake_forbidden_nights_with_a_vampire':'5574',
  'the_offspring_out_of_the_darkness':'5577',
  'the_dream_guardians_series_pleasures_of_the_night':'5605',
  'fusion_listen_to_me':'5609',
  'final_asylum_tales_inner_demon':'5655',
  'quentin_tarantino_once_upon_a_time_in_hollywood':'5690',
  'susan_elizabeth_phillips_when_stars_collide':'5880',
  'barbara_delinsky_a_woman_betrayed':'5881',
  'uma_proposta_indecente_season_3':'1186',
  'kenzo_season_4_msbr':'7298',
  'half_blood_flash_forward':'6502',
  'the_offspring_touching_darkness':'5589',
  'discworld_guards_guards':'5871',
  'julia_quinn_ten_things_i_love_about_you':'5877',
  'kate_quinn_the_alice_network':'5882',
  'soren_sveistrup_the_chestnut_man':'5887',
  'anthony_veasna_so_afterparties':'5890',
  'lisa_kleypas_devil_s_daughter':'5897',
  'o_dom_de_artemis_season_2_msbr':'4690',
  'dark_days_series_wait_for_dusk':'5635',
  'love_at_stake_all_i_want_for_christmas_is_a_vampire':'5637',
  'three_card_monte':'1539',
  'sheriffs_deputy':'7299',
  'schizophrenia':'1703',
  'rules_for_dating_your_ex':'2276',
  'alphas_of_south_forest':'3951',
  'hollows_for_a_few_demons_more':'5638',
  'falling_for_the_mafia_brothers_ep_1_gpt':'7300',
  'dark_ones_series_sex_and_the_single_vampire':'5645',
  'george_orwell_animal_farm':'5741',
  'jessica_blackwood_angel_killer':'5743',
  'the_selection_the_heir':'5796',
  'julia_quinn_splendid':'5800',
  'george_orwell_animal_farm_and_1984':'5814',
  'ruth_galloway_mysteries_the_crossing_places':'5852',
  'bess_crawford_mysteries_an_irish_hostage':'5876',
  'red_queen_red_queen':'5893',
  'the_lord_of_the_rings_the_return_of_the_king':'5894',
  'inspector_ian_rutledge_mysteries_a_divided_loyalty':'5895',
  'stephanie_dray_my_dear_hamilton':'5904',
  'monster_mansion':'6518',
  'mw_book_2_turksih':'1561',
  'a_luna_prometida_msbr':'5428',
  'amigos_por_acaso':'3673',
  'a_love_at_stake_novella_a_very_vampy_christmas':'5673',
  'nikki_gemmell_with_my_body':'5681',
  'the_ravenels_devil_in_spring':'5691',
  'hercule_poirot_mysteries_the_murder_of_roger_ackroyd':'5734',
  'margaret_atwood_the_handmaid_s_tale':'5759',
  'gabriel_allon_the_other_woman':'5792',
  'a_hawthorne_and_horowitz_mystery_the_word_is_murder':'5801',
  'red_queen_war_storm':'5805',
  'janet_evanovich_hero_at_large':'5808',
  'o_bombeiro_msbr':'5327',
  'the_daevabad_trilogy_the_kingdom_of_copper':'5809',
  'seeing_scarlett_2_spanish':'7301',
  'joe_hill_20th_century_ghosts':'5817',
  'darkest_temptation':'454',
  'agatha_christie_and_then_there_were_none':'5701',
  'league_of_pensioners_the_little_old_lady_who_broke_all_the_rules':'5706',
  'stone_barrington_dead_in_the_water':'5707',
  'gregg_hurwitz_the_kill_clause':'5751',
  'tessa_bailey_love_her_or_lose_her':'5767',
  'a_leaphorn_and_chee_novel_the_blessing_way':'5783',
  'summer_beach_summer_on_the_bluffs':'5795',
  'big_bad_2_tr':'5427',
  'jewel_in_the_crown_valentine':'6490',
  'wotw_the_she_wolf':'6520',
  'the_alphas_beta_mate_book_two':'5429',
  'red_queen_king_s_cage':'5728',
  'shatter_me_imagine_me':'5736',
  'ava_reid_the_wolf_and_the_woodsman':'5753',
  'joanna_ho_eyes_that_kiss_in_the_corners':'5765',
  'the_earthsea_cycle_a_wizard_of_earthsea':'5789',
  'gabriel_allon_the_new_girl':'5791',
  'kate_elizabeth_russell_my_dark_vanessa':'5848',
  'tim_o_brien_the_things_they_carried':'5862',
  'emily_itami_fault_lines':'5864',
  'these_hollow_vows_these_hollow_vows':'5874',
  'parry_gripp_it_s_raining_tacos':'5878',
  'ann_patchett_the_dutch_house':'5914',
  'flash_forward':'6513',
  'philip_k_dick_the_man_in_the_high_castle':'5916',
  'mafia_captives':'6721',
  'little_dove':'5233',
  'nadia_hashimi_sparks_like_stars':'5778',
  'dianna_rostad_you_belong_here_now':'5781',
  'kate_quinn_the_rose_code':'5793',
  'ashley_franklin_not_quite_snow_white':'5794',
  'jessica_anya_blau_mary_jane':'5861',
  'hercule_poirot_mysteries_cards_on_the_table':'5867',
  'will_leitch_how_lucky':'5883',
  'mitch_albom_the_magic_strings_of_frankie_presto':'5884',
  'julia_quinn_how_to_marry_a_marquis':'5886',
  'sally_thorne_the_hating_game':'5902',
  'wait_for_you_saga_wait_for_you':'5938',
  'alexandria_bellefleur_hang_the_moon':'5944',
  'then_and_now_c12_16':'4424',
  'moon_river_test':'1669',
  'marriage_counselor':'6111',
  'fighting_my_orc':'3490',
  'deadly_angels_kiss_of_wrath':'6160',
  'wayfarers_the_long_way_to_a_small_angry_planet':'5818',
  'karen_harper_the_queen_s_secret':'5905',
  'guilty_series_she_s_no_princess':'5994',
  'simon_dixon_catherine_the_great':'5995',
  'day_they_met':'6110',
  'yes_mr_knight_2_de':'5335',
  'karin_slaughter_false_witness':'5889',
  'guerra_sangrenta_season_4_msbr':'4947',
  'dog_walking_in_heels_split_release':'7302',
  'mw_christmas':'394',
  'travis':'6718',
  'high_stakes_intro':'7303',
  'colt_german_2':'833',
  'requiem_city_resound_branch_m':'6203',
  'neal_stephenson_seveneves':'5898',
  'chasing_the_omega_3':'4746',
  'wayfarers_record_of_a_spaceborn_few':'5901',
  'finding_my_way':'1731',
  'gideon_resound_branch_w':'6204',
  'the_ravenels_marrying_winterborne':'5907',
  'a_leaphorn_chee_manuelito_novel_cave_of_bones':'5909',
  'matthew_quirk_the_night_agent':'5915',
  'maureen_johnson_the_box_in_the_woods':'5923',
  'tiffany_d_jackson_monday_s_not_coming':'5924',
  'jarvis_jay_masters_that_bird_has_my_wings':'5927',
  'half_blood_all_hands_demonstration':'7304',
  'lost_princess_1_10_v2':'3680',
  'sara_nisha_adams_the_reading_list':'5910',
  'wait_for_you_saga_stay_with_me':'5926',
  'a_breakwater_bay_novella_newport_dreams':'6157',
  'amor_a_primeira_parada_msbr':'4427',
  'bq_ep_3_romance_cliff':'496',
  'egomaniac':'622',
  'fgi_higher_stakes_opening':'6517',
  'cold_secrets':'1666',
  'cave_of_time':'6719',
  'the_arrangement_ar_episodes_1':'891',
  'test_book':'1055',
  'alphas_vixen_upload_example':'4693',
  'taking_a_chance_on_love':'2458',
  'alex_light_the_upside_of_falling':'5917',
  'neil_gaiman_the_ocean_at_the_end_of_the_lane':'5919',
  'nancy_johnson_the_kindest_lie':'5920',
  'kacen_callender_felix_ever_after':'5921',
  'players_2_de':'4948',
  'a_pequena_loba_msbr':'4954',
  'minha_gatinha_msbr':'5072',
  'brush_with_fame':'1159',
  'alexis_daria_you_had_me_at_hola':'5922',
  'bridgertons_an_offer_from_a_gentleman':'5925',
  'falling_for_my_brothers_best_friend':'2264',
  'the_seen_trilogy_he_touches_me':'5934',
  'alexandria_bellefleur_written_in_the_stars':'5937',
  'yes_mr_knight_build_tension':'5155',
  'my_girl':'6253',
  'hitchhiker':'6285',
  'the_gentleman':'1699',
  'keeper_vs_reaper':'6722',
  'not_all_about_you_10ch_test_ambience_sfx':'3761',
  'dev_german_test_1':'226',
  'hells_company_series':'6020',
  'immortal_series':'6287',
  'yes_mr_knight_2_tr':'4695',
  'os_lobos_do_oeste_a_cacada_3_msbr':'6311',
  'my_cadillac_billionaire':'6018',
  'lycans_queen_break_into_lycan_academy':'7307',
  'oblivion_series':'6399',
  'unexpected_love':'6402',
  'queen_of_his_heart':'6019',
  'hotel_lamia':'6040',
  'finding_you':'6076',
  'judy_test':'7308',
  'at_the_end_of_the_world_part1':'561',
  'sold_on_you_2_spanish':'1717',
  'operation_bailey_babies':'2261',
  'right_where_we_left_it':'6723',
  'heartbreaker_bay_sweet_little_lies':'6144',
  'work_with_me_ceo_cliffs':'892',
  'marked_german_human_translation':'3327',
  'deadly_angels_kiss_of_temptation':'6145',
  'lycans_queen_christmas':'6201',
  'trick':'6724',
  'danny_ep_1':'6316',
  'heartbreaker_bay_chasing_christmas_eve':'6133',
  'mary_mcnear_butternut_lake_the_night_before_christmas':'6140',
  'deadly_angels_kiss_of_pride':'6154',
  'safe_with_my_ceo':'6441',
  'reapers_claim_2_spanish':'1709',
  'savage_spanish_3':'1246',
  'deadly_angels_vampire_in_paradise':'6134',
  'a_twilight_texas_anthology_the_christmas_cookie_chronicles_grace':'6135',
  'a_twilight_texas_anthology_the_christmas_cookie_chronicles_christine':'6136',
  'a_bachelor_fireman_novella_one_fine_fireman':'6137',
  'deadly_angels_good_vampires_go_to_heaven':'6138',
  'a_breakwater_bay_novella_a_newport_christmas_wedding':'6148',
  'heartbreaker_bay_playing_for_keeps':'6149',
  'deadly_angels_even_vampires_get_the_blues':'6150',
  'the_arrangement_cliff_tests_37_38_39':'490',
  'heartbreaker_bay_one_snowy_night':'6153',
  'omegas_rebellion':'6726',
  'dark_ones':'451',
  'heartbreaker_bay_accidentally_on_purpose':'6155',
  'deadly_angels_kiss_of_surrender':'6156',
  'a_twilight_texas_anthology_the_christmas_cookie_collection':'6161',
  'my_rejected_king':'6199',
  'fighting_for_more':'6205',
  'timing_is_everything':'6224',
  'lycans_queen_10_20':'3873',
  'fire_seal':'5215',
  'a_deadly_angels_novella_christmas_in_transylvania':'6162',
  'author_rewrite_1_to_5':'5074',
  'love_thy_alpha_split_release':'7311',
  'gastown_girls_series':'6306',
  'o_guardiao_possessivo_season_32_msbr':'5157',
  'rip':'462',
  'forbidden':'6325',
  'wolfskin_series':'6397',
  'alphas_beta':'6479',
  'kenzo_trauma_kink':'6486',
  'the_demons_artifice_book_two':'5078',
  'broken_angels_mc_book_3_trigger':'6212',
  'the_awakening_series_split_release':'7312',
  'professor':'6330',
  'now_you_see_me_now_you_dont':'6331',
  'sealed_by_fate':'6332',
  'pregnant_and_rejected':'6336',
  'solid_stone_series':'6463',
  'snap_s5_c35_46':'4819',
  'the_wolf_wars_beyond_the_border_valentine':'6488',
  'decision_point':'6527',
  'ashwood_family_saga':'6856',
  'the_neglected_girlfriend':'6878',
  'the_professor':'6879',
  'billionaires_dirty_laundry':'6352',
  'soul_of_a_dragon':'6387',
  'the_millennium_wolves_2_ku':'6405',
  'raven_riders_ride_rough':'6560',
  'an_argeneau_novel_immortal_angel':'6561',
  'colt_book_4':'6569',
  'forty_eight_hours':'6572',
  'wilde_series':'6585',
  'a_den_of_gold_and_fangs':'6586',
  'rescuing_zoe_bonus_scene':'6587',
  'spice_and_thyme':'6614',
  'co_parenting':'6616',
  'unholy_bonds':'6623',
  'lost_princess_2_5_turkish':'4786',
  'secret_wolf':'7314',
  'plot_direction':'5173',
  'lycans_queen_sfx_test':'4774',
  'meeting_my_abductor_again_cliffs':'1490',
  'infinity_leading_cliffs':'1053',
  'alpha_kaden_german_2':'644',
  'dark_ones_saga':'458',
  'two_hearts_one_soul':'6408',
  'the_lycans_queen_valentine':'6494',
  'you_are_mine':'6625',
  'redemption':'6633',
  'demons_2':'7315',
  'pinky_swear':'370',
  'wolf_wars_3_de':'4747',
  'playboy_and_the_swat_princess':'6729',
  'prazer_a_negocios_2_msbr':'6321',
  'jenna_mcknight_love_in_the_fast_lane':'6553',
  'the_port_henry_trilogy_sighs_matter':'6562',
  'wynette_texas_the_great_escape':'6564',
  'krieg_der_wolfe_test_de':'4749',
  'my_sexy_stepbrother_spanish_3':'1037',
  'dizzy':'6415',
  'brotherhood_of_six':'6478',
  'julia_quinn_the_further_observations_of_lady_whistledown':'6548',
  'the_darling_detectives_trilogy_killer_charms':'6549',
  'independence_falls_wild_with_you':'6550',
  'raven_riders_ride_wild':'6552',
  'perfect_bastard_bonuses':'6620',
  'nas_sombras_msbr':'4831',
  'mw_rewrite_conservative_cliffs':'1048',
  'finding_your_fate_spanish_2':'1046',
  'perigo_a_espreita_season_2_msbr':'7317',
  'alpha_kaden_spanish_4':'1040',
  'wotw_my_mate_my_enemy':'6522',
  'julia_quinn_lady_whistledown_strikes_back':'6554',
  'independence_falls_full_exposure':'6555',
  'haunted_inkitt_copy_paste':'1591',
  'angels_mrs_miracle':'6557',
  'de_vincent_series_moonlight_sins':'6558',
  'greystone_ridge_pack':'6573',
  'rescuing_rosalie':'6592',
  'tragic_desires':'6602',
  'yes_mr_knight_2_2_msbr':'4838',
  'roxs_little_thief':'6613',
  'lupus_alba_book_two_claimed':'5081',
  'forbidden_touches_35_39':'4789',
  'alphas_prey_c14_18':'4751',
  'a_substituicao_2_msbr':'5295',
  'gideon_clarity_tension':'5337',
  'o_guardiao_possessivo_season_4_msbr':'5339',
  'here_last_hope_earlier_plot_engine':'4900',
  'raven_riders_ride_hard':'6547',
  'billionaires_and_bikers_sinful_rewards_6':'6551',
  'devil_s_rock_hell_breaks_loose':'6559',
  'billionaires_and_bikers_sinful_rewards_4':'6568',
  'to_indulge_a_king':'6627',
  'to_kiss_a_king_generic':'6628',
  'em_busca_da_felicidade_season_2msbr':'4780',
  'reynolds_family_romance':'6789',
  'bound_to_vasiliev':'6816',
  'renegade':'6730',
  'tutors':'6648',
  'heir_of_the_beast':'6678',
  'wild_fantasy':'6819',
  'destiny_makers':'6838',
  'de_caso_com_o_chefe_2_msbr':'4836',
  'champion_the_final_fight':'7316',
  'veils_mistresses':'6621',
  'roommate_fwb_business_among_friends':'6622',
  'author_si_test':'4960',
  'her_possessive_guard_quicker_tension':'4961',
  'mafias_ent':'4842',
  'i_can_see_you':'4093',
  'illicit_cravings':'7297',
  'criminals_series':'6632',
  'dance_with_the_devil':'7313',
  'his_golden_rose':'6644',
  'the_big_bad_wolf_book_two_ours_to_love':'4833',
  'cursed_mate':'6865',
  'owned_by_the_alphas':'6903',
  'irresistible_love':'6916',
  'o_guardiao_possessivo_season_5_msbr':'6016',
  'sharing_delilah_3':'6322',
  'her_beautiful_addiction_c47_58':'4862',
  'mafia_royals_romance_book_four':'4898',
  'solstice_howl':'746',
  'chasing_kiarra_book_two':'5083',
  'entwined_with_you':'6646',
  'a_second_chance':'6647',
  'kemora_archives':'6656',
  'palace_of_frost':'6685',
  'discovering_us_series':'6788',
  'blackmail':'6809',
  'alpha_king_hybrid_mate_no_prologue':'6323',
  'marrying_the_ceo_de':'4956',
  'take_the_risk_romance_cliff':'992',
  'three_the_perfect_number_1_10':'5091',
  'gripped':'117',
  'vampires_call':'7295',
  'crown_saga':'6324',
  'eternal_academy':'6917',
  'the_affair':'7240',
  'daddys_best_friend':'6875',
  'the_bastard':'6876',
  'the_fireman':'6877',
  'the_deerborn_series_book_4_deadline':'6327',
  'the_alphas_beta_mate_book_4_our_final':'6326',
  'community_vote':'7318',
  'montanta_trials_book_two':'4712',
  'something_in_the_way_book_two_beautiful':'5084',
  'impossible_olivia_book_2':'578',
  'best_of_both_worlds':'2072',
  'princes_stolen_love':'6837',
  'who_are_we_fooling':'6891',
  'storm':'2716',
  'pbs_american_portrait':'5550',
  'an_alphas_vixen':'1652',
  'sorry_cant_save_you':'4377',
  'alpha_kings_hybrid_mate_2_spanish':'1751',
  'ride_of_desire':'6900',
  'tlh_a_song_of_chaos':'6901',
  'in_the_shadow_2_de':'4962',
  'irresistible_invitations':'6896',
  'tafc_the_transaction':'6897',
  'tafc_corrupted_by_innocence':'6899',
  'first_casualty':'6905',
  'eyes_on_us':'93',
  'hated_by_my_mate_2_spanish':'1753',
  'wolf_wars_3_spanish':'1755',
  'alpha_kings_daughter_warrior_princess':'7319',
  'and_then_she_found_him':'1492',
  'author_more_workouts':'4995',
  'sleeping_with_a_ghost':'7234',
  'the_bosses_wife':'7237',
  'taken_finale':'7246',
  'hated_by_my_mate_finale':'7247',
  'tell_no_lies_c1_5':'4966',
  's_2_start_relaunch':'6209',
  'dering_siblings_author_test':'4984',
  'moonlight_author_test':'4986',
  'my_warrior_author_test':'4988',
  'their_little_human_author_test':'4998',
  'i_accidentally_stole_the_bad_boys_first_kiss':'6435',
  'slave_of_the_onyx_d_streamline_narrative':'6021',
  'author_addpov':'4985',
  'moonlight_author_test_cliffs':'4987',
  'pd_vance_theme_hookscliffs':'4969',
  'ravenous_author_test_concision':'4997',
  'rebel_souls_mc_book_2_invisible_flames':'6334',
  'blue_wolf_diana_author_missing_edit':'5522',
  'vegas_clandestina_4_spanish':'5094',
  'torian_5':'5027',
  'princess_book_two_sweets':'5030',
  'the_billionaire_beach_boy_split_release':'7323',
  'wicked_hot_medicine':'6734',
  'hated_by_my_mate_german_human_translation':'2838',
  'possesive_5_tr':'5435',
  'moon_river_emphasize_mating':'5439',
  'clausura_2_msbr':'4976',
  'moon_river_emphasize_mating_2':'5440',
  'alphas_second_chance_nymph_author_test':'5028',
  'touch_book_two_grip':'5031',
  'big_bad_wolf':'953',
  'prazer_a_negocios_3_msbr':'7324',
  'testing_chapter_numbers':'4994',
  'broken_queen_book_six':'5436',
  'primeira_chance_msbr':'5013',
  'author_faster_start':'5006',
  'author_more_romance':'4991',
  'bait_author_test':'5029',
  'wyndham_series':'6436',
  'ill_fight_for_you':'2353',
  'madie_baby':'2374',
  'love_online':'2213',
  'yes_mr_knight_faster_start':'6192',
  'emma_series_sexy_intro':'7325',
  'operation_bailey_wedding':'2262',
  'main_source':'2779',
  'mafia_royals_romance_book_one':'4886',
  'a_mafia_royals_romance_book_five':'4899',
  'imprevisivel_season_2_msbr':'6022',
  'a_maldicao_msbr':'5438',
  'blue_wolf_diana':'2192',
  'marcello_o_mafioso_season_2_msbr':'6226',
  'welcome_to_nashville':'6113',
  'mason_suspense_rework_3':'7326',
  'aya_sherif_hookscliffs_horror':'4978',
  'meeting_my_abductor_agaian_s3_c1_9':'5002',
  'buying_the_virgin':'6733',
  'meeting_my_abductor_again_spanish_2':'986',
  'quimica_2_msbr':'5514',
  'fada_madrinha_ltda_season_6_msbr':'5385',
  'author_foreshadowing_romance':'5023',
  'streamlined_pilot':'6345',
  'rvd_ch_4_6':'7328',
  'tales_of_the_others':'6338',
  'bossman_test_cliffhangers':'2356',
  'entre_dois_alfas_2_msbr':'5199',
  'above_the_clouds_tony_si_test':'5211',
  'aether':'1004',
  'moontochter_2':'5223',
  'when_tables_turn_less_abuse':'5387',
  'author_intrigue':'5036',
  'lust_or_love':'1283',
  'gabriel_allon_the_cellist':'5829',
  'lost_princess_intensify_narrative':'6347',
  'the_kings_gift_book_2':'6239',
  'in_it_to_win_it':'4097',
  'final_diary':'1543',
  'mark_of_mars':'1662',
  'star_found':'6736',
  'twisted_minds_human_translation_german':'3501',
  'lost_princess_ch_18_20':'659',
  'their_thief':'909',
  'jessica_blackwood_name_of_the_devil':'5799',
  'matthew_caldwell_the_untouchable':'6225',
  'os_guerreiros_de_torian_season_5_msbr':'5163',
  'dev_the_millennium_wolves_s2_opening_b_continuation':'197',
  'jack_in_the_box_inkitt_copypaste':'1600',
  'sede_de_sangue_season_2_msbr':'7331',
  'os_lobos_do_oeste_a_cacada_msbr':'4909',
  'mmaa_boost_beginning':'6342',
  'coast_to_coast':'1526',
  'luck_of_the_draw':'6739',
  'price_possession':'122',
  'marrying_the_ceo_unedited':'371',
  'flames_that_bind_us_german_staffel_2':'879',
  'reapers_begehren_2':'990',
  'her_last_hope_2_spanish':'1720',
  'alpha':'1031',
  'first_chance_4_de':'6348',
  'wolf_romance':'6541',
  'bad_boy_alphas_german_s_5_ms':'5298',
  'the_unchosen_path_book_3':'6544',
  'her_possessive_guard_3_de':'5164',
  'arrangement_1_turkish_extensive_edit':'5519',
  'charlotte_and_the_seven_frat_brothers':'1596',
  'a_rainha_dos_licantropos_season_3_ms':'4317',
  'broken_angels_mc_book_two_reaper':'5373',
  'first_chance_2_tr':'5452',
  'the_unchosen_path_book_4':'6545',
  'hael_meeting':'6229',
  'alphas_pet_conflicted_alpha':'6241',
  'in_the_shadows_2_tr':'5391',
  'jogo_de_seducao_3_msbr':'5395',
  'no_split_2':'5453',
  'her_book_two_us':'5301',
  'colt_german_3':'834',
  'bridgerton_test':'5397',
  'different_nosplit':'5454',
  'haunted_2_german':'2620',
  'fgi_magic_trial':'6216',
  'casando_com_o_ceo_season_2_part_2_msbr':'5947',
  'meeting_my_abductor_first_chapter_test':'5948',
  's_2_start':'5446',
  'nas_sombras_2_msbr':'5467',
  'colt_1_3':'6349',
  'tempted_by_my_boss':'4111',
  'the_half_blood_music_only':'4512',
  'sold_on_you_s2_reformat':'1621',
  'the_half_blood_sfx_only':'4513',
  'wolf_foreshadowing':'6359',
  'rebel_souls_mc_book_4':'6369',
  'tension_aa_ch_1_3':'6363',
  'savage_german_2':'963',
  'her_fate':'1270',
  'the_blue_wolf_diana_author_test':'5475',
  'in_the_shadow_4_de':'6360',
  'dark_heroine_series_autumn_rose':'5932',
  'alpha_and_aurora_spanish_3':'1633',
  'author_trauma':'5471',
  'mw_tony_reboot':'6354',
  'the_alphas_prey_author_test':'5476',
  'author_hinting_sex':'5472',
  'the_blue_wolf_diana_author_si_test':'5477',
  'a_plea_for_help_book_two_a_cry_for_help':'5400',
  'infinity_haunted_by_voice':'6361',
  'the_lost_princess_spanish_2':'1248',
  'alphas_maid_alpha_cliff':'893',
  'faster_start':'5950',
  'evie_darling':'2440',
  'requiem_city_extensive_editing':'5401',
  'madhouse_c43_52_final':'5951',
  'em_busca_da_felicidade_season_4msbr':'5473',
  'curvy_girls_club':'6373',
  'real_evie_chase':'154',
  'the_bad_boy_wants_me':'663',
  'the_invitation_vi_keeland':'1332',
  'meet_me_in_the_woods':'3776',
  'hidden_legacy_diamond_fire':'5945',
  'rebel_souls_mc_book_3':'6368',
  'in_need_of_rescue':'1374',
  'luna_with_green_eyes':'1104',
  'the_crystal_commander':'6682',
  'darker_side_of_sex':'2788',
  'stepford_vampires':'4645',
  'i_can_read_level_2_albert_einstein_a_curious_mind':'5529',
  'bernard_most_abc_t_rex':'5533',
  'rejected_warrior_author_test':'5478',
  'joyce_maynard_the_good_daughters':'5744',
  'a_filha_do_alfa_rei_season_2_msbr':'6584',
  'cured':'2656',
  'diferente_2_msbr':'5479',
  'reapers_claim_3_spanish':'1829',
  'lycans_queen_s2_full_sound':'6232',
  'reluctantly_yours':'2401',
  'the_replacement_second_mother':'6581',
  'her_possessive_guard_4_de':'5954',
  'dont_close_your_eyes':'6746',
  'alpha_loren_book_3':'6649',
  'rebel_souls_mc':'6024',
  'the_wolfskin_series_book_3_wolvenskin':'6575',
  'my_valerie':'757',
  'meet_dimitri_earlier':'6582',
  'dev_bright_star_b_start':'183',
  'the_hybrid_series_book_2_mine':'6650',
  'millennium_wolves_toms_version':'558',
  'my_warrior_inside_the_ring':'6634',
  'primeira_chance_season_5msbr':'6576',
  'the_secret_angel_book_2':'6640',
  'the_arrangement_chat_start':'187',
  'dev_savage_b':'153',
  'gideon_german_1_3_translated_si_win':'6577',
  'msmstry':'6635',
  'mason_2':'2410',
  'capture_me_2_tr':'6578',
  'mason_10ch_test_music':'4179',
  'first_chance_5_tr':'6642',
  'faster_romance':'6653',
  'mistake':'3573',
  'witch_hunter':'6747',
  'the_millennium_wolves_2_spanish_human':'2906',
  'the_invitation_restructure_cliffs':'6637',
  'darkest_sinner':'455',
  'i_dont_belong_here':'3574',
  'dev_the_millennium_wolves_7':'186',
  'donor_to_a_vamipre_book_2':'577',
  'beneath_the_scars_book_2':'6624',
  'abby_pov':'6379',
  'dev_the_real_evie_chase':'130',
  'hellion_mc_book_3_conquering_bonds':'6388',
  'increase_mystery':'6595',
  'requiem_city_german_3':'784',
  'i_with_vixens_human_chapter':'6382',
  'shaing_delilah_4_de':'6386',
  'the_immortal_series_book_2':'6419',
  'faster_start_remove':'6389',
  'family_matters':'1176',
  'workmates':'2158',
  'whats_done_in_the_dark_book_2':'6390',
  'primeira_chance_season_3msbr':'6374',
  'kiss_of_darkness':'6750',
  'werewolf_university_book_2':'6418',
  'dering_siblings':'1049',
  'desperation':'3781',
  'a_substituicao_3_msbr':'6400',
  'artemis_gift_book_3_the_path_of_war':'6420',
  'alpha_kaden_german_human_translation':'2822',
  'top_shelf':'1417',
  'broken_angels_mc_book_4':'6392',
  'beauty_and_the_lumberjacks':'6751',
  'requiem_city_build_suspense':'6384',
  'stray_puppy_book_2_stray_master':'6401',
  'primeira_chance_season_4msbr':'6421',
  'sharing_delilah_2_de':'5955',
  'body_37':'3577',
  'bbandag_si_5_7cliffs':'421',
  'mason_portuguese_season_3':'6630',
  'seal_team_one_book_1_colette':'523',
  'cheeky_royal':'6752',
  'undressed_by_the_king_spanish_2':'1142',
  'black_heart_book_3':'6410',
  'a_procura_do_omega_season_3_msbr':'4720',
  'seal_team_one_book_2_possessing_molly':'524',
  'cupidity_book_2_personal_foul':'6411',
  'hated_by_my_mate_full_sound':'5065',
  'alpha_kings_daughter_book_3_reign':'6412',
  'fgi_fantasy_reality_tv':'6033',
  'em_busca_da_felicidade_season_5msbr':'6032',
  'the_cupid_mixup':'6755',
  'fairy_godmother_inc_book_7_c11_20':'6034',
  'nas_sombras_3_msbr':'6043',
  'her_bodyguard':'6762',
  'alpha_second_chance_nymph_s3_c1_10':'6044',
  'entre_dois_alfas_3_msbr':'6047',
  'the_alphas_beta_mate_book_three':'6035',
  'the_deal_elle_kennedy':'1368',
  'princess_book_3_cinderella':'6061',
  'bantering_with_murder_book_5':'6037',
  'alpha_loren_book_two':'6038',
  'secrets_of_sin_brothers_bestfriend':'6066',
  'replacement_3_turkish':'6060',
  'bait_book_three':'6051',
  'cross_to_bear_2_tr':'6069',
  'mafia_puppet':'4160',
  'primalthorn':'6610',
  'angelborn':'6756',
  'the_mistake':'1975',
  'tension_erotic':'6068',
  'a_maldicao_season_2_msbr':'6079',
  'half_blood_3_tr':'6054',
  'meet_darius_earlier':'6041',
  'kenzo_2_tr':'6080',
  'lupus_alba_book_4_turned':'6100',
  'paranoid':'2069',
  'killer_kiss':'6757',
  'uncontrollable_heat_last_chapters_german':'6120',
  'guardians_mark':'2430',
  'love_at_stake_crouching_tiger_forbidden_vampire':'5639',
  'the_asylum_tales_dead_man_s_deal':'5651',
  'third_a_kiss':'687',
  'match_made':'1126',
  'alpha_and_aurora_immediate_action':'977',
  'findingyourfate_german_human_translation':'2836',
  'her_protective_deputy':'857',
  'melting_ice':'1241',
  'katie_a_love_story':'2348',
  'o_lobo_mau_season_2_msbr':'6088',
  'one_she_always_wanted':'956',
  'the_ties_that_bind':'500',
  'muses_touch':'948',
  'o_segredo_da_montanha_3_msbr':'6127',
  'big_bad_wolf_2_de':'6083',
  'bossman_final':'617',
  'morally_grey':'6074',
  'baby_girl':'1310',
  'j_a_jance_sins_of_the_fathers':'5708',
  'jodi_picoult_the_pact':'5715',
  'beautiful_mistake_julian_rewrite_test':'293',
  'the_alphas_call_love_triangle':'6085',
  'alpha_and_aurora_increased_romance':'978',
  'the_alphas_call_spanish_4':'1124',
  'you_make_me_happy':'867',
  'when_ash_falls':'450',
  'ever_series_valentine':'6763',
  'os_lobos_do_oeste_a_cacada_2_msbr':'6077',
  'finding_your_fate_spanish_3':'1047',
  'at_the_end_of_the_world':'560',
  'spring_seduction_wedding_night_hook':'991',
  'broken_queen_s5_sound':'579',
  'just_a_prince':'913',
  'infinity_2_part_2_de':'6078',
  'the_wold_wars_3_german':'2041',
  'journeys_end':'1289',
  'never_fall_for_the_bad_boy':'858',
  'jax_cliff':'6090',
  'gideon_cliff':'6091',
  'alphas_bargain':'3104',
  'beg_for_mercy':'6249',
  'an_american_faerie_tale_the_returned':'5601',
  'heartbreaker_bay_wrapped_up_in_you':'6151',
  'first_chance':'1352',
  'alpha_aurora_revenge_plot':'6093',
  'mated_to_the_werewolf_king_german':'1906',
  'adam_silvera_they_both_die_at_the_end':'5891',
  'the_offspring_burning_darkness':'5613',
  'a_pirates_oath':'5378',
  'judi_mccoy_one_night_with_a_goddess':'5621',
  'judi_mccoy_wanted_one_special_kiss':'5625',
  'robert_lacey_the_queen':'5996',
  'escaping_fate':'6008',
  'gateway':'6713',
  'just_until_tomorrow':'1003',
  'the_poppy_war_the_dragon_republic':'5820',
  'beck_and_call':'6725',
  'new_lys2_sound_dev_fix':'6450',
  'kim_harrison_sudden_backtrack':'5634',
  'lord_of_fire':'1287',
  'operation_bailey_birthday':'2293',
  'chicago_bratva_italian_ms_s_5':'6098',
  'fit_for_fire_s2_c26_38':'6099',
  'george_orwell_1984':'5524',
  'lessons_from_a_one_night_stand':'2008',
  'slave_of_the_onyx_dragon':'2595',
  'a_twilight_texas_anthology_the_christmas_cookie_chronicles_carrie':'6163',
  'savage_spanish_2':'1244',
  'the_arrangement_chat_start_manual':'188',
  'the_arrangement_sex_start':'189',
  'i_shouldnt_fall_for_you':'1616',
  'e_2_e_test_story':'6661',
  'riders_of_tyr_copy_paste_og':'1007',
  'mw_rewrite_s2_fix':'970',
  'the_rivals':'610',
  'out_of_reach':'907',
  'peter_swanson_eight_perfect_murders':'5687',
  'gordon_korman_the_unteachables':'5723',
  'wayside_school_sideways_stories_from_wayside_school':'5729',
  'the_brown_sisters_get_a_life_chloe_brown':'5730',
  'rosaline':'3956',
  'betty_smith_a_tree_grows_in_brooklyn':'5560',
  'shel_silverstein_where_the_sidewalk_ends':'5733',
  'daniel_keyes_flowers_for_algernon':'5745',
  'seeing_scarlett':'131',
  'born_regal':'1315',
  'the_arrangement_finale':'3878',
  'linwood_barclay_find_you_first':'5688',
  'an_fbi_thriller_vortex':'5732',
  'gabriel_allon_house_of_spies':'5735',
  'a_plea_for_help':'2459',
  'purgatory':'4110',
  'wait_for_you_be_with_me':'5043',
  'a_weekend_or_two':'1153',
  'sinful_tempatation':'2754',
  'love_at_stake_be_still_my_vampire_heart':'5619',
  'tequila_rose':'6717',
  'ann_patchett_commonwealth':'5911',
  'sex_not_love':'618',
  'cut_to_the_core':'1023',
  'wolf_ranch_book_4':'1408',
  'finding_your_fate_3':'573',
  'alpha_kaden_new_s4':'413',
  'fit_for_fire_romance_cliffs':'1398',
  'tease_me_once':'6720',
  'linda_howard_after_sundown':'5912',
  'dark_series_dark_gold':'5930',
  'lost_princess_series_the_barefoot_princess':'5989',
  'sum_of_us':'840',
  'colleen_mccullough_the_thorn_birds':'5561',
  'secrets_of_sin_cliff_extended':'1355',
  'bought_by_the_billionaire':'6748',
  'millenniumwolves_s2_nocheatingtest':'481',
  'sold_on_you_cliff_restructure':'1448',
  'the_selection_the_elite':'5811',
  'un_maravilloso_error_2':'868',
  'birth_of_a_baby_daddy':'2247',
  'the_selection_the_one':'5822',
  'lobos_milenarios_3':'870',
  'discworld_the_color_of_magic':'5843',
  'wiedersehen_mit_meinem_entfuhrer_2':'883',
  'devstory_bq_3_ep_denise_mallon':'329',
  'neil_gaiman_good_omens':'5879',
  'shatter_me_shatter_me':'5899',
  'lupus_paulo_spanish_3':'982',
  'madeline_miller_the_song_of_achilles':'5565',
  'pull':'469',
  'shatter':'470',
  'javiera_test_galatea_unlimited_flag':'866',
  'mateo_santiago_deutsch_3':'872',
  'lobos_milenarios_2':'861',
  'forbidden_series':'6335',
  'forever':'473',
  'fall':'474',
  'ruin_series':'477',
  'love_at_stake_vampire_mine':'5672',
  'bait':'4037',
  'dirk_steele_series_soul_song':'5666',
  'dark_series_dark_prince':'5931',
  'dirk_steele_series_within_the_flames':'5624',
  'julia_quinn_brighter_than_the_sun':'5900',
  'fearless':'475',
  'drawn_together':'2155',
  'beautiful_mistake_final':'621',
  'damons_soulmate':'1333',
  'love_at_stake_wanted_undead_or_alive':'5640',
  'his_true_mate':'553',
  'her_royal_master_a_bad_boy_billionaire':'6121',
  'forever_doms_of_club_eden':'6727',
  'marian_keyes_watermelon':'5562',
  'journey_to_wudang_trilogy_heaven_to_wudang':'5685',
  'prince_of_the_deep':'6683',
  'shadow_pact':'358',
  'my_master_ep_1_3_less_rape_cliffs':'842',
  'les_loups_du_millenaire':'899',
  'beautiful_mistake_test_no_baby':'285',
  'love_happens':'559',
  'kenzo_season_2_msbr':'6693',
  'rose_book_1_the_midnight_rose':'564',
  'mending_a_shattered_heart':'2659',
  'montana_trials':'4694',
  'sophia':'4859',
  'the_matchmakers_mistake':'6744',
  'kerrelyn_sparks_the_forbidden_lady':'5592',
  'marian_keyes_angels':'5675',
  'lindsey_kelk_i_heart_new_york':'5678',
  'italian_heart':'491',
  'una_propuesta_inmoral_season_3':'856',
  'marked_polish_cs':'3157',
  'julia_quinn_everything_and_the_moon':'5866',
  'gideon_staffel_3':'873',
  'devil_vs_alpha_2_german':'3034',
  'mein_stiefbruder_ist_ein_werbar_3':'865',
  'broken_measures':'570',
  '500_days':'742',
  'my_sexy_stepbrother_is_a_werebear_spanis':'794',
  'the_arrangement_spanish_4':'983',
  'princess':'1407',
  'journey_to_wudang_trilogy_earth_to_hell':'5683',
  'mein_heisser_stiefbruder_3':'885',
  'aullido_alfa_2':'845',
  'una_propuesta_inmoral_season_2':'853',
  'julia_quinn_dancing_at_midnight':'5555',
  'hunted_at_whiteford_farm':'6745',
  'falling_for_the_mafia_brothers_2_de':'2930',
  'new_lycans_queen_music_only_test':'4685',
  'mw_rewrite_s2':'968',
  'mason_ep_1_3_agency_cliff':'958',
  'bending_the_rules_ep_1_3_hook':'957',
  'the_arrangement_wish_upon_a_star':'6701',
  'lostprincess_book2_for_review_50_89':'959',
  'mateo_santiago_spanish_2':'1125',
  'behind_the_masque_book_2_beyond_the_mas':'547',
  'just_a_few_steps_away':'855',
  'missing_out_on_life':'6708',
  'der_schonste_fehler_2':'654',
  'reapers_begehren_3':'1016',
  'dark_ones_new_chapter_breaks':'498',
  'immortal_ascension_og_edited':'728',
  'once_in_a_lifetime':'1026',
  'dark_place_in_the_back_of_your_mind':'2169',
  'savage_german_3':'964',
  'in_the_pines':'6702',
  'second_chance_nymph_restructure_cliffs':'1164',
  'hated_by_my_mate_alpha_sympathy':'1022',
  'belladonna_a_virtue_and_vice_prequel':'6741',
  'dark_days_series_pray_for_dawn':'5584',
  'dirk_steele_series_the_wild_road':'5662',
  'julia_quinn_the_secret_diaries_of_miss_miranda_cheever':'5754',
  'my_sexy_stepbrother_is_a_werebear_btest':'178',
  'the_millennium_wolves_2_no_cheating':'672',
  'dev_hpg_si_sexy_taps_beginning':'222',
  'dev_test_google_docs_upload':'220',
  'the_arrangement_empower_angela':'238',
  'siren_lane':'904',
  'hollows_the_good_the_bad_and_the_undead':'5661',
  'mssiawb_s01_tdst':'233',
  'dev_ride_c':'221',
  'mw_character_conflicts':'1056',
  'need':'86',
  'julia_quinn_what_happens_in_london':'5763',
  'dev_ubtk_k_e1_7_cp':'229',
  'dev_rot_3_ep_b_test':'224',
  'dev_german_translation_test':'225',
  'mssiawb_s01_e25_26b':'232',
  'mssiawb_s01_e19b':'231',
  'hated_by_my_mate_s1_cliffs':'1038',
  'debase':'463',
  'my_sexy_stepbrother_is_a_werebear_spanish_2':'1036',
  'beautiful_mistake_ch8and9_cliff_test':'278',
  'the_arrangement_s6':'243',
  'gideon_sexier_derek':'411',
  'liane_moriarty_the_last_anniversary':'5564',
  'mw_parents_foreshadow':'1057',
  'her_last_hope_foreshadow_cliffs':'1192',
  'agatha_christie_they_came_to_baghdad':'5724',
  'bossman':'614',
  'durch_flammen_vereint_3':'998',
  'betting_on_the_house':'6742',
  'spirits':'96',
  'dirty_witches':'99',
  'a_beast_within':'102',
  'my_cowboy_s1_cp':'236',
  'hercule_poirot_mysteries_the_mystery_of_the_blue_train':'5807',
  'training_test':'326',
  'caged':'2576',
  'julia_quinn_a_night_like_this':'5803',
  'julia_quinn_the_secrets_of_sir_richard_kenworthy':'5918',
  'salems_cipher':'3806',
  'chronicles_of_narnia_the_chronicles_of_narnia_complete_7_book_collection':'5568',
  'the_millennium_wolves':'119',
  'dev_ac_b1_k11_20':'230',
  'tranquility_s1_rewrite':'242',
  'hpg_max_test':'237',
  'test_this_de_stuff':'235',
  'broken_queen_s4':'257',
  'reapers_claim_s1_new':'316',
  'lycans_queen_24_29_cliff_test':'419',
  'dev_denisnd_devstory':'148',
  'test':'607',
  'dark_ones_series_sex_lies_and_vampires':'5649',
  'dev_german_test_2':'227',
  'the_immortal_zenith_og_inkitt_draft':'632',
  'dev_cradle_snatcher_c_test':'133',
  'finding_your_fate_marking_test':'603',
  'the_immortal_ascension_og_inkitt_draft':'633',
  'bending_the_rules_old':'95',
  'desperate_edited':'1371',
  'charlie_mackesy_the_boy_the_mole_the_fox_and_the_horse':'6114',
  'wolfguise':'766',
  'secrets':'1541',
  'hollows_dead_witch_walking':'5582',
  'great_escape':'366',
  'lupus_paulo_2':'652',
  'requiem_city_3_spanish_final':'1066',
  'resisting_you':'608',
  'konigskriegerin_5':'759',
  'wudang_earth_to_hell':'5026',
  'i_will_always_love_you':'502',
  '12':'1585',
  'gettin_lucky':'6737',
  'aisling_triplets_for_her_eyes_only':'5646',
  'hercule_poirot_mysteries_five_little_pigs':'5875',
  'at_the_end_of_the_world_ep_16_17':'1225',
  'dev_the_millennium_wolves_2_resound':'149',
  'mateo_santiago_spanish_3':'1131',
  'vom_alpha_gestohlen_3':'761',
  'alpha_kaden_2_salvage_grayson_lives':'667',
  'the_alphas_call':'72',
  'mein_heisser_stiefbruder_ist_ein_werbar_2':'648',
  'vom_alpha_gestohlen_4':'762',
  'biss_des_alphas_3':'763',
  'vom_alpha_entfuhrt_2':'1108',
  'chasing_stacy':'6749',
  'the_dream_guardians_series_heat_of_the_night':'5644',
  'miss_marple_mysteries_the_mirror_crack_d_from_side_to_side':'5787',
  'drake':'478',
  'secrets_of_sin':'910',
  'caught_between_two_billionaires':'1323',
  'until_my_last_breath':'745',
  'miss_marple_mysteries_they_do_it_with_mirrors':'5700',
  'plea_for_help_10_ep_sound_test':'3201',
  'das_unmoralische_angebot_6':'760',
  'lilys_choice':'1226',
  'der_schonste_fehler_3':'769',
  'love_at_first_stoplight':'1418',
  'dragons_princess_spanish_2':'1132',
  'alpha_and_aurora_book_2':'1135',
  'forbidden_territory':'512',
  'liliths_revenge':'591',
  'wild_claim_original':'328',
  'werewolf_chronicles_spanish_2':'1136',
  'the_alphas_call_spanish_3':'1119',
  'der_ruf_des_alphas_8_episodes':'691',
  'broken_queen_10_13':'273',
  'infinity_split_test':'1120',
  'sample_book':'1113',
  'beautiful_belle_dana_full_edit':'391',
  'werewolf_chronicles_spanish_3':'1137',
  'die_millennium_wolfe_7':'752',
  'hercule_poirot_mysteries_death_on_the_nile':'5856',
  'ever_series_love':'6768',
  'man_of_the_match_edited':'392',
  'moonshot':'722',
  'wilde_ones':'771',
  'o_segredo_do_meu_meio_irmamy_sexy_stepbrother_is_a_werebear_ptbro':'1138',
  'requiem_city_4':'792',
  'die_millennium_wolfe_6':'749',
  'die_strafe_des_alphas_3':'799',
  'the_selection_the_selection':'5716',
  'jessica_blackwood_black_fall':'5731',
  'deliverance_company_someday_soon':'5816',
  'ever_series_truly':'6769',
  'a_dangerous_union':'6770',
  'barnaby_hooker_series_metro_girl':'5913',
  'her_howntown_prince_unedited':'369',
  'behind_the_masque_book_iii':'548',
  'the_seen_trilogy_he_claims_me':'5933',
  'fourth_a_lie':'694',
  'wolf':'810',
  'twelve_years':'1291',
  'ihr_leidenschaftlicher_beschutzer_2':'656',
  'die_strafe_des_alphas_2':'798',
  'gideon_staffel_2':'802',
  'work_with_me_book_2_chapter_12':'343',
  'ezekiels_luna_chapter_13':'363',
  'aisling_triplets_at_the_edge':'5615',
  'dark_surrender':'453',
  'wolfs_contract':'5111',
  'dark_days_series_dawnbreaker':'5571',
  'love_at_stake_secret_life_of_a_vampire':'5647',
  'hollows_white_witch_black_curse':'5668',
  'dark_days_series_dayhunter':'5664',
  'friendship_test_full_season':'428',
  'loving_mr_steel':'372',
  'alphas_testermd':'388',
  'beautiful_belle_and_grayson_c44_48':'415',
  'point_of_no_return':'286',
  'her_hometown_prince':'357',
  'behing_the_masque_book_1':'546',
  'her_possessive_guard_spanish_2':'1116',
  'c_s_lewis_the_great_divorce':'5846',
  'everett_test_1_19':'426',
  'ac_first_8':'400',
  'beautiful_mistake_s3rw':'382',
  'lupus_paulo_enhanced_copy_paste':'418',
  'when_a_moth_loved_a_bee':'6429',
  'broken_queen_german_3':'645',
  'chronicles_of_narnia_the_lion_the_witch_and_the_wardrobe':'5896',
  'when_a_moth_loved_a_bee_2':'6458',
  'untouchable_darkness':'452',
  'whispered_music':'448',
  'the_living_canvas':'706',
  'twisted_perfection':'486',
  'steal':'459',
  'aks3_final':'351',
  'gideon_6_to_10':'410',
  'work_with_me_unedited':'360',
  'dev_detox_story':'64',
  'seal_team_one_book_3_worshipping_amara':'525',
  'dev_ftbu_btest':'127',
  'hated_by_my_mate_2_hating_my_mate':'536',
  'once_a_myth_final':'689',
  'dev_beastly_lights':'140',
  'dev_mttwk_ctest':'136',
  'alpha_kaden_salvage_project':'707',
  'mw_branch_o_season_1':'321',
  'wolf_wars_s3_e12to16':'1231',
  'lost_princess_delayed_sex_test':'708',
  'alpha_kane':'313',
  'trapping_quincy_light_sound':'312',
  'mw_ger_s1_ep1_10_marlena':'315',
  'bq1_si_test_romance':'304',
  'dev_savage_s3':'139',
  'dev_beautiful_mistake':'132',
  'upon_a_midnight_dream':'447',
  'gideon_s1':'245',
  'dev_reapers_claim_more_taps_b':'161',
  'dev_dragons_princess_msa':'150',
  'dev_story_test':'126',
  'dev_the_alphas_call_9':'142',
  'alpha_kaden_season_cliff_test':'296',
  'keep':'457',
  'ruin':'466',
  'dev_broken_queen_31_33':'182',
  'fighting_fate_chapters_25_26':'342',
  'given_time_c51_epi':'407',
  'dev_the_millennium_wolves_s3b':'144',
  'dev_requiem_city_s4':'145',
  'dev_ride_s1':'156',
  'lost_princess_heightened_romance_test':'709',
  'gideon_2or4':'341',
  'bride_trilogy_the_bride_stripped_bare':'5041',
  'marian_keyes_rachel_s_holiday':'5677',
  'violets_octagon':'255',
  'alphas_second_chance_nymph':'581',
  'no_other_love':'1271',
  'millennium_wolves_ep_recaps':'1204',
  'fighting_fate_chapters_22_24':'336',
  'dark_days_series_burn_the_night':'5585',
  'the_ether_chronicles_nights_of_steel':'5593',
  'the_arrangement_s1_intro_reboot':'575',
  'undresed_by_the_king_edited_final':'727',
  'the_replacement_season_2':'586',
  'broken_queen_si_22_30':'180',
  'dev_the_alphas_call_e':'141',
  'dp_so_first':'6462',
  'best_and_honor_2':'279',
  'dev_survival_of_the_rose_alternative_pov':'158',
  'dev_reapers_claim_h':'219',
  'dev_haunted_2':'164',
  'dev_silent_embrace_accelerated_c':'162',
  'dev_req_city_acc_b':'160',
  'the_lycans_queen_german_with_sound':'1085',
  'the_mafia_saved_me':'78',
  'dev_millenniumwolves_s2_siennatest_final':'435',
  'dev_lupus_paulo_g':'203',
  'tranquility_s1_dream_test':'192',
  'dev_her_possessive_guard_g':'193',
  'the_arrangement_hook_test':'201',
  'dev_saving_maximus_new_ending':'171',
  'saving_maximus_btest':'173',
  'bbandag_si_4_7cliffs':'422',
  'dev_nico_test_2':'168',
  'dev_requiem_city_h_test':'218',
  'broken_queen_si':'174',
  'dev_test_cover_123123':'205',
  'dev_saving_maximus_revert_1_ep':'172',
  'dev_seeing_scarlett_b_test':'170',
  'test_devstory':'4584',
  'dev_colt_b_test':'128',
  'alphas_call_ep_1_3_cliffs':'1147',
  'burgundy_paint_chronicles':'1025',
  'melodys_song':'1068',
  'kings_mate':'1143',
  'tucker_series':'844',
  'caught_up_in_between':'1178',
  'kidnapped_by_the_agent':'85',
  'chasing_kiarra_sfx':'4494',
  'dev_my_sexy_stepbrother_is_a_werebear':'163',
  'torian_warriors_2_de':'2935',
  'tornare_a_casa_in_un_posto_nuovo':'3026',
  'my_wifes_desires':'4243',
  'walter_dean_myers_scorpions':'6465',
  'the_oregon_trail_the_oregon_trail_trailblazer_4_book_collection':'6466',
  'the_oregon_trail_the_oregon_trail_4_book_digital_collection':'6467',
  'endgame_season_2':'1319',
  'endgame_season_4':'1321',
  'lovely_liaison_spanish_launch':'3125',
  'bq_s1_ep11_20':'281',
  'secrets_of_sin_less_extortion':'1342',
  'beautiful_mistake_test_ep15':'284',
  'endgame_season_5':'1322',
  'work_with_me_book_two':'334',
  'reapers_claim_copypaste':'287',
  'lp_ch_1_and_2_test':'320',
  'my_cowboy_spanish_2':'1312',
  'caught_between_billionaires_season_3':'1325',
  'ronald_l_smith_gloom_town':'6468',
  'love_of_my_life':'2187',
  'a_dish_best_served_cold':'1534',
  'beautiful_belle_and_alpha_grayson_dana':'386',
  'mine_to_claim_enhanced_cp':'437',
  'bqs1_ch27_30_test':'399',
  'lycans_queen_6_to_8':'403',
  'new_mine_to_claim_enhanced_cp':'438',
  'underground_kings_season_2':'1327',
  'letters_to_inmate_29901':'1338',
  'the_wolf_wars_beyond_the_border_ptbr_2':'1353',
  'wolf_ranch_book_2':'1396',
  'wolf_ranch_book_3':'1406',
  'wolf_ranch_book_5':'1409',
  'wolf_ranch_book_6':'1410',
  'broken_doll':'592',
  'millenniumwolves_s2_scandaltest':'482',
  'dev_the_arrangement_s1_ep_12_18_cliffs_test':'443',
  'the_arrangement_episode_29_30_test':'488',
  'the_arrangement_episode_31_32':'489',
  'dark_ones_new_chapter_2':'582',
  'the_new_girl':'414',
  'strung':'467',
  'tear':'468',
  'seaside_series':'472',
  'the_ceos_love':'507',
  'mr_charlton_book_2_mrs_charlton':'533',
  'laudition':'556',
  'great_wide_somewhere':'580',
  'conflict_of_interest_book_1':'589',
  'daddys_here':'596',
  'not_the_only_one':'1313',
  'saving_kinsley':'1419',
  'more_than_just_friends':'2206',
  'falling_for_the_mafia_brothers_2_german':'3006',
  'the_wolf_wars_beyond_the_border':'510',
  'a_chance_to_have_you':'1380',
  'welcome_to_heartbreak':'542',
  'impossible_olivia':'568',
  'papas_prey':'590',
  'every_cloud':'1331',
  'tied_to_the_moon':'1358',
  'crimson_seduction':'595',
  'mafiawere_brothers':'1362',
  'champagne_dreams':'597',
  'not_all_about_you':'1070',
  'gentlemens_club_series':'1354',
  'judge_jury_and_executioner':'1535',
  'black_heart_series':'5502',
  'e_2_e_paywall_test_story':'6669',
  'humanborn':'381',
  'neheros_song':'1404',
  'seaside_pictures_series':'461',
  'tricks_of_passion':'693',
  'for_you':'860',
  '13_angels':'1533',
  'fate_of_the_fathers':'3957',
  'chamberlain_files_10_ep_sound_test':'3354',
  'bellas_beginning':'750',
  'unclassic_hero':'880',
  'beguiling_bachelors':'897',
  'seventeen_and_pregnant':'1728',
  'nerds_secret':'1784',
  'kings_series':'1349',
  'her_cold_heart':'1811',
  'my_beloved_billionaire':'1527',
  'his_nerd_sfx_only':'4250',
  'alpha_kaden_si_resound':'6473',
  'mentor':'1530',
  'given_time':'368',
  'rock_my_world':'1240',
  'capricorn_house':'1547',
  'whenever_you_want':'1568',
  'nephilim':'479',
  'mason_10ch_sfx_test':'4260',
  'hated_by_my_mate_full_sound_backup':'5249',
  'the_spark':'1365',
  'donor_to_a_vampire':'567',
  'roaming_alpha':'1519',
  'frankenstein':'1671',
  'broken_angels_mc':'1696',
  'the_fae_wolf':'1705',
  'talking_bodies':'1727',
  'how_to_survive_the_alien_invasion':'5448',
  'emilys_list':'1545',
  'punished_by_the_alpha_italian_ms':'5414',
  'mine':'124',
  'deep_into_the_woods':'5501',
  'a_n_wilson_prince_albert':'5987',
  'when_night_comes':'1708',
  'chance_of_fate':'1538',
  'fang':'5377',
  'a_crime_of_passion':'3937',
  'hated_by_my_mate_sfx_only':'4383',
  'zombie_prophecies':'1878',
  'feelin_the_burn':'1356',
  'only_in_the_dark':'1892',
  'tammi_sauer_princess_in_training':'5988',
  'dark_night_experiements':'1752',
  'coming_home_to_a_place_ive_never_been':'1756',
  'against_her_will':'2063',
  'mommy_to_be':'2064',
  'half_blood':'1269',
  'in_cold_blood':'4373',
  'robert_lacey_battle_of_brothers':'5991',
  'eleanor_anstruther_a_perfect_explanation':'5992',
  'crown_of_lies':'698',
  'homewrecking_alpha':'1163',
  'treacherous_secrets':'1546',
  'chicago_bratva_series':'2025',
  'lqfix':'6125',
  'alexandra_joel_the_royal_correspondent':'5993',
  'alphas_guest_10ch_sfx_only':'3978',
  'alphas_call_human_translation_german':'4265',
  'his_ladys_maid':'3835',
  'lycans_queen_music_test':'4684',
  'silverproof':'3834',
  'secrets_of_the_worlds_worst_matchmaker':'2265',
  'talking_to_the_moon':'5358',
  'alphas_second_chance_nymph_spanish_2':'2830',
  'dont_let_me_fall':'1254',
  'power':'1205',
  'chasing_kiarra_music_only_test':'4435',
  'hollow':'1224',
  'his_queen':'1637',
  'intimately_inked':'5998',
  'nola_and_duke':'1640',
  'dont_cross_the_x':'1897',
  'attached':'1915',
  'vegas_underground_series':'2023',
  'reapers_claim_si_resound':'6472',
  'dragons_slave_2_german':'2898',
  'sincerely_yours':'103',
  'below_our_happy_home':'1121',
  'how_one_man_recruited_and_trained_a_sub':'2903',
  'requiem_city_german_human_translation':'2833',
  'chasing_kiarra_german_human_translation':'3076',
  'alpha_and_aurora_human_translation_de':'4156',
  'snap':'1722',
  'behind_the_masque_book_i':'676',
  'last_to_fall':'6477',
  'his_nerd_music_only':'4158',
  'dancers_body':'3841',
  'ugly_christmas_sweater':'755',
  'lost':'1422',
  'look_at_you':'104',
  'wolfs_rose':'1710',
  'ghost_moon':'3760',
  'stray_puppy':'1387',
  'restless':'1663',
  'immersive_demo':'4139',
  'gideon_1_human_translation_german':'3466',
  'one_that_came_back':'4029',
  'dev_ravenous_immersive_sound_test':'2867',
  'paul_tremblay_a_head_full_of_ghosts':'5747',
  'wait_for_you_wait_for_you':'5040',
  'tessa_bailey_fix_her_up':'5773',
  'her':'4026',
  'immersive_example':'6787',
  'going_nowhere':'544',
  'grasping_fate':'875',
  'under_the_moon':'4035',
  'wotw_the_feral_war':'3245',
  'girl_next_door':'6767',
  'the_truth_in_lies':'534',
  'cross_to_bear':'2185',
  'choice':'6484',
  'alphas_beta_mate':'401',
  'my_alpha_lover':'551',
  'take_the_risk':'405',
  'childish_things':'1901',
  'studio_sandbox_dev_story':'3342',
  'siren_prisoner':'6686',
  'dream_of_me':'2345',
  'misconception':'4148',
  'discretion':'6491',
  'one_in_a_million_love':'1379',
  'the_lycans_queen_human_translation_de':'3370',
  'trapping_quincy_io':'373',
  'hollows_pale_demon':'5594',
  'julia_quinn_just_like_heaven':'5786',
  'meeting_him_in_between':'493',
  'alphas_of_north_forest':'6660',
  'fairy_godmother_inc_2_full_sound':'6218',
  'unchosen_path':'6493',
  'fighting_darius':'1295',
  'one_night':'1636',
  'big_country_small_town':'484',
  'married_at_first_sight':'6690',
  'enemies_io':'4660',
  'punished_series':'6339',
  'work_with_me':'298',
  'my_brothers_best_friend':'480',
  'wired_rogue':'3940',
  'karin_slaughter_pretty_girls':'5737',
  'have_i_got_a_deal_for_you':'521',
  'not_all_about_you_10ch_test_music':'3870',
  'stepbrother_dearest':'2299',
  'winning_my_best_friends_girl':'2272',
  'private_lessons':'1180',
  'three_the_perfect_number_valentine':'6501',
  'nicky_the_driver_bonus_epilogue':'6503',
  'derwyns_rejected_mate':'6505',
  'two_marks_de_s_3':'4476',
  'dark_series':'5332',
  'the_lone_wolfs_rejected_mate':'6504',
  'taming_the_zookeeper':'4621',
  'heavy_deleted_scene':'6506',
  'wolves_of_the_black_rose':'6508',
  'wired_rogue_new':'4418',
  'his_haze_immersive':'6509',
  'hitting_the_wall_bonus_epilogue':'6515',
  'run_posy_run_bonus_epilogue':'6516',
  'akhm_si_test_resound':'6312',
  'julia_quinn_to_catch_an_heiress':'5556',
  'spark':'6519',
  'lunas_secret':'1154',
  'mason_resound_upbeat':'6101',
  'scandalous':'6525',
  'collide':'385',
  'amy_reed_unforgivable':'5570',
  'alpha_king_hybrid_mate_music_test':'4827',
  'initials_only':'376',
  'alphas_guest_10ch_music_only':'3874',
  'sharing_delilah_spanish_launch_2':'5077',
  'lucy_foley_the_hunting_party':'5697',
  'secret_angel':'6526',
  'teenage_dreams':'4199',
  'three_the_perfect_number_sfx_only':'4753',
  'werewolf_chronicles':'100',
  'when_tables_turn':'1035',
  'temperance':'4033',
  'alphas_of_south_forest_valentine':'6489',
  'fairy_godmother_inc_updated_full_sound':'6530',
  'the_cradle_snatcher':'70',
  'devil_vs_alpha':'87',
  'the_playmaker':'420',
  'fairy_godmother_spanish_6_launch':'4958',
  'awaken':'3958',
  'his_nerd':'514',
  'julia_quinn_the_sum_of_all_kisses':'5870',
  'vegas_underground_french_s_5_ms':'5296',
  'whats_a_luna':'876',
  'becoming_the_werewolf_queen':'5082',
  'uncontrollable_heat':'1266',
  'him_and_i':'501',
  'kenzo_desired_temptation':'6487',
  'alpha_king_hybrid_mate_sfx_test':'4828',
  'three_the_perfect_number_music_only':'4752',
  'possessive_guard_4_spanish_launch':'5338',
  'wotw_rejected_replaced_forgotten':'6514',
  'impossible_mates':'3773',
  'grin':'4025',
  'i_shouldnt_want_you':'789',
  'marked':'1033',
  'a_princesa_perdida_season_2_part_2msbr':'4781',
  'wanting_the_man':'1105',
  'wolf_ranch_spanish_s_6_ms':'5297',
  'blood_moon_series':'6015',
  'desperate':'1337',
  'house_call':'1340',
  'murphys_law':'4417',
  'the_hunt_spanish_2_launch':'4963',
  'peanut_butter_kisses':'2770',
  'hells_riders_mc':'1776',
  'bantering_with_murder':'1542',
  'fallen':'4865',
  'emergency_plan_f':'4967',
  'love_by_coin':'5326',
  'high_school_with_my_fiance':'555',
  'fable_of_happiness':'1339',
  'alphas_guest_full_sound':'5294',
  'wolf_wars_spanish_season_3_last':'4964',
  'serojie_the_seeing_eye':'1537',
  'moonlight':'3619',
  'a_string_of_murder':'1544',
  'chasing_kiarra_full_sound_full_season':'5010',
  'crossing_in_a_heartbeat':'5997',
  'loathing_ryan':'4233',
  'second_chance':'4234',
  'unfortunate_friends':'2751',
  'difficult_dukes_ten_things_i_hate_about_the_duke':'5005',
  'bridgerton_an_offer_from_a_gentleman':'5012',
  'alpha_kings_daughter':'805',
  'i_heart_i_heart_paris':'5020',
  'dragons_leash':'850',
  'untie_the_knot':'4087',
  'ride':'167',
  'roommates_with_benefits':'513',
  'destined_to_love':'2337',
  'the_dream_life':'2351',
  'alpha_ares':'1175',
  'haunting_lies':'4618',
  'annie_lyons_the_brilliant_life_of_eudora_honeysett':'5756',
  'snakes_and_ladders':'5375',
  'seeking_happiness':'1713',
  'need_me_like_that':'5374',
  'i_can_read_level_3_sam_the_minuteman':'6537',
  'the_billionaires_mistress':'599',
  'marcello_mafia':'2336',
  'la_bella_notte':'522',
  'i_didnt_belong_to_you':'1317',
  'beatriz_williams_our_woman_in_moscow':'5815',
  'alphas_prey':'2074',
  'are_you_mine':'2186',
  'date_night':'2800',
  'a_rogues_choice':'359',
  'mirror_image':'3465',
  'unconventional':'5447',
  'broken_queen_10ch_fewer_sfx_test':'6190',
  'the_lairds_marker':'563',
  'the_naked_truth':'625',
  'dev_editor':'1',
  'madhouse':'3865',
  'perfect_amount_of_messed_up':'5235',
  'return_to_westover':'5309',
  'to_find_love_again':'68',
  'mason_full_sound_season_1':'5212',
  'one_call_away':'5451',
  'alpha_loren':'3221',
  'alphas_guest':'364',
  'alive_again':'5383',
  'sweetest_temptation':'1506',
  'dark_ones_series_a_girl_s_guide_to_vampires':'5583',
  'bridgertons_bridgerton':'5552',
  'wild_claim':'314',
  'dark_breed_novels_bedeviled':'5606',
  'outcasts_series':'6351',
  'wolf_whisperer':'739',
  'fighting_for_whats_mine':'2767',
  'clandestine_heart':'3778',
  'michael_crichton_dragon_teeth':'5760',
  'dynamic_price_test':'5470',
  'songs_of_nendavia':'5231',
  'his_nerd_full_sound':'5392',
  'marianne_cronin_the_one_hundred_years_of_lenni_and_margot':'5758',
  'heather_morris_the_tattooist_of_auschwitz':'5771',
  'a_leaphorn_and_chee_novel_listening_woman':'5779',
  'alphas_pet':'1000',
  'assistant':'2215',
  'a_hawthorne_and_horowitz_mystery_the_sentence_is_death':'5746',
  'helene_wecker_the_hidden_palace':'5748',
  'beatriz_williams_all_the_ways_we_said_goodbye':'5762',
  'j_r_r_tolkien_the_silmarillion':'5764',
  'a_potential_suitor':'2494',
  'lycans_queen_full_sound_full_season':'5011',
  'pete_the_cat_pete_the_cat_crayons_rock':'5797',
  'linzie_hunter_what_if_pig':'5798',
  'fat_keily':'1222',
  'the_selection_the_crown':'5806',
  'a_click_graphic_novel_act':'5526',
  'shatter_me_unravel_me':'5804',
  'crimson_cup':'6367',
  'devil_s_rock_all_chained_up':'6563',
  'wotw_the_hunt':'4032',
  'billionaires_and_bikers_sinful_rewards_5':'6565',
  'cabin_fever_heat_rises':'6566',
  'waltz_of_the_lioness':'4832',
  'etaf_rum_a_woman_is_no_man':'5847',
  'devil_s_rock_fury_on_fire':'6567',
  'open_your_heart':'6571',
  'rejected_warrior':'1223',
  'toxic_love':'2409',
  'tell_no_lies':'3852',
  'emma_series':'2200',
  'taken':'88',
  'her_alien_exchange':'6580',
  'the_adventures_of_paddington_the_adventures_of_paddington_the_wrong_list':'5530',
  'kiera_cass_the_betrothed':'5769',
  'the_lycans_queen':'335',
  'casanova_at_law':'1316',
  'saxon_tales_war_lord':'5761',
  'haunted':'125',
  'grace_byers_i_am_enough':'5774',
  'beth_ferry_the_nice_dream_truck':'5777',
  'cupidity':'5048',
  'wayfarers_a_closed_and_common_orbit':'5776',
  'a_leaphorn_chee_manuelito_novel_the_tale_teller':'5784',
  'elly_griffiths_the_postscript_murders':'5785',
  'kevin_wilson_nothing_to_see_here':'5802',
  'mhairi_mcfarlane_just_last_night':'5810',
  'broken_queen_s6_sound':'5953',
  'alpha_king_hybrid_mate_full_sound':'6023',
  'alpha_zachary':'753',
  'billionaire_ceo':'2372',
  'sylvia_plath_the_bell_jar':'5523',
  'yes_mr_larson_and_other_filthy_stories':'3095',
  'sigma_force_novels_the_last_odyssey':'5849',
  'a_dark_witch_series':'6000',
  'a_leaphorn_and_chee_novel_hunting_badger':'5819',
  'highland_magic':'6025',
  'the_darling_detectives_trilogy_satisfaction':'6556',
  'the_lycans_queen_savanah':'6574',
  'mason':'539',
  'fighting_darius_full_sound':'6619',
  'the_night_operator':'1735',
  'little_bridge_island_no_offense':'5821',
  'a_sheldon_horowitz_novel_how_to_find_your_way_in_the_dark':'5825',
  'maisie_dobbs_the_consequences_of_fear':'5828',
  'the_fated':'511',
  'agatha_christie_the_unexpected_guest':'5826',
  'final_asylum_tales_demon_s_vow':'5669',
  'alyssa_cole_when_no_one_is_watching':'5823',
  'elite_bratva_brotherhood':'464',
  'angie_thomas_the_hate_u_give':'5831',
  'darby_kane_pretty_little_wife':'5838',
  'book_of_bixby':'1879',
  'the_lost_princess_crimson_cup':'6495',
  'realm_breaker_realm_breaker':'5824',
  'ruth_galloway_mysteries_a_dying_fall':'5827',
  'laurie_graham_gone_with_the_windsors':'5986',
  'shadow_warriors':'6004',
  'witching_hour':'6476',
  'the_goal':'1978',
  'meg_mason_sorrow_and_bliss':'5833',
  'healed':'5086',
  'playboy_heir':'5324',
  'kimberly_mccreight_friends_like_these':'5832',
  'j_r_r_tolkien_the_hobbit':'5834',
  'march_lord':'6381',
  'barbara_delinsky_finger_prints':'5836',
  'meg_waite_clayton_the_last_train_to_london':'5839',
  'susan_wiggs_the_lost_and_found_bookshop':'5840',
  'serpent_dove_serpent_dove':'5842',
  'stone_barrington_swimming_to_catalina':'5844',
  'princes_true_mate':'863',
  'curvy_girls_cant_date_surfers':'6391',
  'hercule_poirot_mysteries_peril_at_end_house':'5841',
  'renege':'6288',
  'always_you':'6406',
  'the_tyrant_alphas_rejected_mate_bonus':'6407',
  'a_perfect_holiday':'6598',
  'inspector_ian_rutledge_mysteries_a_fatal_lie':'5853',
  'sally_thorne_second_first_impressions':'5837',
  'girl_meets_duke_the_duchess_deal':'5845',
  'liv_constantine_the_last_mrs_parrish':'5855',
  'serpent_dove_blood_honey':'5858',
  'action_presidents_action_presidents_3_theodore_roosevelt':'5544',
  'peter_swanson_every_vow_you_break':'5859',
  'saxon_tales_sword_of_kings':'5865',
  'honey':'6599',
  'an_american_faerie_tale_three_promises':'5610',
  'love_at_stake_the_vampire_and_the_virgin':'5611',
  'the_ether_chronicles_skies_of_gold':'5658',
  'fusion_savor_you':'5665',
  'the_poppy_war_the_poppy_war':'5813',
  'hated_by_my_mate':'535',
  'mia_sosa_the_worst_best_man':'5755',
  'taylor_adams_no_exit':'5766',
  'the_food_group_the_couch_potato':'5782',
  'serpent_dove_gods_monsters':'5885',
  'rumaan_alam_leave_the_world_behind':'5888',
  'dont_underestimate_the_broken':'4203',
  'dragons_shadow':'775',
  'judi_mccoy_wanted_one_sexy_night':'5599',
  'tessa_bailey_tools_of_engagement':'5750',
  'the_way_of_the_drow_starlight_enclave':'5892',
  'i_can_read_level_1_amelia_bedelia_lost_and_found':'5525',
  'survival_of_the_rose':'115',
  'billionaire_baby_daddy':'3762',
  'i_can_read_level_1_44_cats_a_cat_s_best_friend':'5527',
  'gary_d_schmidt_almost_time':'5528',
  'madeline_mcgrane_the_accursed_vampire':'5540',
  'princes_contract_fiancee':'5234',
  'my_first_i_can_read_the_adventures_of_paddington_paddington_and_the_pigeon':'5531',
  'i_can_read_level_1_amelia_bedelia_steps_out':'5539',
  'my_first_i_can_read_the_adventures_of_paddington_paddington_and_the_painting':'5545',
  'daria_peoples_riley_america_my_love_america_my_heart':'5548',
  'tragically_flawed':'6604',
  'vamp_city_a_kiss_of_blood':'5567',
  'ruth_hogan_the_keeper_of_lost_things':'5830',
  'stealing_his_mate':'3756',
  'running_into_you':'6017',
  'the_russians_defiant_wolf':'504',
  'something_in_the_way':'3423',
  'rachel':'4646',
  'bridgertons_the_viscount_who_loved_me':'5553',
  'devils_mercy':'4925',
  'fusion_close_to_you':'5591',
  'an_asylum_tales_story_the_asylum_interviews_trixie':'5603',
  'fusion_the_beauty_of_us':'5616',
  'the_food_group_the_cool_bean':'5780',
  'wotw_in_the_heat':'6523',
  'the_adventures_of_paddington_the_adventures_of_paddington_the_magic_trick':'5532',
  'a_offspring_novella_the_darkness_within':'5620',
  '44_cats_44_cats_cats_on_a_mission':'5534',
  'robin_ha_almost_american_girl':'5543',
  'i_can_read_level_1_44_cats_cats_rock':'5546',
  'paulo_coelho_the_alchemist_a_graphic_novel':'5549',
  'fated_bonds':'6073',
  'a_twilight_texas_anthology_the_christmas_cookie_chronicles_raylene':'6164',
  'action_presidents_action_presidents_1_george_washington':'5535',
  'infinity':'713',
  'hollows_ever_after':'5579',
  'hollows_a_perfect_blood':'5617',
  'an_original_dark_days_story_the_dead_the_damned_and_the_forgotten':'5596',
  'lycans_queen_edit_s':'6123',
  'action_presidents_action_presidents_2_abraham_lincoln':'5536',
  'gabriel_allon_the_order':'5860',
  'vampires_of_crimson_bay_series_intervamption':'5590',
  'love_at_stake_eat_prey_love':'5576',
  'gideon_ch_5_6_resound':'6087',
  'sound_qa_example':'6118',
  'sold_to_the_alpha':'4627',
  'rule_of_always':'4867',
  'final_asylum_tales_demon_s_vengeance':'5587',
  'dirk_steele_series_shadow_touch':'5595',
  'dark_realm_series_darkness_burning':'5598',
  'dark_breed_novels_ascension':'5626',
  'jewel_in_the_crown_edge':'6524',
  'wayfarers_the_galaxy_and_the_ground_within':'5705',
  'billionaires_and_bikers_sinful_rewards_1':'5942',
  'werewolf_university':'4024',
  'jewel_in_the_crown':'4112',
  'judi_mccoy_almost_a_goddess':'5633',
  'theres_something_in_the_woods':'4787',
  'billionaires_and_bikers_sinful_rewards_3':'5941',
  'judi_mccoy_wanted_one_perfect_man':'5642',
  'hollows_every_which_way_but_dead':'5652',
  'a_leaphorn_chee_manuelito_novel_stargazer':'5717',
  'her_last_hope':'862',
  'saxon_tales_war_of_the_wolf':'5726',
  'wiley_cash_when_ghosts_come_home':'5742',
  'what_happened_to_erin':'6112',
  'aav_a_vixens_human':'6329',
  'safety_of_his_arms':'4221',
  'in_the_shadow_of_midnight':'4215',
  'twisted_minds':'1173',
  'love_at_stake_wild_about_you':'5650',
  'the_lord_of_the_rings_the_two_towers':'5714',
  'secret_zoo_the_secret_zoo':'5718',
  'the_brown_sisters_take_a_hint_dani_brown':'5696',
  'bright_star':'184',
  'in_love_with_mr_mafia':'4084',
  'shatter_me_restore_me':'5712',
  'joyce_maynard_count_the_ways':'5713',
  'a_archer_family_novella_all_the_pleasures_of_the_season':'6141',
  'shatter_me_defy_me':'5702',
  'kay_scarpetta_chaos':'5711',
  'heartbreaker_bay_the_trouble_with_mistletoe':'6142',
  'in_the_glowing':'250',
  'lupus_alba':'4834',
  'heal_my_soul':'1165',
  'the_offspring_darkness_becomes_her':'5671',
  'sally_thorne_99_percent_mine':'5694',
  'red_queen_glass_sword':'5698',
  'beyond_the_pack':'499',
  'you_taught_me_to_fly':'541',
  'starlit_wolf':'780',
  'natalie_haynes_a_thousand_ships':'5703',
  'a_bachelor_fireman_novella_desperately_seeking_fireman':'6143',
  'a_bachelor_fireman_novella_it_s_a_wonderful_fireman':'6146',
  'deadly_angels_the_angel_wore_fangs':'6147',
  'karin_slaughter_pieces_of_her':'5709',
  'firefighter':'2400',
  'the_daevabad_trilogy_the_city_of_brass':'5710',
  'an_american_faerie_tale_the_stolen':'5614',
  'shatter_me_ignite_me':'5720',
  'the_lord_of_the_rings_the_fellowship_of_the_ring':'5722',
  'stone_barrington_new_york_dead':'5738',
  'zora_neale_hurston_their_eyes_were_watching_god':'5739',
  'the_lord_of_the_rings_the_lord_of_the_rings':'5749',
  'bridgertons_it_s_in_his_kiss':'5554',
  'bridgertons_the_bridgertons_happily_ever_after':'5566',
  'summer_love':'4912',
  'action_presidents_action_presidents_4_john_f_kennedy':'5537',
  'misha_collins_the_adventurous_eaters_club':'5538',
  'moon_river':'1161',
  'i_can_read_level_1_amelia_bedelia_gets_the_picture':'5551',
  'the_replacement':'101',
  'karen_harper_american_duchess':'5725',
  'anthony_horowitz_magpie_murders':'5812',
  'balli_kaur_jaswal_erotic_stories_for_punjabi_widows':'5857',
  'demise_of_a_self_centered_playboy':'2266',
  'stalked_assistant':'6075',
  'heartbreaker_bay_hot_winter_nights':'6152',
  'love_dominance_and_suppression':'2894',
  'demons_artifice':'2031',
  'brazen':'2357',
  'the_ether_chronicles_skies_of_steel':'5608',
  'vampires_of_crimson_bay_series_last_vamp_standing':'5670',
  'hazel_gaynor_the_lighthouse_keeper_s_daughter':'5757',
  'the_alpha_kings_claim':'942',
  'karin_slaughter_the_good_daughter':'5854',
  'kenzo':'2905',
  'beautiful_mistake_ch_1_5_resound':'6095',
  'love_at_stake_vamps_and_the_city':'5656',
  'a_dark_breed_novella_before_the_fall':'5657',
  'an_american_faerie_tale_the_forgotten':'5618',
  'love_at_stake_sexiest_vampire_alive':'5636',
  'dirk_steele_series_tiger_eye':'5659',
  'dark_realm_series_darkness_captured':'5663',
  'mr_charlton':'531',
  'an_asylum_tales_story_the_asylum_interviews_bronx':'5602',
  'heartbreaker_bay_about_that_kiss':'6139',
  'the_offspring_a_perfect_darkness':'5622',
  'kerrelyn_sparks_less_than_a_gentleman':'5630',
  'immortal_dawn':'123',
  'her_light':'425',
  'will_trent_the_last_widow':'5908',
  'a_love_and_football_novella_holding_holly':'6158',
  'heartbreaker_bay_holiday_wishes':'6159',
  'the_ether_chronicles_night_of_fire':'5631',
  'lisa_kleypas_chasing_cassandra':'5788',
  'dirk_steele_series_the_red_heart_of_jade':'5632',
  'love_at_stake_how_to_marry_a_millionaire_vampire':'5674',
  'jenny_holiday_a_princess_for_christmas':'5936',
  'fusion_blush_for_me':'5667',
  'lindsey_kelk_i_heart_paris':'5680',
  'liv_constantine_the_stranger_in_the_mirror':'5689',
  'beautiful_belle_and_alpha_grayson':'251',
  'judi_mccoy_making_over_mr_right':'5581',
  'nightmare_chronicles_before_i_wake':'5641',
  'dragons_slave':'767',
  'raised_by_vampires':'1729',
  'an_original_dark_days_story_bound_to_me':'5654',
  'nikki_gemmell_i_take_you':'5686',
  'dirk_steele_series_in_the_dark_of_dreams':'5660',
  'office_rules':'3105',
  'flames_that_bind_us':'105',
  'vamp_city_a_blood_seduction':'5679',
  'love_at_stake_the_vampire_with_the_dragon_tattoo':'5580',
  'paullina_simons_tatiana_and_alexander':'5928',
  'dark_series_dark_desire':'5939',
  'love_at_stake_how_to_seduce_a_vampire_without_really_trying':'5653',
  'pure_perfection':'436',
  'claws':'492',
  'ruthless_surrender':'584',
  'nikki_gemmell_the_bride_stripped_bare':'5682',
  'master_of_metal':'6684',
  'demons_artifice_spanish_launch_2':'6694',
  'the_asylum_tales_angel_s_ink':'5628',
  'destined_alpha':'3318',
  'dirk_steele_series_a_dream_of_stone_shadow':'5643',
  'john_irving_a_prayer_for_owen_meany':'5752',
  'hollows_black_magic_sanction':'5607',
  'marian_keyes_anybody_out_there':'5676',
  'corporate_crush':'6704',
  'journey_to_wudang_trilogy_hell_to_heaven':'5684',
  'hercule_poirot_mysteries_hercule_poirot_s_christmas':'5721',
  'becoming_luna':'5112',
  'my_first_secret':'6699',
  'surrogate_girlfriend':'3365',
  'chase_and_the_chains':'6706',
  'mates':'6705',
  'hollows_a_perfect_blood_with_bonus_material':'5604',
  'a_leaphorn_chee_manuelito_novel_spider_woman_s_daughter':'5768',
  'julia_quinn_the_lost_duke_of_wyndham':'5790',
  'dark_heroine_series_the_dark_heroine':'5940',
  'reapers_claim_finale':'6700',
  'dark_days_series_nightwalker':'5612',
  'the_millennium_wolves_2':'77',
  'the_girl_who_cried_werewolf':'6703',
  'warriors_the_prophecies_begin_warriors_1_into_the_wild':'5557',
  'hollows_the_outlaw_demon_wails':'5588',
  'dirk_steele_series_eye_of_heaven':'5627',
  'hollows_a_fistful_of_charms':'5648',
  'dirk_steele_series_the_fire_king':'5600',
  'miss_marple_mysteries_the_moving_finger':'5692',
  'wallflowers_the_devil_in_winter':'5775',
  'hercule_poirot_mysteries_murder_on_the_orient_express':'5873',
  'hey_hey_testio':'729',
  'my_possessive_mate':'3097',
  'dark_breed_novels_chosen':'5573',
  'a_highlanders_faith':'2217',
  'realm_series':'5381',
  'his_kitten':'3817',
  'her_dark_silence':'4375',
  'spouse_trap':'4376',
  'neil_gaiman_stardust':'5559',
  'peter_swanson_the_kind_worth_killing':'5569',
  'the_marriage_bargain':'537',
  'enforce_my_heart':'552',
  'falling_for_the_alpha':'259',
  'two_sides_to_every_story':'299',
  'back_to_my_country_heart':'896',
  'ezekiels_luna':'323',
  'amethyst_pack':'432',
  'dreaming_of_you':'1044',
  'her_blue_sky':'530',
  'poisonous_greed':'1008',
  'roses_and_fangs':'770',
  'fighting_fate':'317',
  'beta_stone':'253',
  'craving_the_player':'791',
  'gideon':'241',
  'his_brothers_girl':'509',
  'playing_deep':'670',
  'torian_warriors':'1152',
  'nillium_neems':'1602',
  'wolf_ranch_series':'1334',
  'reason_to_believe':'1348',
  'monsters_in_the_dark':'701',
  'dragons_pet':'777',
  'alpha_malik':'719',
  'dancing_naked':'1076',
  'when_it_will_rain_it_will_pour':'303',
  'amidst_chaos':'485',
  'alpha_jasper':'717',
  'luna_awakened':'2054',
  'what_a_woman_wants':'2760',
  'beautiful_mistake':'94',
  'walk_through_shadows':'966',
  'seal_team_one':'655',
  'yes_mr_knight':'1211',
  'roommate_fwb':'1649',
  'jaspers_soulmate':'355',
  'nathaniel_lachlan':'408',
  'finding_your_fate':'350',
  'different':'390',
  'arranged_marriage':'710',
  'falling_for_the_mafia_brothers':'1466',
  'alpha_grayson':'294',
  'underground_kings':'1326',
  'meeting_my_abductor_again':'658',
  'gideon_ku':'6404',
  'beautiful_mistake_ku':'6403',
  'when_the_night_falls':'1386',
  'just_go_with_it':'1726',
  'choose_me_or_lose_me':'679',
  'alpha_landon':'716',
  'love_bites':'1074',
  'bad_boy_alphas_series':'1412',
  'alpha_rylan':'720',
  'chasing_the_omega':'433',
  'ruling_the_ceo':'1296',
  'the_alphas_runt':'684',
  'sold_on_you':'788',
  'he_healed_my_heart':'267',
  'the_alphas_arrangement':'517',
  'wolf_inside':'378',
  'their_little_human':'1012',
  'her_familiar_stranger':'1293',
  'mr_nice_guy':'7616',
  'alphas_unwilling_mate': '7562',
  'taketherisk_3_tr': '8070',
  'his_lovely_pet_audiobook_edit': '8069',
  'werewolf_chronicles_audiobook_edit': '8068',
  'unfortunate_friends_audiobook_edit': '8067',
  'alphas_servant_audiobook_edit': '8066',
  'falling_for_the_mafia_brothers_spanish_3': '8065',
  'lost_princess_audiobook_edit': '8063',
  'hells_riders_mc_audiobook_edit': '8061',
  'dragons_slave_audiobook_edit': '8059',
  'home_wrecking_alpha_audiobook_edit': '8057',
  'galatea_university_2_12_fvrw': '8036',
  'a_virada_season_3_msbr': '8034',
  'broken_queen_rendez_vous_by_the_lake': '8033',
  'audiobook_rebel_souls_mc': '8024',
  'fighting_darius_smoother_line': '8018',
  'gideon_hiding_in_the_closet': '8017',
  'her_savior': '8016',
  'return_to_silver_creek': '8015',
  'a_ridge_mountain_pack_series': '8014',
  'the_alpha_and_luna_series_book_2': '8006',
  'wolfe_ranch_book_2_part_2': '8005',
  'the_dangerous_past_split_release': '8004',
  'secrets_of_sin_deep_edit_1': '8001',
  'lycans_queen_have_you_heard_the_gossip': '8000',
  'req_5_tr': '7999',
  'rebel_soul_mc_audiobook_edit': '7998',
  'infinity_audiobook_edit': '7983',
  'beautiful_belle_and_alpha_grayson_book_1': '7981',
  'dragons_blood_audiobook_edit': '7970',
  'alpha_ares_mysterious_guest': '7969',
  'work_with_me_audiobook_edit': '7968',
  'marrying_the_ceo_audiobook_edit': '7967',
  'ch_1_plus_streamlined': '7966',
  'the_overdue_quickie': '7965',
  'the_revenge': '7964',
  'the_couple': '7963',
  'the_ceo': '7962',
  'the_colleague': '7961',
  'the_bad_day': '7960',
  'striptease': '7959',
  'twins': '7958',
  'the_girl': '7957',
  'the_striptease': '7956',
  'falling_3_tr': '7954',
  'cowboys_heart_of_stone_split_release': '7953',
  'the_alphas_lone_wolf_book_1_split_relea': '7952',
  'slave_of_the_onyx_dragon_audiobook_edit': '7951',
  'colt_audiobook_edit': '7950',
  'treasure_of_the_sea': '7936',
  'just_the_little_sister_deep_edit': '7926',
  'in_the_shadows_deep_edit': '7924',
  'kenzo_societa_oscura': '7923',
  'audiobook_infinity_book_2': '7921',
  'under_his_reign': '7919',
  'audiobook_perfect_spiral': '7908',
  'alpha_and_aurora_meet_me_in_the_forest': '7907',
  'ponto_de_virada_season_3_msbr': '7904',
  'first_person': '7893',
  'gideon_resound_deep_edit_win': '7888',
  'the_emma_series_audiobook_edit': '7883',
  'cidade_requiem_season_5_msbr': '7882',
  'how_not_to_date_your_neighbor_split_re': '7881',
  'second_impressions_series_split_release': '7880',
  'lost_lycans_mate_its_audiobook_edit': '7879',
  'the_perfect_spiral_audiobook_edit': '7878',
  'infinity_book_2_audiobook_edit': '7877',
  'ruling_the_ceo_audiobook_edit': '7876',
  'brazen_audiobook_edit': '7875',
  'my_cowboy_audiobook_edit': '7874',
  'apaixonada_pelos_irmaos_mafiosos_3_msbr': '7873',
  'grey_billionaire': '7872',
  'the_secret': '7871',
  'the_sex_slave': '7870',
  'the_best_friend': '7869',
  'the_stranger': '7868',
  'something_like_that_split_release': '7867',
  'the_werewolf_motorcycle_club_split_relea': '7866',
  'the_werewolf_king_series_split_release': '7864',
  'werewolf_kings_mate': '7863',
  'tafc_the_dangerous_past': '7862',
  'after_dark': '7861',
  'the_witching_hour': '7859',
  'marcello_mafia_passionate_intro': '7857',
  'lycans_queen_resound_deep_edit_win': '7856',
  'wolf_dream': '7855',
  'alphas_lone_wolf': '7854',
  'helions_kitchen_split_release': '7853',
  'helions_kitchen': '7852',
  'defendant': '7851',
  'cowboys_heart_of_stone': '7850',
  'love_at_the_fifty_yard_line': '7849',
  'audiobook_mw_his_haze': '7848',
  'audiobook_beautiful_belle_and_alpha_grayson_his_lost_queen': '7847',
  'audiobook_mafia_puppet': '7846',
  'ai_authors_voice_2': '7844',
  'ai_authors_voice_1': '7843',
  'ai_authors_voice': '7842',
  'the_arrangement_de_gpt': '7839',
  'then_you_look_at_me_split_release': '7838',
  'then_you_look_at_me': '7837',
  'ghosted_soul_immersive_user_feedback': '7836',
  'first_responders_book_1_split_release': '7833',
  'demon_ascending': '7832',
  'airport_security': '7831',
  'three_the_perfect_number_audiobook_edit': '7830',
  'alphas_arrangement_deep_edit': '7829',
  'first_five_rewrite': '7828',
  'ai_ch_1_2_3': '7826',
  'lycans_queen_spanish_rework_2023_3': '7825',
  'lycans_queen_spanish_rework_2023_2': '7824',
  'eyes_on_us_immersive_user_feedback': '7822',
  'seeing_scarlett_immersive_user_feedback': '7821',
  'half_blood_resound': '7820',
  'eat_me': '7818',
  'his_lost_queen_audiobook_edit': '7815',
  'cross_to_bear_audiobook_edit': '7814',
  'the_half_blood_audiobook_edit': '7813',
  'yes_mr_knight_audiobook_edit': '7812',
  'when_tables_turn_deep_edit': '7811',
  'his_haze_audiobook_edit': '7808',
  'mafia_puppet_audiobook_edit': '7807',
  'amor_en_guerra': '7804',
  'entre_tu_mundo_y_el_mio': '7803',
  'divorciada_de_un_paralitico': '7802',
  'her_possessive_guard_audiobook_edit_4': '7798',
  'immortal_dawn_immersive_user_feedback': '7795',
  'her_possessive_guard_audiobook_edit': '7794',
  'brothers_best_friend': '7793',
  'the_siren': '7792',
  'soccer_mom': '7791',
  'at_the_end_of_the_world_audiobook_edit': '7789',
  'cold_showers_split_release': '7788',
  'encounter_with_an_alpha_split_release': '7787',
  'encounter_with_an_alpha': '7786',
  'kidnapped_by_my_mate_tr_1_reedit': '7784',
  'sweet_sinner': '7783',
  'silent_embrace_immersive_user_feedback': '7782',
  'mttwk_immersive_user_feedback': '7781',
  'dirty_little_vow': '7780',
  'bad_business': '7779',
  'friends_right_split_release': '7776',
  'bosss_secret_baby_split_release': '7775',
  'twisted_minds_audiobook_edit': '7774',
  'kbbm_resound_ch1_2': '7773',
  'mssiaw_audiobook_edit': '7772',
  'werewolf_university_deep_edit': '7771',
  'bastard_boss': '7770',
  'guns_and_royalties_deep_edit': '7769',
  'reveal_ch_1': '7768',
  'the_replacement_audiobook_edit': '7766',
  'werewolf_motorcycle_club': '7764',
  'something_like_that': '7763',
  'emmelines_alpha': '7762',
  'friends_right': '7760',
  'hbmm_comic': '7758',
  'uma_proposta_indecente_reediting': '7757',
  'ghost_assassin': '7755',
  'os_lobos_do_milenio_reediting': '7754',
  'sam_in_ch_1': '7748',
  'party_is_over': '7747',
  'ftbu_immersive_user_feedback_ch_1': '7746',
  'akhm_resound': '7745',
  'happy_death_day': '7744',
  'bloody_love': '7742',
  'bloody_vows': '7741',
  'love_kils': '7737',
  'love_me_dead': '7735',
  'murder_girl': '7731',
  'colt_resound': '7728',
  'rearrangement_3_tr': '7725',
  'rearrangement_2_tr': '7724',
  'murder_notes': '7723',
  'arrangement_reedited_1_2_3_tr': '7722',
  'hated_by_my_mate_immersive_user_feedback': '7720',
  'alphas_match_deep_edit': '7719',
  'the_replacement_deep_edit': '7718',
  'record_daddy': '7717',
  'flight_attendant': '7716',
  'gift_of_god_split_release': '7715',
  'the_bargain': '7714',
  'inkitt_gpt_5': '7709',
  'mason_inkitt_gpt': '7708',
  'the_unseen_book_1_split_release': '7707',
  'twisted_minds_inkitt_gpt': '7703',
  'torian_warriors_inkitt_gpt': '7702',
  'an_alphas_vixen_inkitt_gpt': '7701',
  'req_city_inkitt_gpt': '7700',
  'requiem_city_inkitt_gpt': '7699',
  'meeting_my_abductor_again_inkitt_gpt': '7698',
  'secrets_of_sin_inkitt_gpt': '7697',
  'tech_billionaire_inkitt_gpt': '7696',
  'streamlined_intro_h': '7694',
  'inkitt_gpt_06': '7693',
  'creatures_of_the_dark_book_1_split_relea': '7690',
  'the_morretti_brothers_book_1_split_relea': '7689',
  'alpha_ares_inkitt_gpt': '7688',
  'king_of_ruin_ep_1_si': '7687',
  'hated_tr_leftover': '7683',
  'millennium_wolves_1_and_2_audiobook_edit': '7682',
  'the_game': '7680',
  'something_unexpected': '7679',
  'marcello_mafia_inkitt_gpt': '7678',
  'inkitt_gpt_4': '7677',
  'when_the_night_falls_inkitt_gpt': '7676',
  'cabin_by_the_lake_ai_split_release': '7675',
  'inkitt_gpt_3': '7673',
  'the_arrangement_inkitt_gpt': '7670',
  'inkitt_gpt_2': '7668',
  'inkitt_gpt': '7667',
  'when_maeve_met_caleb_split_release': '7665',
  'teach_me_to_love_split_release': '7664',
  'scarlet_ocean_split_release': '7663',
  'cabin_by_the_lake_split_release': '7660',
  'alphas_unwilling_mate_split_release': '7659',
  'the_barbarian_book_4': '7658',
  'second_impressions': '7657',
  'blazing_sirens': '7656',
  'addicted_to_you': '7655',
  'dragons_princess_inkitt_gpt': '7654',
  'the_lost_princess_inkitt_gpt': '7653',
  'tranquility_inkitt_gpt': '7652',
  'reapers_claim_inkitt_gpt': '7651',
  'the_arrangement_audiobook_edit_3_6': '7650',
  'how_not_to_date_your_neighbor': '7647',
  'cold_showers': '7646',
  'broken_fate': '7645',
  'home_on_the_range_split_release': '7644',
  'the_sheriffs_deputy_book_1_split_releas': '7643',
  'falling_for_a_cowboy_split_release': '7642',
  'meu_alfa_me_odeia_season_2_part_2': '7641',
  'angry_james': '7640',
  'lycans_queen_deep_edit': '7639',
  'love_bites_deep_edit': '7637',
  'secrets_of_sin_deep_edit': '7636',
  'gideon_deep_edit': '7635',
  'kbmm_airport_bar': '7634',
  'colt_ep_1_3_intro_restructure': '7633',
  'wolves_of_artemis_book_1_split_release': '7632',
  'daughter_of_albion_book_1_split_release': '7631',
  'alpha_wolfs_fated_mate_split_release': '7630',
  'frozen_desires_book_1_split_release': '7629',
  'kenzo_teach_me': '7628',
  'bitten_by_the_alpha_immersive_ch_1_test': '7627',
  'colt_immersive_ch_1_test': '7626',
  'the_barbarian_2_tr': '7625',
  'streight_boys': '7624',
  'last_to_fall_first_to_rise': '7623',
  'eyes_like_a_wolf': '7622',
  'silent': '7618',
  'boss_secret_baby': '7617',
  'mr_nice_guy': '7616',
  'kinkys_carnival': '7615',
  'gift_of_god': '7614',
  'sex_dream': '7613',
  'undressed_by_the_king_deep_edit': '7610',
  'the_arrangement_1_and_2_audiobook_edit': '7606',
  'gideon_audiobook_edit': '7605',
  'mason_book_3_audiobook_edit': '7604',
  'mason_book_2_audiobook_edit': '7603',
  'mason_book_1_audiobook_edit': '7602',
  'my_cowboy_immersive_ch_1_test': '7601',
  'trapping_quincy_dreaming_of_quincy': '7600',
  'test_comic': '7599',
  'streamlining_first_7': '7597',
  'broken_queen_immersive_ch_1_test': '7596',
  'mw_combined_summary': '7595',
  'when_the_night_falls_rival_tension': '7593',
  'galatea_university_60': '7592',
  'mason_combined_bundle': '7591',
  'half_blood_visions_in_the_forest': '7590',
  'ai_ch_1_3': '7588',
  'ai_ch_1_2': '7587',
  'ai_ch_1_1': '7586',
  'red_flags': '7585',
  'lycans_queen_audiobook_script': '7583',
  'mason_sexual_tension': '7582',
  'king_of_ruin_full_immersive_2': '7579',
  'king_of_ruin_full_immersive': '7578',
  'ai_ch_11': '7575',
  'ai_ch_1': '7574',
  'rethink_intro': '7573',
  'revenge_on_the_billionaire_split_release': '7572',
  'prazer_a_negocios_4_msbr': '7571',
  'cabin_by_the_lake': '7569',
  'creatures_of_the_dark': '7568',
  'the_eresthai_series_book_2': '7566',
  'the_stillwell_cowboys_book_2': '7565',
  'the_discovering_us_series_book_2': '7564',
  'alphas_unwilling_mate': '7562',
  'hated_by_my_mate_audiobook_edit': '7561',
  'savage_immersive_test': '7560',
  'his_nerd_deep_edit': '7559',
  'my_grumpy_alpha_full_audiobook_script': '7558',
  'stray_puppy_spanish_2': '7556',
  'crimson_cup_ch_17': '7553',
  'feelin_the_burn_audiobook_edit': '7552',
  'a_humana_perdida_season_2_msbr': '7551',
  'riders_of_tyr_resound': '7550',
  'galatea_university_53': '7549',
  'daughter_of_albion': '7548',
  'audiobook_editing_test': '8136',
  'until_dawn ': '8134',
  'broken_queen_finale': '8302',
  'luna_graced': '8096',
  'luna_graced_deep_edit_test': '8596',
  'devil_vs_alpha': '87'
  };
