import React from "react";
import { Settings } from "../types";

type ModelIndicatorProps = {
  settings: Settings,
};

export const ModelIndicator = (props: ModelIndicatorProps) => {
  const {settings} = props;
  return (<div className="model-indicator">
  <ins>
    <strong>{settings.mode}</strong>{" "}
    <small>{settings.model}</small>
  </ins>{" "}
  {settings.mode === 'deep-editing' &&  <span>
    {settings.preserveParagraphs ? (
      <small style={{ color: "green" }}>
        (Preserving paragraphs)
      </small>
    ) : (
      <small style={{ color: "coral" }}>(New paragraphs)</small>
    )}
  </span>}
  </div>);
};

