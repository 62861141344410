const LETTER_A = 65;
const AB_LETTER_BASE = 26;

export default function abIndexToLetter(ab_index: number): string {
  if (ab_index < 0) {
    return '?';
  }

  const firstLetterIndex = Math.trunc(ab_index / AB_LETTER_BASE) - 1;
  const secondLetterIndex = ab_index % AB_LETTER_BASE;

  if (firstLetterIndex > AB_LETTER_BASE - 1) {
    return '??';
  }

  return [
    firstLetterIndex > -1
      ? String.fromCharCode(LETTER_A + firstLetterIndex)
      : '',
    String.fromCharCode(LETTER_A + secondLetterIndex),
  ].join('');
}

