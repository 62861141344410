import React, { useEffect, useState } from "react";
import { diffContent, diffContentFullText } from "../lib/diff";
import type { SampleContent, DifferenceByWords, Settings } from "../types";
import { DiffContentView } from "./DiffContentView";

type ContentProps = {
  content: SampleContent;
  enableSkip: boolean;
};
const ContentView = ({ content, enableSkip }: ContentProps) => (
  <article style={{ fontFamily: "serif", marginTop: 0 }}>
    {content.map((paragraph) => (
      <p
        key={paragraph.block_id}
        className={enableSkip && paragraph.skip ? "protected-paragraph" : ""}
        data-tooltip={enableSkip && paragraph.skip ? "Protected paragraph (chat)" : null}
      >
        {paragraph.text}
        {paragraph.tags ? <span className="audio-tags">{paragraph.tags}</span> : <></>}
      </p>
    ))}
  </article>
);

type ContentViewerProps = {
  contentBefore: SampleContent;
  contentAfter: SampleContent;
  settings: Settings;
};
export const ContentViewer = (props: ContentViewerProps) => {
  const { contentBefore, contentAfter, settings } = props;

  const [difference, setDifference] = useState<DifferenceByWords>({
    diff: [],
    added: 0,
    wordCount: 0,
    removed: 0,
    percentage: 0,
  });

  useEffect(() => {
    if (!contentAfter.length) {
      return;
    }

    if (settings.mode === 'immersive-sound' || settings.mode === 'tag-matching') {
      return;
    }

    const difference = settings.preserveParagraphs
      ? diffContent(contentBefore, contentAfter)
      : diffContentFullText(contentBefore, contentAfter);

    setDifference(difference);
  }, [contentBefore, contentAfter]);

  // --- Immersive sound ---
  if (settings.mode === 'immersive-sound') {
    return (
    <div className="grid">
      <div>
        <small>Original</small>
        <ContentView content={contentBefore} enableSkip={false} />
      </div>
      <div>
        <small>Tagged content:</small>
        <ContentView content={contentAfter} enableSkip={false}/>
      </div>
    </div>
    );
  }

  // --- Tag matching ---
  if (settings.mode === 'tag-matching') {
    return (
    <div className="grid">
      <div>
        <small>Original</small>
        <ContentView content={contentBefore} enableSkip={false} />
      </div>
      <div>
        <small>Resulting tags:</small>
        <article style={{marginTop: 0 }}>
          {contentAfter.map(tagsByCat => (
          <div key={tagsByCat.category}>
            <strong>{tagsByCat.category}</strong>
            <p>{tagsByCat.tags?.join(', ')}</p>
          </div>
          ))}
        </article>
      </div>
    </div>
    );
  }  

  // --- Proofreading or Deep Editing ---
  const afterTitle = settings.preserveParagraphs ? 'After proofreading:' : 'After (new paragraphs were created):';
  return (
    <div className="grid">
      <div>
        <small>Original</small>
        <ContentView content={contentBefore} enableSkip={true}/>
      </div>
      <div>
        <small>{afterTitle}</small>
        <DiffContentView content={difference} />
      </div>
    </div>
  );
};
