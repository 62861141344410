import { diffWords } from "diff";
import type { Paragraph, SampleContent, DifferenceByWords } from "../types";

export const diffContentFullText = (
  original: SampleContent,
  processed: Paragraph[]
): DifferenceByWords => {
  const originalFullString = original.map((p) => p.text).join("\n\n");
  const processedFullString = processed.map((p) => p.text).join("\n\n");
  const diffed = diffWords(originalFullString, processedFullString);

  const result = {
    percentage: 0,
    diff: diffed,
    wordCount: 0,
    added: 0,
    removed: 0,
  };

  diffed.forEach((fragment) => {
    if (fragment.added) {
      result.added++;
    }
    if (fragment.removed) {
      result.removed++;
    }
    if (fragment.value) {
      result.wordCount += fragment.value.split(" ").length;
    }
  });

  return result;
};

export const diffContent = (
  original: SampleContent,
  processed: SampleContent
): DifferenceByWords => {
  const result: DifferenceByWords = {
    percentage: 0,
    diff: [],
    wordCount: 0,
    added: 0,
    removed: 0,
  };

  // @ts-ignore
  const processedContentMap = processed.reduce((map, p: Paragraph) => {
    map[p.block_id] = p;
    return map;
  }, {});

  const diffParagraphs = (a: Paragraph, b: Paragraph) =>
    diffWords(a.text, b.text);

  const diffed = original.map((p: Paragraph) =>
    diffParagraphs(p, processedContentMap[p.block_id])
  );

  const { added, removed, wordCount } = diffed.reduce(
    (res, fragments) => {
      fragments.forEach((fragment) => {
        if (fragment.added) {
          res.added++;
        }
        if (fragment.removed) {
          res.removed++;
        }
        if (fragment.value) {
          res.wordCount += fragment.value.split(" ").length;
        }
      });
      return res;
    },
    { added: 0, removed: 0, wordCount: 0 }
  );

  result.diff = diffed;
  result.added = added;
  result.removed = removed;
  result.wordCount = wordCount;
  result.percentage = (100 * (added + removed)) / wordCount;

  return result;
};

/**
 * This is just a simple way to red-flag something.
 */
export const simpleDiffPercentageByCharacters = (a, b) => {
  const difference = Math.abs(a.length - b.length);
  return (difference * 100) / a.length;
};
