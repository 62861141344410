import { GPTModel, Settings } from "./types";

export const URL_PREFIX = "https://studio-api.getgalatea.com/";

export const AVAILABLE_MODELS = [
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-16k",
  "gpt-4",
  "gpt-4-1106-preview",
  "gpt-4-0125-preview",
  "gpt-4o-mini",
  "gpt-4o",
] as const;

/**
 * Maximum amount of concurrent workers.
 */
export const MAX_WORKERS_PER_MODEL = {
  "gpt-3.5-turbo": 50,
  "gpt-3.5-turbo-16k": 50,
  "gpt-4": 50, // because we have 40k TPM
  "gpt-4-1106-preview": 50,
  "gpt-4-0125-preview": 50,
  "gpt-4o-mini": 50,
  "gpt-4o": 50,
};

/**
 * Time to wait for a worker to be available again once we've exhausted our MAX_WORKERS
 */
export const THREAD_WAIT_TIME_MS = 10 * 1000;

export const GPT_TOKEN_LIMITS = {
  "gpt-3.5-turbo": 4097,
  "gpt-3.5-turbo-16k": 16385,
  "gpt-4": 8192,
  "gpt-4-1106-preview": 128000,
  "gpt-4-0125-preview": 128000,
  "gpt-4o-mini": 128000,
  "gpt-4o": 128000,
};

export const DEFAULT_SETTINGS: Settings = {
  inputBranch: 'main',
  minDiff: 0,
  maxDiff: 5,
  maxTokens: 4096,
  maxTokens2: 4096, // second request made for immersive-sound mode
  attempts: 10,
  temperature: 0,
  preserveParagraphs: true,
  model: "gpt-3.5-turbo" as GPTModel,
  maxWorkers: 20,
  waitTime: 0,
  branchPrefix: 'proofreading-audit-',
  mode: 'deep-editing'
};

export const DEFAULT_PROMPT =
  `This is a sample from a romance novel in the form of a json file. ` +
  `You are a proofreading AI assistant. Your task is to correct any spelling and grammar errors in the text without changing anything else. ` +
  `Correct spelling and grammar on the values of the fields labeled "text".` +
  ` Preserve the writing style.`;


export const DEFAULT_IMMERSIVE_PROMPT = 'You are a categorization AI assistant. You will be given a list of words called tags, ' +
  'each tag will describe an ambiance, feeling, events or elements.' +
  'Then you will receive a sample from a romance novel in the form of a json file. ' +
  'Your task is to assign the tag that best describes the idea in each "text" field on each element of this json file, ' +
  'separate those tags by commas and put them inside the "tags" field on each element of the json file.';

export const DEFAULT_TAG_MATCHING_PROMPT = `You are an AI assistant. You will be given a list of phrases we will call "tags"; ` +
 'each tag describes a situation, object, topic, setting or relationship. ' +
 'Then you will receive a sample from a romance novel. ' +
 `Your task is to choose tags that apply to the romance novel`;

export const DEFAULT_SUMMARIZE_PROMPT = `This is a summary of a romance novel. Generate a set of short phrases or words that` +
` describe the genre, ambiance, mood, situation, location and or era that best describe the summary.`;

export const AVAILABLE_MODES = [
  {
    name: 'Deep editing',
    key: 'deep-editing',
    label: 'Rewrites the text on a book'
  },
  {
    name: 'Immersive sound',
    key: 'immersive-sound',
    label: 'Adds Immersive Sound to a book'
  },
  {
    name: 'Summarize',
    key: 'summarize',
    label: 'Takes the content of every episode in the book/branch and produces a single episode in a new branch.'
  },
  {
    name: 'Tag Matching',
    key: 'tag-matching',
    label: 'Provided a list of tags, GPT scans the whole book and finds which of those tags are applicable to the story *. Results are placed in the first chapter of a new empty branch'
  }
];
