import React, { useState } from "react";
import type { EpisodeProcess, ProcessMap, StoryProcess } from "../types";
import "./MultithreadProcessTracker.css";
import classNames from "classnames";

type MultithreadProcessTrackerProps = {
  processMap: ProcessMap;
};

const finalStatusses = ["completed", "errored"];
const finished = (ep: EpisodeProcess) => {
  return finalStatusses.includes(ep.status);
};

export const MultithreadProcessTracker = (
  props: MultithreadProcessTrackerProps
) => {
  const { processMap } = props;
  const [currentProcess, setCurrentProcess] = useState(null);
  const processes: StoryProcess[] = Object.keys(processMap).map(
    (key) => processMap[key]
  );

  return (
    <div className="multithread-process-tracker">
      <div>Story Processes tracker:</div>
      <small className="very-small">
        Click on a Story tracker to inspect the log
      </small>
      <div className="multithread-loader">
        {processes.map((process) => {
          const processedEpisodes = process.episodes.filter(finished).length;
          return (
            <div
              key={process.id}
              className={classNames({
                "story-loader": true,
                selected: currentProcess && currentProcess.id === process.id,
              })}
              zIndex={0}
              onClick={() => setCurrentProcess(process)}
            >
              <div>
                <div>{/* <small>{process.slug}</small> */}</div>
                <small>
                  Story ID <strong>{process.id}</strong>
                  {processedEpisodes === process.originalNumberOfEpisodes && (
                    <span style={{ color: "limegreen" }}> ✓</span>
                  )}
                </small>
              </div>
              <div>
                <small>
                  Episodes processed:{" "}
                  <strong>
                    {processedEpisodes} of {process.originalNumberOfEpisodes}
                  </strong>
                </small>
              </div>
              <progress
                value={processedEpisodes}
                max={process.originalNumberOfEpisodes}
              />
            </div>
          );
        })}
      </div>

      {currentProcess && (
        <div className="process-log">
          <div className="log-title">
            <small>
              Log for Story ID <strong>{currentProcess.id}</strong>
            </small>
          </div>
          <div>
            <small className="very-small">
              Episodes are processed concurrently with no specific order.
            </small>
          </div>
          {currentProcess.episodes.map((episode) => (
            <div key={episode.episodeId}>
              <p
                className="episode-log-title"
                aria-busy={episode.status !== "completed"}
              >
                <strong>
                  {episode.status == "completed" ? "Episode completed ✓" : ""}
                </strong>
                <br />
                ID: <strong>{episode.episodeId}</strong>&nbsp;&nbsp;N:{" "}
                <strong>{episode.number}</strong>&nbsp;&nbsp;
                <i>"{episode.title}"</i>
                <br />
              </p>
              {episode.log.map((message, index) => (
                <p
                  className={
                    message.danger || message.text?.includes("CONFLICTIVE")
                      ? "danger"
                      : ""
                  }
                  key={index}
                >
                  {message.text && "→ "}
                  {message.text}
                </p>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
