import { loadEpisode } from "./lib/episodeLoader";
import createEpisode from "./lib/createEpisode";
import type {
  Settings,
  Chunk,
  ProcessedChunk,
  Paragraph,
  RequestMeta,
  ProcessWorkerOptions,
  TagMatchingResult,
} from "./types";
import { simpleDiffPercentageByCharacters } from "./lib/diff";

import {
  paragraphsToContent,
  contentFromStringChunks,
  contentToMinimalProcessableChunksWithIDs,
  contentToTextOnlyProcessableChunksWithPlaceholders,
  restorePropertyFrom
} from "./lib/contentPreprocessor";
import { hasOnlyPlaceholder } from "./lib/hasOnlyPlaceholder";

export const wait = async (seconds: number): Promise<void> => {
  const resolver = (resolve) => setTimeout(() => resolve(), seconds * 1000);
  return new Promise(resolver);
};

const logChunkAsConflictive = (processedResult, onLogUpdate, chunkIndex?) => {
  onLogUpdate({
    text: `CONFLICTIVE Chunk ${isNaN(chunkIndex)? ' ' : chunkIndex }retried to max attempts`,
    danger: true,
  });
  const statuses = processedResult.requestMeta.statusCodes.join(",");
  onLogUpdate({
    text: `Status history: [${statuses}] Final status: ${processedResult.requestMeta.finalStatusCode}`,
    danger: true,
  });
};

/**
 * Throws
 */
const createChunkWithMeta = (processedResult, jsonDecode = true) => {
  const content = jsonDecode ? JSON.parse(processedResult.content) : processedResult.content;
  return (content as Paragraph[]).map(
    (paragraph) => {
      return {
        ...paragraph,
        meta: { retries: processedResult.meta?.retries },
      };
    }
  );
};

export const processChunk = async (
  chunk: Chunk | string,
  openAI,
  prompts: string[],
  settings: Settings,
  retries: number,
  meta = {},
  requestMeta: RequestMeta = {
    finalStatusCode: 0,
    finalStatusMessage: "",
    statusCodes: [],
  },
  lastTransformedChunk?: Chunk | string
): Promise<ProcessedChunk> => {
  if (retries <= 0) {
    // return processed result OR raw input.
    return {
      content: lastTransformedChunk || (settings.mode === 'tag-matching' ? '' : chunk),
      meta: { ...meta, retries: 0 },
      requestMeta,
    };
  }

  const respectJSONstructure = settings.preserveParagraphs && settings.mode !== 'immersive-sound' && settings.mode !== 'tag-matching';

  if (!respectJSONstructure && hasOnlyPlaceholder(chunk as string)) {
    console.log('this chunk only has a placeholder, bypassing openAI', chunk);
    return {
      content: chunk,
      meta: { ...meta, retries: retries },
      requestMeta,
    };
  }


  let openAIResult: ProcessedChunk | null = null;
  try {
    openAIResult = await openAI.process(
      settings.model,
      prompts,
      chunk,
      respectJSONstructure,
    );
  } catch (err) {
    // Request errored out
    const errData = err.toJSON ? err.toJSON() : err;
    const { status, message } =
      typeof errData === "string" ? { status: err, message: err } : errData;

    const newRequestMeta = {
      ...requestMeta,
      statusCodes: [...requestMeta.statusCodes, status],
      // Keep the old ones if they exist.
      finalStatusCode: requestMeta.finalStatusCode || status,
      finalStatusMessage: requestMeta.finalStatusMessage || message,
    };

    await wait(settings.waitTime);
    return await processChunk(
      chunk,
      openAI,
      prompts,
      settings,
      retries - 1,
      meta,
      newRequestMeta,
      lastTransformedChunk
    );
  }

  const processedChunkObject = openAIResult;

  // Request errored out, retry.
  if (!processedChunkObject) {
    // is this point ever reached?
    console.log("Empty Processed chunk, retrying", openAIResult);

    await wait(settings.waitTime);
    return await processChunk(
      chunk,
      openAI,
      prompts,
      settings,
      retries - 1,
      meta,
      requestMeta,
      lastTransformedChunk
    );
  }

  // IF Result too far from MaxDifference, retry
  let a: string | null = null;
  let b: string | null = null;
  if (respectJSONstructure) {
    // JSON-respectful mode
    const reducer = (allText, p) => allText + "" + p.text;
    a = (chunk as Chunk).reduce(reducer, "");
    b = (processedChunkObject.content as Paragraph[]).reduce(reducer, "");
  } else {
    //JSON Free mode
    a = chunk as string;
    b = processedChunkObject.content as string;
  }
  const diff = simpleDiffPercentageByCharacters(a, b);

  if (settings.mode !== 'tag-matching' && (diff > settings.maxDiff || diff < settings.minDiff)) {
    // outside limits
    if (retries - 1 <= 0) {
      // outside limits AND exhausted retries
      const newRequestMeta = { ...processedChunkObject.requestMeta };
      newRequestMeta.finalStatusMessage = "successful but outside limits";
      return {
        ...processedChunkObject,
        meta: { ...meta, retries: 0 },
        requestMeta: newRequestMeta,
      };
    }

    console.log("chunk is outside diff limits, reprocessing");

    await wait(settings.waitTime);
    return await processChunk(
      chunk,
      openAI,
      prompts,
      settings,
      retries - 1,
      meta,
      requestMeta,
      processedChunkObject.content
    );
  }

  const newRequestMeta = {
    ...processedChunkObject.requestMeta,
    statusCodes: [
      // whatever was in requestMeta.statusCodes and the last returned status code.
      ...requestMeta.statusCodes,
      processedChunkObject.requestMeta.statusCodes[0],
    ],
  };

  if (settings.mode === 'deep-editing') {
    processedChunkObject.content = fixResponse(chunk, processedChunkObject.content, respectJSONstructure);
  }

  return {
    ...processedChunkObject,
    meta: { ...meta, retries },
    requestMeta: newRequestMeta,
  };
};

const fixResponse = (originalChunk: Paragraph[] | string, aiChunk: Paragraph[] | string, respectJSONStructure: boolean): Paragraph[] | string  => {
  if (respectJSONStructure) {
    return (aiChunk as Paragraph[]).map((p) => {
        const originalText = (originalChunk as Paragraph[]).find((o) => o.id === p.id)!.text;
        return {
          ...p,
          text: fixSpecialSigns(originalText, p.text),
        };
      }
    );
  } else {
    return fixSpecialSigns(originalChunk as string, aiChunk as string);
  }
}

const fixSpecialSigns = (originalText: string, aiText: string) => {
  const cleanOriginalText = originalText.replace(/~/g, '"').replace(/\^/g, '"');
  const diffPar = Math.abs(aiText.length - cleanOriginalText.length);
  const quotationMarksAI = (aiText.match(/["″“”]/g) || []).length;
  const quotationMarksNonAI = (cleanOriginalText.match(/["″“”]/g) || []).length;
  const quotationDiff = Math.abs(quotationMarksAI - quotationMarksNonAI);
  const isEqual = cleanOriginalText === aiText.slice(0, -1);

  if (diffPar < 5 && quotationDiff > 0 && isEqual) {
    return aiText.slice(0, -1);
  }

  return aiText;
};

const processEpisodeForImmersiveSound = async (originalEpisodeContent, openAI, processWorkerOptions, onLogUpdate): Promise<[any, any]> => {

  const {
    settings,
    prompt,
    beforePrompt,
  } = processWorkerOptions;

  onLogUpdate({ text: 'Processing Episode for Immersive Sound Tagging', busy: true });

  /**
   *
   * This is useful to count the amount of tokens that will
   * be placed in the final request, so that we create chunks
   * that fit into a request.
   */
  const fullPromptForSizing = [prompt,beforePrompt].join(' ') ;

  // 7% of tokens for immersive-sound.
  const padding = settings.maxTokens * 0.07;

  onLogUpdate({ text: `Dividing in chunks` });

  let attemptedChunks: Chunk[] = [];
  try {
    attemptedChunks = contentToMinimalProcessableChunksWithIDs(
      originalEpisodeContent,
      fullPromptForSizing,
      settings.maxTokens,
      false, // TODO: refactor contentToMinimalProcessableChunksWithIDs, as this is always false
      padding
    );
  } catch (err) {
    console.log('contentToMinimalProcessableChunksWithIDs errored out');
    return [null, null];
  }

  onLogUpdate({
    text: `Sending ${attemptedChunks.length} chunks to GPT API`,
    busy: true,
  });

  // We store here what we are receiving from GPT.
  const processedChunks: Chunk[] = [];

  for (const c in attemptedChunks) {
    const chunk = attemptedChunks[c];
    const retries = settings.attempts;


    const jsonChunk = JSON.stringify(chunk);

    const prompts = [
      prompt,
      `List of tags: ${beforePrompt}`,
      'Your last task is to, remove the \"text\" field from every element inside this JSON structure'
    ];
    const processedResult = await processChunk(
      jsonChunk,
      openAI,
      prompts,
      settings,
      retries
    );

    // TODO: map HTTP statuses here!
    if (processedResult.meta?.retries === 0) {
      logChunkAsConflictive(processedResult, onLogUpdate, c);
    }

    onLogUpdate({ text: `Chunk ${+c + 1} of ${attemptedChunks.length} processed` });

    let processedChunk: null | Paragraph[] = null;

    try {
      processedChunk = createChunkWithMeta(processedResult);
    } catch(err) {
      onLogUpdate({ text: `Response from GPT is not valid JSON`, danger: true,});
      console.log('INVALID JSON from GPT');
      return [null, null];
    }

    processedChunks.push(processedChunk);

  } // for c in chunks

  // In this case we are supposed to get a valid JSON response.
  // @ts-ignore
  const processedParagraphs = restorePropertyFrom( attemptedChunks.flat(), processedChunks.flat(), 'text');
  const newEpisodeContent = paragraphsToContent(
    processedParagraphs,
    originalEpisodeContent,
    processWorkerOptions.immersiveTags
  );

  return [newEpisodeContent, processedParagraphs];
};

const processEpisodeForDeepEditing = async (originalEpisodeContent, openAI, processWorkerOptions, onLogUpdate): Promise<[any, any]> => {
  const {
    settings,
    prompt,
  } = processWorkerOptions;

  const firstLog = settings.preserveParagraphs ? 'Processing Episode for ProofReading (preserving paragraphs)' : 'Processing Episode for Deep Editing (new paragraphs)';
  onLogUpdate({ text: firstLog, busy: true });

  // 10 tokens padding for for deep editing.
  const padding = 20;

  let chunks: Chunk[] = [];
  let skippedByPlaceholder: any = null;
  let skippedButAtStart: any = null;
  let metadataForNextParagraph: any = null;

  onLogUpdate({ text: `Dividing in chunks` });

  if (!settings.preserveParagraphs) {
    // true deep editing.
    // Paragraph free mode
    const [resultingChunks, skippedSectionsByPlaceholder, metadataForNextParagraphByPlaceholder, skippedSectionsAtStart] = contentToTextOnlyProcessableChunksWithPlaceholders(
      originalEpisodeContent,
      prompt,
      settings.maxTokens,
      padding
    );
    chunks = resultingChunks;
    skippedByPlaceholder = skippedSectionsByPlaceholder;
    skippedButAtStart = skippedSectionsAtStart;
    metadataForNextParagraph = metadataForNextParagraphByPlaceholder;
  } else {
    // proofreading
    const resultingChunks = contentToMinimalProcessableChunksWithIDs(
      originalEpisodeContent,
      prompt,
      settings.maxTokens,
      true,
      padding,
      ['prose']
    );
    chunks = resultingChunks;
  }

  onLogUpdate({
    text: `Sending ${chunks.length} chunks to GPT API`,
    busy: true,
  });

  // send chunks as requests for GPT
  const processedChunks: Chunk[] | string[] = [];
  for (const c in chunks) {
    const chunk = chunks[c];
    const retries = settings.attempts;

    let formattedChunk = settings.preserveParagraphs ? chunk : chunk.join("\n\n");

    let povElement = '';
    if (!settings.preserveParagraphs) {
      const povMatch = (formattedChunk as string).match(/\[POV[^\n]*\n\n/);
      povElement = povMatch ? povMatch[0] : '';
      formattedChunk = (formattedChunk as string).replace(povElement, '');
    }

    const processedResult = await processChunk(formattedChunk, openAI, [prompt], settings, retries);

    if (processedResult.meta?.retries === 0) {
      logChunkAsConflictive(processedResult, onLogUpdate, c);
    }

    onLogUpdate({ text: `Chunk ${+c + 1} of ${chunks.length} processed` });

    if (!settings.preserveParagraphs) {
      const processedContent = povElement + processedResult.content;
      (processedChunks as string[]).push(processedContent);
    } else {
      const processedChunk = createChunkWithMeta(processedResult, false);
      (processedChunks as Chunk[]).push(processedChunk);
    }
  } // for c in chunks

  let newEpisodeContent: any = null;

  if (!settings.preserveParagraphs) {
    // in this case, processedChunks is just an array of strings.
    // each string is the size of a processable chunk
    // we have to divide that into paragraphs for Studio
    newEpisodeContent = contentFromStringChunks(processedChunks as string[], skippedByPlaceholder, metadataForNextParagraph, skippedButAtStart);
  } else {
    // In this case we are supposed to get a valid JSON response.
    // @ts-ignore
    const processedParagraphs = processedChunks.flat();
    newEpisodeContent = paragraphsToContent(
      processedParagraphs,
      originalEpisodeContent,
      null
    );
  }
 
  return newEpisodeContent;
}

/**
 * This method returns a promise that evaluates as an array of objects in the
 * following shape:
 *
 * [{
 *    category,
 *    tags: Array.from(tagsSet)
 *  },
 *  ...
 * ]
 *
 * Each member of this array will contain a certain category in the category field
 * and an array of tags that were found appropriate for that category.
 *
 */
const processEpisodeForTagMatching = async (originalEpisodeContent, openAI, processWorkerOptions, onLogUpdate): Promise<TagMatchingResult> => {

  const {
    settings,
    prompt,
    tagMatchingTagMap,
  } = processWorkerOptions;

  onLogUpdate({ text: 'Processing Episode for Tag Matching', busy: true });

  const padding = 20;

  const categories = Object.keys(tagMatchingTagMap);
  onLogUpdate({ text: `Starting the process for ${categories.length} categories` });

  // We will repeat the process for as many categories (headers) we find in tagMatchingTagMap
  const categoryPromises = categories.map(async category => {
    const tags = tagMatchingTagMap[category];
    const fullPromptForSizing = [prompt,...tags].join(' ') ;

    let attemptedChunks: Chunk[] = [];

    try {
      const [resultingChunks] = contentToTextOnlyProcessableChunksWithPlaceholders(
        originalEpisodeContent,
        fullPromptForSizing,
        settings.maxTokens,
        padding,
        settings.mode
      );
      attemptedChunks = resultingChunks;
    } catch (err) {
      return { category, tags: [] };
    }

    onLogUpdate({
      text: `Sending ${attemptedChunks.length} chunks to GPT API for category ${category}`,
      busy: true,
    });

    const processedChunks: string[] = [];

    for (const c in attemptedChunks) {
      const textChunk = attemptedChunks[c].join('\n\n');
      const retries = settings.attempts;

      const prompts = [
        prompt,
        `List of tags: ${tags.join(', ')}`
      ];
      const processedResult = await processChunk(
        textChunk,
        openAI,
        prompts,
        settings,
        retries
      );

      // TODO: map HTTP statuses here!
      if (processedResult.meta?.retries === 0) {
        logChunkAsConflictive(processedResult, onLogUpdate, c);
      }

      onLogUpdate({ text: `Chunk ${+c + 1} of ${attemptedChunks.length} processed for category ${category}` });

      processedChunks.push(processedResult.content as string);
    } // for c in chunks

    // At this point, processedChunks is an array of strings and each string
    // is of this form:  tagA, tagB, tagC
    // Since we're still in the same category, we most likely have duplicated tags here.
    // We also get rid of the blanks (that we get when GPT finds no matching tags)
    const possibleDuplicatedTagsList = processedChunks
      .join(', ')
      .split(',')
      .map(tag => tag.trim())
      .filter(tag => !!tag);
    const tagsSet = new Set(possibleDuplicatedTagsList);

    return {
      category,
      tags: Array.from(tagsSet)
    };
  });

  return await Promise.all(categoryPromises);
};

export const processEpisode = async (
  api,
  openAI,
  episode,
  processWorkerOptions: ProcessWorkerOptions,
  onLogUpdate: Function,
): Promise<[any, any]|[any, any, any]> => {

  const {
    settings
  } = processWorkerOptions;

  // get the content
  const originalEpisodeContent = episode.content;

  if (settings.mode === 'deep-editing') {
    const processedEpisodeContent = await processEpisodeForDeepEditing(originalEpisodeContent, openAI, processWorkerOptions, onLogUpdate);
    return [originalEpisodeContent, processedEpisodeContent];
  }

  if (settings.mode === 'immersive-sound') {
    const [processedEpisodeContent, taggedParagraphs] = await processEpisodeForImmersiveSound(originalEpisodeContent, openAI, processWorkerOptions, onLogUpdate);
    return [originalEpisodeContent, processedEpisodeContent, taggedParagraphs];
  }

  if (settings.mode === 'tag-matching') {
    const processedEpisodeContent = await processEpisodeForTagMatching(originalEpisodeContent, openAI, processWorkerOptions, onLogUpdate);
    return [originalEpisodeContent, processedEpisodeContent];
  }

  throw new Error('unknown-mode');
};

export const processEpisodeAndUploadChanges = async (
  api,
  openAI,
  processWorkerOptions: ProcessWorkerOptions,
  onLogUpdate: Function,
  onPostProcessNeeded: Function,
) => {

  const { storyId, abIndex, episodeId } = processWorkerOptions;

  // Download episode from Studio
  const episode = await loadEpisode(api, storyId, episodeId);

  // Main process
  const [episodeWithContent, newEpisodeContent] = await processEpisode(  api, openAI, episode, processWorkerOptions, onLogUpdate);

  if(!newEpisodeContent) {
    const text = `ERROR: at least one paragraph in the episode either exceeds maxTokens or resulted in an error. The entire episode will be ignored (original content will be places in the new branch)`;
    onLogUpdate({ text, danger: true });
    return await createEpisode(api, storyId, {
      ...episode,
      ab_index: abIndex,
    });
  }

  // Tag matching doesn't create new episodes.
  if (processWorkerOptions.settings.mode === 'tag-matching') {
    onPostProcessNeeded(episodeId, newEpisodeContent);
    return;
  }

  // Upload to studio
  const newEpisode = {
    ...episode,
    content: newEpisodeContent,
    ab_index: abIndex /* AB index*/,
  };

  await createEpisode(api, storyId, newEpisode);
}
