import { BusyTracker, Episode, Settings } from "../types";

type ActionButtonsProps = {
  currentEpisodes: Episode[];
  sampleEpisode: Episode;
  busy: BusyTracker;
  settings: Settings,
  onStartProcessClicked: () => {};
  onTestClicked: () => {};
  onEpisodeSelected: (episodeId: number) => void;
};

export const ActionButtons = (props: ActionButtonsProps) => {
  const {
    currentEpisodes,
    sampleEpisode,
    busy,
    settings,
    onStartProcessClicked,
    onTestClicked,
    onEpisodeSelected,
  } = props;

  return (
    <div className="grid">
      {settings.mode !== 'summarize' && (<div>
        {/* --- Test your prompt --- */}
        <select
          className="sample-select"
          onChange={(e) => onEpisodeSelected(e.target.value)}
          required
        >
          <option value="" selected>
            Select a chapter (first book)
          </option>
          {currentEpisodes
            .sort((a, b) => a.number - b.number)
            .map((episode) => {
              return (
                <option value={episode.id} key={episode.id}>
                  {episode.number} - {episode.title}
                </option>
              );
            })}
        </select>
        {!busy.sample && (
          <button
            onClick={onTestClicked}
            className="outline sample-button"
            disabled={!sampleEpisode}
          >
            Test with selected episode ($)
          </button>
        )}
        {busy.sample && (
          <button className="outline" aria-busy="true">
            Testing...
          </button>
        )}
      </div>)}
      <div>
        {/* --- Send it all to GPT, this costs money --- */}
        <small className="process-label">
          Sends all of the content in each book to GPT's API and creates new
          branches with the resulting output in Studio
        </small>
        {!busy.mainProcess && (
          <button onClick={onStartProcessClicked}>Process All ($$$$)</button>
        )}
        {busy.mainProcess && <button aria-busy="true">Processing...</button>}
      </div>
    </div>
  );
};
