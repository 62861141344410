/**
 * Why?
 *
 * When using them inside setTimeOut (like throttling and debouncing) state
 * variables don't hold its value consistently. So refs come handy.
 * This hook  helps you set both a ref and a state variable in a single shot:
 *
 *  const [myVar, myVarRef, setMyVar] = useRefState<MyVarType>(INITIAL_VALUE);Z
 *
 * Credits: Shawn Martin-Truesdell
 */
import { useState, useRef, type MutableRefObject } from "react";

type CalcState<T> = (arg0: T) => T;
export type RefStateSetter<T> = (
  arg0: T
) => any | ((arg0: CalcState<T>) => any);

export function useRefState<T>(
  initialValue: T
): [T, MutableRefObject<T>, RefStateSetter<T>] {
  const [state, setState] = useState<T>(initialValue);
  const ref = useRef<T>(initialValue);

  const setRefAndState = (nextOrNextFn: T | CalcState<T>): void => {
    setState((last: T) => {
      const next =
        typeof nextOrNextFn === "function"
          ? (nextOrNextFn as CalcState<T>)(last)
          : (nextOrNextFn as T);
      ref.current = next;
      return next;
    });
  };

  return [state, ref, setRefAndState];
}
