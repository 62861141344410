import { slugs_to_ids } from "../temp-slugs";
import type { Episode, Story, Settings } from "../types";

const ID_PATTERN = /^[0-9]+$/;

const load = async (
  bei,
  endpoint: string,
  headers = {}
): Promise<Story | null> => {
  let res;
  try {
    res = await bei.get(endpoint, { withCredentials: true, headers });
  } catch (e) {
    console.error("loadStory error", e.response ? e.response.status : e);
    return null;
  }

  const { story } = res.data;
  return story;
};

export const loadStory = (bei, storyId: number) =>
  load(bei, `api/v1/stories/${storyId}`);

export const loadStoryBySlug = (bei, storySlug: string, clientToken) =>
  load(bei, `external/v1/stories/${storySlug}`, {
    "Client-Token": "S0wwPyf8gMdpGSpeYfHi",
  });

/**
 * We currently have 2 endpoints in GStudio:
 *
 * api/v1/stories/id
 * Which only accepts story ids, gives us all episodes and has no idea which is the main branch
 *
 * external/v1/stories/slug
 * Which only accepts story slugs and does not return the story ID
 *
 * So for now, we rely on the ugly hack of this /temp-slugs.js file
 *
 */
export const loadStoriesBySlugOrId = async (
  api,
  slugs: string[],
  settings: Settings
): Promise<Story[]> => {

  const findBranch = (story) => {
    const matcher = settings.inputBranch === 'main' || !settings.inputBranch ? 
      (branch) => !!branch.main : // Main branch
      (branch) => `${branch.index}` === `${settings.inputBranch}`; // exact branch ID

    return story.branches.find(matcher);
  }

  // Load stories meta
  const stories: Story[] = [];
  let failedStories = false;
  for (const slug of slugs) {

    // Ugly hack here
    const storyID = ID_PATTERN.test(slug) ? +slug : slugs_to_ids[slug];
    const story = await loadStory(api, storyID);

    if(!story) {
      continue;
    }

    const mainBranch = findBranch(story);

    if(!mainBranch){
      failedStories = true;
      continue;
    }

    const mainBranchEpisodes = story.episodes
      ?.filter((episode) => episode.ab_index === mainBranch.index)
      .map(
        (episode) =>
          ({
            id: episode.id,
            ab_index: episode.ab_index,
            title: episode.title,
            number: episode.number,
          } as Episode)
      );

    if(!mainBranchEpisodes){
      failedStories = true;
      continue;
    }

    /**
     * Keeping only what we need since some books have an quite
     * large amount of chapters and metadata which saturates our
     * 5MB of data (localStorage is limited to 5MB in Chrome).
     */
    stories.push({
      id: story.id,
      name: story.name,
      mainBranch,
      mainBranchEpisodes,
    });
  }

  if (failedStories) {
    alert(`Some stories did not load because the selected branch (ID: ${settings.inputBranch}) was not found`);
  }

  return stories;
};
