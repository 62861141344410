"use strict";
import React, { useState, useEffect, useRef } from "react";
import { useRefState } from "./lib/useRefState";
import { loadStoriesBySlugOrId } from "./lib/storyLoader";
import { loadEpisode } from "./lib/episodeLoader";
import {
  contentToParagraphs,
  tagsToText,
} from "./lib/contentPreprocessor";
import {
  processBook,
  storiesProcesses,
  endStatusses,
} from "./bookProcess";
import { processEpisode } from "./episodeProcess";
import {
  storiesStore,
  deepEditingPromptStore,
  settingsStore,
  credentialsStore,
  immersivePromptStore,
  summarizePromptStore,
  tagMatchingPromptStore,
  tagsStore,
  tags2Store,
} from "./stores";
import { logIn } from "./login";
import openAI from "./openAI";
import { DEFAULT_SETTINGS, DEFAULT_PROMPT, DEFAULT_IMMERSIVE_PROMPT, DEFAULT_TAG_MATCHING_PROMPT } from "./config";
import type {
  Credentials,
  Episode,
  ProcessMap,
  StoryProcess,
  BusyTracker,
  Settings,
  Mode,
} from "./types";
import { LoginForm } from "./components/loginForm";
import { StoriesInputForm } from "./components/StoriesInputForm";
import { ContentViewer } from "./components/contentViewer";
import { SettingsForm } from "./components/settingsForm";
import { PromptForm } from "./components/promptForm";
import { StoriesView } from "./components/storiesView";
import { ProcessLoader } from "./components/ProcessLoader";
import { ActionButtons } from "./components/ActionButtons";
import { ModeSelector } from "./components/ModeSelector";
import { BranchSelector } from "./components/BranchSelector";
import { ModelIndicator } from './components/ModelIndicator';
import { MultithreadProcessTracker } from "./components/MultithreadProcessTracker";

import "./app.css";

declare global {
  interface Window {
    api: any;
  }
}

window.api = window.api || {};

export const App = () => {
  const bookSlugs = useRef([]);
  const importInput = useRef();
  const [currentSettings, setCurrentSettings] =
    useState<Settings>(DEFAULT_SETTINGS);
  const [episodeSample, setEpisodeSample] = useState([]);
  const [processedEpisodeSample, setProcessedEpisodeSample] = useState([]);
  const [multiThreadingProcess, setMultiThreadingProcess] = useState({});
  const [loginError, setLoginError] = useState("");
  const [sampleEpisode, setSampleEpisode] = useState();
  const [tags, setTags] = useState([]);
  const [tags2, setTags2] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentAPIKey, setCurrentAPIKey] = useState("");
  const [deepEditingPrompt, setDeepEditingPrompt] = useState(DEFAULT_PROMPT);
  const [summarizePrompt, setSummarizePrompt] = useState(DEFAULT_PROMPT);
  const [immersivePrompt, setImmersivePrompt] = useState(DEFAULT_IMMERSIVE_PROMPT);
  const [tagMatchingPrompt, setTagMatchingPrompt] = useState(DEFAULT_TAG_MATCHING_PROMPT);
  const [loadedStories, setLoadedStories] = useState([]);
  const [processLoadingMessages, setProcessLoadingMessages] = useState([]);

  const [busy, busyRef, setBusy] = useRefState<BusyTracker>({
    stories: false,
    sample: false,
    mainProcess: false,
  });

  // Logger
  const log = {
    add: (message, markDoneBefore = false) => {
      if (markDoneBefore) {
        setProcessLoadingMessages((msgs) =>
          [...msgs].map((msg) => ({ ...msg, busy: false }))
        );
      }
      setProcessLoadingMessages((msgs) => [...msgs, message]);
    },
    clear: () => {
      setProcessLoadingMessages([]);
    },
  };

  useEffect(() => {
    const credentials = credentialsStore.get();
    if (!credentials) {
      setLoggedIn(false);
      return;
    }
    startLogin(credentials.email, credentials.password, credentials.apiKey);
    const storedSettings = settingsStore.get();

    if (!storedSettings) {
      // get from inputs and set
      onChangedSettings(currentSettings);
    }

    Object.keys(DEFAULT_SETTINGS).forEach((key) => {
      if (!currentSettings.hasOwnProperty(key)) {
        const newSettings = {
          ...currentSettings,
          [key]: DEFAULT_SETTINGS[key],
        };
        onChangedSettings(newSettings);
      }
    });

    openAI.init(credentials, storedSettings);
    restoreState();

    const allBooksFinished = (storiesProcesses: ProcessMap) => {
      const pendingBook = Object.keys(storiesProcesses).find((spk) => {
        const storyProcess = storiesProcesses[spk] as StoryProcess;
        if (
          storyProcess.episodes.length < storyProcess.originalNumberOfEpisodes
        ) {
          return true;
        }

        const pendingEpisode = storyProcess.episodes.find(
          (ep) => !endStatusses.includes(ep.status)
        );
        if (pendingEpisode) {
          return true;
        }

        return false;
      });

      return !pendingBook;
    };

    // Multithread UI sync
    const interval = setInterval(() => {
      setMultiThreadingProcess({ ...storiesProcesses });
      if (allBooksFinished(storiesProcesses)) {
        // busy needs to be a reference here
        if (busyRef.current.mainProcess) {
          setBusy({ ...busyRef.current, mainProcess: false });
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const restoreState = () => {
    deepEditingPromptStore.restore(setDeepEditingPrompt);
    immersivePromptStore.restore(setImmersivePrompt);
    summarizePromptStore.restore(setSummarizePrompt);
    tagMatchingPromptStore.restore(setTagMatchingPrompt);
    storiesStore.restore(setLoadedStories);
    settingsStore.restore(setCurrentSettings);
    tagsStore.restore(setTags);
    tags2Store.restore(setTags2);
  };

  const onChangedSettings = (newSettings) => {
    setCurrentSettings(newSettings);
    openAI.updateSettings(newSettings);
    settingsStore.set(newSettings);
  };

  const onTagsChanged = (tags, tags2) => {
    if(tags) {
      setTags(tags);
      tagsStore.set(tags);
    }
    if (tags2) {
      setTags2(tags2);
      tags2Store.set(tags2);
    }
  };

  const onPromptChanged = (prompt, mode: Mode) => {
    switch (mode) {
      case 'deep-editing':
        deepEditingPromptStore.set(prompt);
        setDeepEditingPrompt(prompt);
      break;
      case 'immersive-sound':
        immersivePromptStore.set(prompt);
        setImmersivePrompt(prompt);
      break;
      case 'summarize':
        summarizePromptStore.set(prompt);
        setSummarizePrompt(prompt)
      break;
      case 'tag-matching':
        tagMatchingPromptStore.set(prompt);
        setTagMatchingPrompt(prompt);
      break;
    }
  };

  const  download = (content, filename, contentType) => {
    if(!contentType) {
      contentType = 'application/octet-stream';
    }
    const a = document.createElement('a');
    const blob = new Blob([content], {'type':contentType});
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  };

  const exportSettings = () => {
    const exportable = {
      settings: settingsStore.get(),
      stories: storiesStore.get(),
      deepEditingPrompt: deepEditingPromptStore.get(),
      summarizePrompt: summarizePromptStore.get(),
      immersivePrompt: immersivePromptStore.get(),
      tagMatchingPrompt: tagMatchingPromptStore.get(),
      tags: tagsStore.get(),
      tags2: tags2Store.get(),
    };
    const exportableStr = JSON.stringify(exportable);
    download(exportableStr, 'settings.json', "text/json");
  };

  const importSettings = (file: File) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      const newValues = JSON.parse(e.target.result);
      settingsStore.set(newValues.settings);
      storiesStore.set(newValues.stories);
      deepEditingPromptStore.set(newValues.deepEditingPrompt);
      summarizePromptStore.set(newValues.summarizePrompt);
      immersivePromptStore.set(newValues.immersivePrompt);
      tagMatchingPromptStore.set(newValues.tagMatchingPrompt);
      tagsStore.set(newValues.tags);
      tags2Store.set(newValues.tags2);

      restoreState();
    };
    reader.readAsText(file);
  };

  const resetCredentials = () => {
    credentialsStore.set(null);
    setCurrentEmail("");
    setCurrentAPIKey("");
    setLoggedIn(false);
  };

  const startLogin = async (email, password, apiKey) => {
    let credentials: any = null;
    try {
      credentials = await logIn(email, password, apiKey);
    } catch (error) {
      setLoginError(error.message ? error.message : error);
      return;
    }
    const storedSettings = settingsStore.get();
    openAI.init(credentials as Credentials, storedSettings);

    setCurrentEmail(email);
    setCurrentAPIKey(apiKey);
    setLoggedIn(true);
  };

  const loadStories = async () => {
    clearOutput();
    setBusy({ ...busy, stories: true });
    const stories = await loadStoriesBySlugOrId(window.api, bookSlugs.current, currentSettings);
    storiesStore.set(stories);
    setLoadedStories(stories);
    setBusy({ ...busy, stories: false });
  };

  const clearOutput = () => {
    log.clear();
    setEpisodeSample([]);
    setProcessedEpisodeSample([]);
  };

  const onTestClicked = async () => {
    if (!sampleEpisode) {
      return;
    }

    clearOutput();
    setBusy({ ...busy, sample: true });

    const episodeId = sampleEpisode.id;
    // Hardcoded for first story
    const storyId = loadedStories[0].id;

    const beforePrompt = currentSettings.mode === 'tag-matching' ? '' : tagsToText(tags);
    const immersiveTags = currentSettings.mode === 'tag-matching'
      ? null
      : tags.reduce((map, tag) => {
        map[tag.name] = tag.filename;
        return map;
      }, {});

    // Download episode from Studio
    const episode = await loadEpisode(window.api, storyId, episodeId);

    const prompts = {
      'deep-editing': deepEditingPrompt,
      'immersive-sound': immersivePrompt,
      'summarize': summarizePrompt,
      'tag-matching': tagMatchingPrompt,
    };
    const prompt = prompts[currentSettings.mode];

    const [originalEpisodeContent, newEpisodeContent, processedParagraphs] =
      await processEpisode(
        window.api,
        openAI,
        episode,
        {
          episodeId,
          storyId,
          abIndex: 99, // doesn't matter, not creating a branch.
          settings: currentSettings,
          prompt,
          beforePrompt,
          immersiveTags,
          tagMatchingTagMap: tags2
        },
        log.add
      );

    if (!newEpisodeContent) {
      setBusy({ ...busy, sample: false });
      log.add({ text: `Test FAILED` }, true);
      return;
    }

    const presentableParagraphs = currentSettings.mode === 'tag-matching' ? newEpisodeContent : processedParagraphs || contentToParagraphs(newEpisodeContent);

    const originalParagraphs = contentToParagraphs(originalEpisodeContent);
    setEpisodeSample(originalParagraphs);
    setProcessedEpisodeSample(presentableParagraphs);

    setBusy({ ...busy, sample: false });
    log.add({ text: `Test complete` }, true);
  };

  const onEpisodeSelected = (episodeId: number) => {
    if (
      !loadedStories[0]?.mainBranchEpisodes ||
      !loadedStories[0]?.mainBranchEpisodes.length
    ) {
      return;
    }
    const selectedEpisode = loadedStories[0]?.mainBranchEpisodes.find(
      (ep: Episode) => ep.id == episodeId
    );
    if (selectedEpisode) {
      setSampleEpisode({ ...selectedEpisode });
    }
  };

  /**
   * THE process
   */
  const onStartProcessClicked = async () => {
    setBusy({ ...busy, mainProcess: true });
    log.clear();

    const prompts = {
      'deep-editing': deepEditingPrompt,
      'immersive-sound': immersivePrompt,
      'summarize': summarizePrompt,
      'tag-matching': tagMatchingPrompt,
    };
    const prompt = prompts[currentSettings.mode];

    const beforePrompt = currentSettings.mode === 'tag-matching' ? '' : tagsToText(tags);
    const immersiveTags = currentSettings.mode === 'tag-matching'
      ? null
      : tags.reduce((map, tag) => {
        map[tag.name] = tag.filename;
        return map;
      }, {});

    for (const story of loadedStories) {
      await processBook(
        window.api,
        story,
        prompt,
        beforePrompt,
        immersiveTags,
        tags2,
        currentSettings,
        log.add
      );
      log.add({ text: `Processed story: ${story.name} (ID: ${story.id})` });
    }

    log.add({ text: "Process started for all books" });
  };

  const prompts = {
    'deep-editing': deepEditingPrompt,
    'immersive-sound': immersivePrompt,
    'summarize': summarizePrompt,
    'tag-matching': tagMatchingPrompt
  };

  return (
    <div>
      {!loggedIn && <LoginForm onSubmit={startLogin} error={loginError} />}
      <div>

        <div className="grid">
          <div>
            <h3 className="title">AI Editing Tool</h3>
            <small className="very-small" data-tooltip="Latest updates: tag-matching mode for multiple categories">Last updated: Jul 19 10:23am CET</small>
          </div>
          <div>
            {loggedIn && (
              <div className="text-right"> ⚷ {" "}
                <small>{currentEmail}</small> &nbsp;
                <small>
                  ***{currentAPIKey.substring(currentAPIKey.length - 5,currentAPIKey.length)}
                </small>{" "}
                &nbsp;
                <a onClick={resetCredentials}>
                  <small>Log out</small>
                </a>
              </div>
            )}
            <div className="text-right very-small" style={{marginTop: '20px'}}>
              <a onClick={exportSettings} style={{marginRight: '10px'}}>Export settings</a> |{" "}
              <a onClick={() => importInput.current?.click() } style={{marginLeft: '10px'}}>Import settings</a>
              <input type="file" ref={importInput} style={{width: 0, height: 0}} accept=".json" onChange={(e) => importSettings(e.target.files[0])}/>
            </div>
          </div>
        </div>
        <br />


        {/* --- Tell me which stories you want --- */}
        <hr />
        <br />
        <StoriesInputForm
          onBooksProvided={(slugs) => (bookSlugs.current = slugs)}
        />

        {/* --- Which branch? --- */}
        <BranchSelector settings={currentSettings} onChangedSettings={onChangedSettings}/>
        <br />

        <div>
          {!busy.stories && (
            <button className="secondary outline" onClick={loadStories} disabled={currentSettings.inputBranch === '' || !bookSlugs.current?.length}>
              Load Stories
            </button>
          )}
          {busy.stories && (
            <button className="secondary outline" aria-busy="true">
              Loading Stories...
            </button>
          )}
        </div>

        {!!loadedStories.length && (
          <>
            {/* --- See loaded Stories --- */}
            <StoriesView stories={loadedStories} />

            {/* --- Mode --- */}
            <ModeSelector settings={currentSettings} onChangedSettings={onChangedSettings}/>

            {/* --- Provide settings for GPT --- */}
            <SettingsForm
              settings={currentSettings}
              prompts={prompts}
              onChangedSettings={onChangedSettings}
            />

            {/* --- Check your prompt --- */}
            <PromptForm
              prompts={prompts}
              settings={currentSettings}
              tags={tags}
              tags2={tags2}
              onTagsChanged={onTagsChanged}
              onPromptChange={onPromptChanged}
            />
            <br />

            {/* --- Quick verification --- */}
            <ModelIndicator settings={currentSettings}/>

            {/* --- Test or Execute on all books --- */}
            <ActionButtons
              currentEpisodes={loadedStories[0].mainBranchEpisodes}
              sampleEpisode={sampleEpisode}
              busy={busy}
              settings={currentSettings}
              onStartProcessClicked={onStartProcessClicked}
              onTestClicked={onTestClicked}
              onEpisodeSelected={onEpisodeSelected}
            />

            {/* --- See the status for all books --- */}
            {
              currentSettings.mode !== 'summarize' &&
              !!multiThreadingProcess &&
              !!Object.keys(multiThreadingProcess).length && (
                <MultithreadProcessTracker processMap={multiThreadingProcess} />
              )}

            {/* --- Log --- */}
            {!!processLoadingMessages.length && (
              <ProcessLoader messages={processLoadingMessages} />
            )}

            {/* --- Diff --- */}
            {!!episodeSample.length && (
              <div>
                <br />
                <ContentViewer
                  settings={currentSettings}
                  contentBefore={episodeSample}
                  contentAfter={processedEpisodeSample}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
