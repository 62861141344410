import React, { useState, useEffect } from "react";
import type { Settings, GPTModel, Prompts } from "../types";
import {
  GPT_TOKEN_LIMITS,
  MAX_WORKERS_PER_MODEL,
  DEFAULT_SETTINGS,
  AVAILABLE_MODELS
} from "../config";
import { countTokens } from "../lib/contentPreprocessor";
import {Slider} from './Slider';

import "./settingsForm.css";

type SettingsFormProps = {
  onChangedSettings: Function;
  prompts: Prompts,
  settings: Settings;
};

const uiPatterns = {
  temperature: /^[0,1](\.[0-9])?$|^2$/,
  maxDiff: /^[1-9]([0-9])?$|^100$/,
  minDiff: /^([0-9]\.)?([0-9])+?$|^0$/,
  attempts: /^[1-9]$|^10$/,
  branchPrefix: /^.+$/
};

const models: GPTModel[] = [...AVAILABLE_MODELS];

export const SettingsForm = (props: SettingsFormProps) => {
  const { settings, onChangedSettings, prompts } = props;

  // So the UI remains responsive
  const [currentSettings, setCurrentSettings] = useState(settings);
  const [uiValidInputs, setUiValidInputs] = useState({
    temperature: true,
    maxDiff: true,
    minDiff: true,
    attempts: true,
    waitTime: true,
    branchPrefix: true,
  });

  useEffect(() => {
    const thePrompt = prompts[settings.mode];
    const minTokens = countTokens(thePrompt);
    if (currentSettings.maxTokens < minTokens) {
      const newSettings = { ...currentSettings, maxTokens: minTokens + 100 };
      onChangedSettings(newSettings);
    }
  }, [prompts]);

  useEffect(() => setCurrentSettings(settings), [settings]);

  const onChange = (value, field) => {
    const newSettings = { ...currentSettings };
    newSettings[field] = value;
    const valid = validate(value, field);

    if (field === "model") {
      newSettings.maxTokens = validMaxTokens(
        newSettings.maxTokens,
        newSettings.model
      )
        ? newSettings.maxTokens
        : DEFAULT_SETTINGS["maxTokens"];

      newSettings.maxWorkers = validMaxWorkers(
        newSettings.maxWorkers,
        newSettings.model
      )
        ? newSettings.maxWorkers
        : DEFAULT_SETTINGS["maxWorkers"];
    }
    setCurrentSettings(newSettings);

    if (valid) {
      onChangedSettings(newSettings);
      return;
    }
  };

  const validMaxTokens = (value, model) =>
    +value <= GPT_TOKEN_LIMITS[model] && +value >= 10;

  const validMaxWorkers = (value, model) =>
    +value <= MAX_WORKERS_PER_MODEL[model] && +value >= 1;

  const validate = (value: string, field: string) => {
    if (field === "maxTokens" || field === "maxTokens2") {
      return validMaxTokens(value, currentSettings.model);
    }

    if (field === "waitTime") {
      return +value >= 0 && +value <= 300;
    }

    if (
      field === "preserveParagraphs" ||
      field === "maxWorkers" ||
      field === "model"
    ) {
      return true;
    }

    const valid = uiPatterns[field].test(value);
    setUiValidInputs({ ...uiValidInputs, [field]: valid });

    return valid;
  };

  const currentPrompt = prompts[settings.mode];

  return (
    <details>
      <summary>GPT API Settings</summary>
      <div className="settings-form-content">
        <div className="grid">
          <div>
            <label htmlFor="model">Model</label>
            <select
              id="model"
              name="model"
              required
              onChange={(e) => onChange(e.target.value, "model")}
            >
              {models.map((model) => (
                <option
                  value={model}
                  key={model}
                  selected={model === currentSettings.model}
                >
                  {model}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="temp">Temperature</label>
            <input
              type="text"
              name="temp"
              value={currentSettings.temperature}
              aria-invalid={!uiValidInputs.temperature}
              onChange={(e) => onChange(+e.target.value, "temperature")}
            />
            <small>Between 0 and 2. Lower makes it more deterministic.</small>
          </div>
          <div></div>
          <div></div>
        </div>
        { currentSettings.mode === 'deep-editing' && <>
          <div className="grid">
            <div>
              <label>Paragraph Structure</label>
              <div style={{ marginTop: "20px" }}>
                <label htmlFor="preserveParagraphs">
                  <input
                    type="checkbox"
                    id="preserveParagraphs"
                    name="preserveParagraphs"
                    role="switch"
                    onChange={(e) =>
                      onChange(
                        !currentSettings.preserveParagraphs,
                        "preserveParagraphs"
                      )
                    }
                    checked={currentSettings.preserveParagraphs}
                  />
                  Preserve paragraphs
                </label>
                <br />
                <input type="hidden" />
                <small>
                  {currentSettings.preserveParagraphs
                    ? "Paragraph metadata will be preserved in the new GStudio branch but the model's capacity will be restricted. This mode is useful for proofreading and to preserve immersive sound in the resulting branch"
                    : "The current metadata will be lost but model will have more freedom. This will get ride of immersive sound, bubble paragraphs (chat-like ui) and will produce new paragraphs in the resulting branch in Studio"}
                </small>
              </div>
            </div>
            <div></div>
          </div>
          <br />
        </>}
        <div className="grid">
          <div>
            <label htmlFor="attempts">Max attempts</label>
            <input
              type="text"
              name="attempts"
              value={currentSettings.attempts}
              aria-invalid={!uiValidInputs.attempts}
              onChange={(e) => onChange(+e.target.value, "attempts")}
            />
            <small>
              Maximum number of attempts when the difference criteria is not met
            </small>
          </div>
          <div>
            <label htmlFor="waitTime">Retry wait time</label>
            <input
              type="number"
              min="0"
              max="300"
              name="waitTime"
              value={currentSettings.waitTime}
              aria-invalid={!uiValidInputs.waitTime}
              onChange={(e) => onChange(+e.target.value, "waitTime")}
            />
            <small>
              In seconds, amount of time to wait in between attempts
            </small>
          </div>
          { currentSettings.mode === 'deep-editing' ? <>
            <div>
              <label htmlFor="maxDiff">Max Allowed Difference</label>
              <input
                type="text"
                name="maxDiff"
                value={currentSettings.maxDiff}
                aria-invalid={!uiValidInputs.maxDiff}
                onChange={(e) => onChange(+e.target.value, "maxDiff")}
              />
              <small>
                Maximum difference (A lower number means more requests)
              </small>
            </div>
            <div>
              <label htmlFor="minDiff">Min Allowed Difference</label>
              <input
                type="text"
                name="minDiff"
                value={currentSettings.minDiff}
                aria-invalid={!uiValidInputs.minDiff}
                onChange={(e) => onChange(+e.target.value, "minDiff")}
              />
              <small>
                Minimum difference (a higher number means more requests)
              </small>
            </div>

          </> : <>
            <div></div>
            <div></div>
          </>}
        </div>
        <br />
        <p>Thread and token limits: <br /><small className="very-small">Does not apply to Test mode</small></p>
        <div className="grid">
          {/* Summarize mode is mono-thread as it is a single request */}
          { currentSettings.mode !== 'summarize' && (
          <Slider
            label="Concurrency limit"
            min={1}
            max={MAX_WORKERS_PER_MODEL[currentSettings.model]}
            name="threadsLimit"
            info={`${currentSettings.maxWorkers} Worker${currentSettings.maxWorkers > 1 ? "s" : ""}`}
            value={currentSettings.maxWorkers}
            onChange={(value) => onChange(value, "maxWorkers")}
          />)}
          <Slider
            label="Tokens per request"
            min={countTokens(currentPrompt)}
            max={GPT_TOKEN_LIMITS[currentSettings.model]}
            name="maxTokens"
            info={`${currentSettings.maxTokens} Token${currentSettings.maxTokens > 1 ? "s" : ""}`}
            value={currentSettings.maxTokens}
            onChange={(value) => onChange(+value, "maxTokens")}
          />
        </div>
        <br />
        <div className="grid">
          <div>
            <label htmlFor="branchPrefix">Branch prefix <br/><small className="very-small">This is for all books processed</small></label>
              <input
                type="text"
                name="branchPrefix"
                value={currentSettings.branchPrefix}
                aria-invalid={!uiValidInputs.branchPrefix}
                onChange={(e) => onChange(e.target.value, "branchPrefix")}
              />
              <small>
                Preview: [{currentSettings.branchPrefix}YY.mm.HH:MM]
              </small>
          </div>
          <div></div>
          <div></div>
        </div>

      </div>
    </details>
  );
};
