import React from "react";
import "./ProcessLoader.css";

type LoadingMessage = {
  text: string;
  busy: false;
  danger: boolean;
};

type ProcessLoaderProps = {
  messages: LoadingMessage[];
};

export const ProcessLoader = (props: ProcessLoaderProps) => {
  const { messages } = props;
  return (
    <div className="process-loader">
      <div className="log-title">
        <small>General process log</small>
      </div>
      <br />
      {messages.map((message, i) => (
        <div key={i}>
          {message.busy && <p aria-busy="true">{message.text}</p>}
          {!message.busy && (
            <p>
              {message.danger ? "⚠ " : "✓ "}
              {message.text}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};
