export const loadEpisode = async (bei, storyId, episodeId) => {
  let res;
  try {
    res = await bei.get(`api/v1/stories/${storyId}/episodes/${episodeId}`);
  } catch(e) {
    console.error('loadEpisode error', e.response ? e.response.status : e);

    return false;
  }

  const { episode } = res.data;

  return episode;
}