import React from "react";
import { encode } from "gpt-3-encoder";
import { Settings, Tag, Prompts, TagMap } from "../types";
import { CSVReader } from '../components/CSVReader';

type PromptFormProps = {
  settings: Settings,
  prompts: Prompts,
  tags: Tag[],
  tags2: TagMap,
  onPromptChange: Function,
  onTagsChanged: Function
};

const PromptTextArea = ({prompt, onPromptChange, settings}) => (
  <textarea
    name="Prompt"
    placeholder="Write your prompt"
    value={prompt}
    onChange={(e) => onPromptChange(e.target.value, settings.mode)}
  />
);

export const PromptForm = (props: PromptFormProps) => {
  const { prompts, onPromptChange, settings, onTagsChanged, tags, tags2 } = props;
  const currentPrompt = prompts[settings.mode];
  const nTokens = encode(currentPrompt).length;

  if(settings.mode == 'deep-editing') {
    return (
      <div>
        <label htmlFor="Prompt">Prompt:</label>
        <small className="very-small">
            {settings.preserveParagraphs
              ? "You are preserving paragraphs, don't forget to include instructions to respect JSON in your prompt"
              : "You are ignoring paragraph structure don't forget to REMOVE instructions about JSON from your prompt"}
        </small>
        <PromptTextArea prompt={currentPrompt} onPromptChange={onPromptChange} settings={settings}/>
        <small>{nTokens} tokens</small>
      </div>
    );
  }

  if(settings.mode == 'summarize') {
    return (
      <div>
        <label htmlFor="Prompt">Prompt:</label>
        <small className="very-small">Take into account that the token limit includes this prompt, the data being sent and GPTs response.</small>
        <PromptTextArea prompt={currentPrompt} onPromptChange={onPromptChange} settings={settings}/>
        <small>{nTokens} tokens</small>
      </div>
    );
  }

  if(settings.mode == 'tag-matching') {
    return (
      <div>
        <label htmlFor="Prompt">Prompt (Tag Matching):</label>
        <PromptTextArea prompt={currentPrompt} onPromptChange={onPromptChange} settings={settings}/>
        <small>{nTokens} tokens (tags + prompt)</small>
        <CSVReader
          label="Tags:"
          mode={settings.mode}
          onDataLoaded={(data) => onTagsChanged(null, data)}
          tagMap={tags2}
        />
      </div>
    );
  }

  // Immersive sound prompts and tag-matching
  return (
    <div>
      <label htmlFor="Prompt">Prompt (Immersive Sound Tagging):</label>
      <PromptTextArea prompt={currentPrompt} onPromptChange={onPromptChange} settings={settings}/>
      <small>{nTokens} tokens (tags + prompt)</small>
      <CSVReader
        label="Tags:" 
        mode={settings.mode}
        onDataLoaded={(data) => onTagsChanged(data)}
        tags={tags}
      />
    </div>
  );

};
