async function createEpisode(bei, storyId, episode) {
  let res;
  try {
    res = await bei.post(`api/v1/stories/${storyId}/episodes`, { episode });
  } catch(e) {
    console.error('createEpisode error', e.response ? e.response.status : e);
    console.error(e.response.data);

    return false;
  }

  const { episode: episodeCreated } = res.data;

  return episodeCreated;
}

module.exports = createEpisode;
