import React, { useState, useEffect } from "react";
import store from "store";

const STORE_KEY = "initial-value";
const SLUGS_OR_IDS_INPUT_PATTERN = /^[a-z0-9_]+(,\s?[a-z0-9_]+)*$/;

type StoriesInputFormProps = {
  onBooksProvided: Function;
};

export const StoriesInputForm = (props: StoriesInputFormProps) => {
  const { onBooksProvided } = props;

  const [invalid, setInvalid] = useState(false);
  const [value, setValue] = useState(store.get(STORE_KEY) || "");

  useEffect(() => {
    if (value) {
      parseStorySlugs(value);
    }
  }, []);

  const parseStorySlugs = (input) => {
    setValue(input);
    store.set(STORE_KEY, input);
    if (!SLUGS_OR_IDS_INPUT_PATTERN.test(input)) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
    onBooksProvided(input.split(",").map((str) => str.trim()));
  };

  return (
    <div>
      <label htmlFor="story_ids">Stories (slugs)</label>
      <input
        type="text"
        name="story_ids"
        placeholder="the_arrangement, the_millennium_wolves_2"
        aria-invalid={invalid}
        value={value}
        onChange={(e) => parseStorySlugs(e.target.value)}
      />
      <small className="very-small">
        Comma separated list of slugs: immersive_demo, the_arrangement
      </small>
    </div>
  );
};
