import React from 'react';

type SliderProps = {
  label: string,
  min: number,
  max: number,
  name: string,
  info: string,
  value: number,
  onChange: Function;
};

const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0
});

export const Slider = (props: SliderProps) => {
  const {label, name, min, max, value, info, onChange} = props;
  return (
    <div>
      <label htmlFor={name}>
        {label}
        <div className="flex-cont" style={{float: 'right', opacity: '0.5'}}>
          <small className="very-small">Max <strong>{numberFormatter.format(max)}</strong></small>
        </div>
        <input
          type="range"
          min={min}
          max={max}
          id={name}
          value={value}
          onChange={(e) => onChange(+e.target.value, name)}
          name={name}
        />
        <small className="block-cont">
          {info}
        </small>
      </label>
    </div>
  );  
};
