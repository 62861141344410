import type {
  ProcessWorker,
  Settings,
  MessageDataFromWorker,
  ProcessEpisodeOptions,
  Credentials,
} from "./types";
import { credentialsStore, settingsStore } from "./stores";
import { THREAD_WAIT_TIME_MS } from "./config";

const processWorkers: ProcessWorker[] = [];

const firstAvailable = () => {
  return processWorkers.find((pw) => !pw.busy);
};

const markAvailable = (processWorker: ProcessWorker) => {
  processWorker.busy = false;
};

const markBusy = (processWorker: ProcessWorker) => {
  processWorker.busy = true;
};

const createProcessWorker = () => {
  // The Web Worker.
  const registerWorker = (worker) => new window.Worker(worker);
  const newWorker = registerWorker("./worker.js");

  // Tells the worker to start a new process
  // And attaches listener.
  const start = (options: ProcessEpisodeOptions) => {
    newWorker.onmessage = (e) => {
      const data = e.data as MessageDataFromWorker;
      if (data.status === "completed") {
        markAvailable(newProcessWorker);
      }
      // Bubble up message
      options.onMessage?.(data);
    };

    // Remove onMessage since it is not clonable for the worker.
    const { onMessage, ...workerOptions } = options;

    markBusy(newProcessWorker);

    // tell the worker to start the process
    newWorker.postMessage({
      action: "processEpisode",
      options: workerOptions,
    });
  };

  // initialize the Web Worker
  const credentials: Credentials = credentialsStore.get();
  const storedSettings: Settings = settingsStore.get();
  newWorker.postMessage({
    action: "init",
    credentials: credentials,
    settings: storedSettings,
  });

  // The Process Worker
  const newProcessWorker = {
    worker: newWorker,
    busy: false,
    start,
  };

  return newProcessWorker;
};

/**
 * This function doesn't start the process, just finds/creates a worker.
 */
const getNextAvailableWorker = async (
  settings: Settings
): Promise<ProcessWorker> => {
  return new Promise((resolve, reject) => {
    // Find a non busy worker in our current workers
    let availableWorker = firstAvailable();

    // We found an already created, available worker.
    if (availableWorker) {
      resolve(availableWorker);
      return;
    }

    // We didn't find it, but we still have space for more workers
    if (processWorkers.length < settings.maxWorkers) {
      // New worker
      const newProcessWorker = createProcessWorker();
      processWorkers.push(newProcessWorker);
      resolve(newProcessWorker);
      return;
    }

    // We didn't find an already created, nor could create it,
    // so we'll wait THREAD_WAIT_TIME_MS seconds
    setTimeout(async () => {
      // Recursively calling the same function until finding an available worker/thread
      const processWorker = await getNextAvailableWorker(settings);
      console.log("Waiting for a worker", processWorkers.length);
      resolve(processWorker);
    }, THREAD_WAIT_TIME_MS);
  });
};

export default {
  getNextAvailableWorker,
};
