async function saveStory(bei, storyId, story) {
  let res;
  try {
    res = await bei.patch(`api/v1/stories/${storyId}`, {story});
  } catch(e) {
    console.error('saveStory error', e.response ? e.response.status : e);

    return false;
  }

  const { story: newStory } = res.data;

  return newStory;
}

module.exports = saveStory;
